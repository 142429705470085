var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('AssessmentBanner',{attrs:{"header":_vm.centre.name,"loading":_vm.assessmentIsLoading,"progress":_vm.totalProgress,"term":_vm.assessment.term}}),_c('v-container',{class:_vm.$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'},[(!_vm.compliant)?_c('v-row',{staticClass:"mt-3 mb-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('Alert',{staticClass:"embeddedalert",attrs:{"show-alert":"","type":"warning","text":"Have you checked that you're still compliant?","subtext":"It is recommended that you check and re-submit your compliance checklist on a regular basis.","in-page":"","allow-dismiss":""}})],1)],1):_vm._e(),_c('v-row',{staticClass:"my-6 mt-3",attrs:{"no-gutters":true}},[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"12","md":"4","lg":"6"}},[_c('h1',{attrs:{"align":_vm.$vuetify.breakpoint.mdAndUp ? 'left' : 'center'}},[_vm._v(" Quality Areas ")])]),_c('v-col',{staticClass:"px-2 mt-1",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('AdsButton',{attrs:{"button-text":"Philosophy statement","icon":"add_circle_outline","secondary":true,"width":"100%"},on:{"click":_vm.openPhilosophyStatementDialog}})],1),_c('v-col',{staticClass:"px-2 mt-1",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('AdsButton',{attrs:{"width":"100%","button-text":"Generate QIP","icon":"download"},on:{"click":_vm.openQIPDialog}})],1)],1),_c('v-fade-transition',[(_vm.$vuetify.breakpoint.smAndUp || true)?_c('v-card',[_c('AdsDataTable',{attrs:{"headers":[
						{ text: 'Quality Area', value: 'name' },
						{ text: 'Standards', value: 'standard1' },
						{ text: '.', value: 'standard2' },
						{ text: '.', value: 'standard3' },
						{ text: 'Overall status', value: 'status' },
					],"loading":_vm.assessmentIsLoading,"items":_vm.assessment.qualityAreas},on:{"click:row":_vm.clickDataRow},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mt-3",attrs:{"types":{ trow: 'table-cell@5' },"type":'trow@3'}})]},proxy:true},_vm._l(([1, 2, 3]),function(i){return {key:`item.standard${i}`,fn:function({ item }){return [_c('div',{key:i},[(i == 2 && _vm.$vuetify.breakpoint.xsOnly)?_c('div',[_c('v-icon',{attrs:{"large":""}},[_vm._v("navigate_next")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.xsOnly)?_c('ProgressChip',{attrs:{"date":item.standards[i - 1] ? item.standards[i - 1].progress : -1}}):_vm._e()],1)]}}}),{key:`item.name`,fn:function({ item }){return [_c('b',[_vm._v(" "+_vm._s(_vm.structure[item.name] ? _vm.structure[item.name].number : "")+" - "+_vm._s(_vm.structure[item.name] ? _vm.structure[item.name].name : "")+" ")])]}},{key:`item.status`,fn:function({ item }){return [(_vm.getItemStatus(item) <= 0)?_c('div',[_vm._v("Not Started")]):(true || _vm.getItemStatus(item) < 100)?_c('v-progress-linear',{staticStyle:{"min-width":"90px"},attrs:{"value":_vm.getItemStatus(item),"rounded":true,"height":"15","color":"green"}}):_c('v-chip',{attrs:{"color":"green"}},[_vm._v(" Done! ")])]}}],null,true)})],1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.generateQIPDialogOpen),callback:function ($$v) {_vm.generateQIPDialogOpen=$$v},expression:"generateQIPDialogOpen"}},[_c('GenerateQIP',{on:{"handleBack":_vm.handleCloseDialogs}})],1),_c('v-dialog',{attrs:{"max-width":"800px","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.philosophyStatementDialogOpen),callback:function ($$v) {_vm.philosophyStatementDialogOpen=$$v},expression:"philosophyStatementDialogOpen"}},[_c('AddPhilosophyStatement',{on:{"handleBack":_vm.handleCloseDialogs}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }