<template>
	<v-container class="pt-10 download-card">
		<v-card>
			<v-card-title>
				<v-row class="text-center" justify="center">
					<v-col class="d-flex justify-center align-center">
						<FeatureIcon
							size="128"
							color="navy"
							background-color="light blue"
							icon="download"
							:outline="true"
						/>
					</v-col>
				</v-row>
			</v-card-title>
			<v-card-text>
				<v-row class="text-center" justify="center">
					<v-col>
						<h2>Your file is downloading.</h2>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-row class="text-center" justify="center">
					<v-col>
						<v-fade-transition>
							<span v-if="downloadMightNotHaveStarted">
								If your file didn't download automatically, please
								<a @click="loadEvidence">click here</a>.
							</span>
						</v-fade-transition>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import api from "../api"
import { FeatureIcon } from "@nswdoe/doe-ui-core"

const standard = {
	title: "Standard",
	name: "standard",
	async created() {
		this.loadEvidence()
		setTimeout(() => {
			this.downloadMightNotHaveStarted = true
		}, 5000)
	},
	computed: {},
	data() {
		return {
			downloadMightNotHaveStarted: false,
		}
	},
	methods: {
		loadEvidence() {
			api.strengths
				.getEvidenceData(this.$route.params.id)
				.then((data) => {
					console.log(data)
					api.strengths
						.getEvidence(data.s3_object.split("/")[1])
						.then(async (buf) => {
							//const bText = await buf.text()
							const dataUrl = `data:${data.type};base64,${buf.data}`
							try {
								const blob = await fetch(dataUrl).then((r) => r.blob())
								this.downloadBlob(blob, data.name || "unknown.png")
								this.$store.commit("SET_IS_LOADING", false)
							} catch (error) {
								console.error(error)
								console.log(buf)
								console.log(dataUrl)
								this.$store.dispatch("displayApiError", [
									"your evidence",
									"find",
									500
								])
							}
						})
						.catch((reason) => {
							console.error(reason)
							this.$store.dispatch("displayApiError", [
								"your evidence",
								"download",
								reason.reason || reason.data?.reason || reason.status,
							])
						})
				})
				.catch((reason) => {
					//console.error(reason)
					this.$store.dispatch("displayApiError", [
						"your evidence",
						"find",
						reason.reason || reason.data?.reason || reason.status,
					])
				})
				.finally(() => {
					setTimeout(() => {
						//window.close()
					}, 2000)
				})
		},
		downloadBlob(blob, name = "qip.docx") {
			// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
			const blobUrl = URL.createObjectURL(blob)

			// Create a link element
			const link = document.createElement("a")

			// Set link's href to point to the Blob URL
			link.href = blobUrl
			link.download = name

			// Append link to the body
			document.body.appendChild(link)

			// Dispatch click event on the link
			// This is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent("click", {
					bubbles: true,
					cancelable: true,
					view: window,
				}),
			)

			// Remove link from body
			document.body.removeChild(link)
		},
	},
	components: {
		FeatureIcon,
	},
}

export default standard
</script>

<style lang="scss" scoped>
@import "../scss/invert-banner.scss";

#main-content {
	padding-bottom: 0px !important;
	::v-deep div.pl-0.col {
		padding-top: 0px !important;
	}
}
.spacebetween {
	justify-content: space-between;
}
::v-deep .embeddedalert .v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
h1,
h2,
h3 {
	color: $ads-dark;
}
.download-card {
	max-width: 900px;
}
a,
u {
	color: $ads-blue-1 !important;
	text-underline-position: below;
}
</style>
