export default {
	epp: {
		name: "Educational program and practice",
		short: "epp",
		number: "1",
		children: { amount: 3 },
	},
	chs: {
		name: "Children's health and safety",
		short: "chs",
		number: "2",
		children: { amount: 2 },
	},
	physenv: {
		name: "Physical environment",
		short: "physenv",
		number: "3",
		children: { amount: 2 },
	},
	staffarr: {
		name: "Staffing arrangements",
		short: "staffarr",
		number: "4",
		children: { amount: 2 },
	},
	rwc: {
		name: "Relationships with children",
		short: "rwc",
		number: "5",
		children: { amount: 2 },
	},
	cpwfac: {
		name: "Collaborative partnerships with families",
		short: "cpwfac",
		number: "6",
		children: { amount: 3 },
	},
	gal: {
		name: "Governance and leadership",
		short: "gal",
		number: "7",
		children: { amount: 2 },
	},
}
