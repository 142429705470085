const primary = Object.freeze({
	navy: "#041E42",
	red: "#CE0037",
	lightblue: "#C7DCF0",
	white: "#FFFFFF",
})

const secondary = Object.freeze({
	blue1: "#1D428A",
	blue2: "#407EC9",
	blue3: "#6CACE4",
	red2: "#EE3C48",
	red3: "#F3B8B5",
})

const userInterface = Object.freeze({
	black: "#151515",
	darkgray: "#2E2F30",
	coolgrey: "#A9B1B4",
	grey60: "#666666",
	grey30: "#B3B3B3",
	grey15: "#D9D9D9",
	grey10: "#E5E5E5",
	grey5: "#F5F5F5",
	grey2: "#FAFAFA",
})

const complementary = Object.freeze({
	purple: "#752F8A",
	orange: "#FF7F2F",
	yellow: "#F9BE00",
	green: "#69B32D",
})

const notification = Object.freeze({
	errorred: "#B81237",
	warningorange: "#DC5800",
	infoblue: "#2E5299",
	successgreen: "#00A908",
})

export default Object.freeze({
	primary,
	secondary,
	userInterface,
	complementary,
	notification,
})
