const createAuthHeaders = (header) => {
	let accessToken = localStorage.getItem("access_token")
	let tokenType = localStorage.getItem("token_type")
	let idToken = localStorage.getItem("id_token")

	const authHeaders =
		accessToken && tokenType && idToken
			? {
					Authorization: tokenType + " " + accessToken,
					"id-token": idToken,
					"X-Correlation-ID": +new Date(),
			  }
			: {}
	return { ...authHeaders, ...(header || {}) }
}
export default createAuthHeaders
