import Vue from "vue"
import VueRouter from "vue-router"
import SelfAssessment from "../views/SelfAssessment.vue"
import Standard from "../views/Standard.vue"
import ElementDisplay from "../views/Element.vue"
import QualityArea from "../views/QualityArea.vue"
import HelpAndSupport from "../views/HelpAndSupport.vue"
import Settings from "../views/Settings.vue"
import Error401 from "../views/401Error.vue"
import ErrorTimedOut from "../views/ErrorTimedOut.vue"
import Evidence from "../views/Evidence.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "/assessment",
		name: "Self-Assessment",
		component: SelfAssessment,
		meta: {
			protected: true,
		},
	},
	{
		path: "/assessment/:qualityArea",
		name: "Quality Area",
		component: QualityArea,
		meta: {
			protected: true,
		},
	},
	{
		path: "/assessment/:qualityArea/:standard",
		name: "Standards",
		component: Standard,
		meta: {
			protected: true,
		},
	},
	{
		path: "/assessment/:qualityArea/:standard/:element",
		name: "Element",
		component: ElementDisplay,
		meta: {
			protected: true,
		},
	},
	{
		path: "/help",
		name: "Help",
		component: HelpAndSupport,
	},
	{
		path: "/settings",
		name: "Settings",
		component: Settings,
		meta: {
			protected: true,
		},
	},
	{
		path: "/evidence/:id",
		name: "View Evidence",
		component: Evidence,
		meta: {
			protected: true,
		},
	},
	{
		path: "/error401",
		name: "Error 401",
		component: Error401,
	},
	{
		path: "/errorTimedOut",
		name: "TimedOut",
		component: ErrorTimedOut,
	},
	{ path: "*", redirect: "/assessment" },
]

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

export default router
