export const ROLES = {
	STUDENT: [
		"DET.ACESTUDENT",
		"DET.GUEST_STUDENT",
		"DET.GUEST_STUDENT_BROWSE",
		"SCHOOL.STUDENT",
	],
	OTHER: [
		"DEC.COMMUNITIES",
		"DEC.ECEC",
		"DET.ACETEACHER",
		"DET.CASUAL",
		"DET.CONTRACTOR",
		"DET.EXTERNALAUDITOR",
		"DET.GUEST",
		"DET.GUEST_BROWSE",
		"DET.GUESTLECTURER",
		"DET.STAFFSECONDMENT",
		"DET.USER",
		"DET.WORKEXPERIENCE",
		"SCHOOL.CASUALNONTEACHER",
		"SCHOOL.CASUALTEACHER",
		"SCHOOL.EXCHANGEPRINCIPAL",
		"SCHOOL.EXCHANGESUBEXECUTIVE",
		"SCHOOL.EXCHANGETEACHER",
		"SCHOOL.NONTEACHER",
		"SCHOOL.PRACTICE",
		"SCHOOL.PRINCIPAL",
		"SCHOOL.PRINCIPAL.DEPUTY.HS",
		"SCHOOL.PRINCIPAL.DEPUTY.PS",
		"SCHOOL.SES",
		"SCHOOL.SUBEXECUTIVE",
		"SCHOOL.TEACHER",
		"SCHOOL.TEACHER.APPLICANT.APPOINTED",
		"SCHOOL.STAFF.FORMER",
		"SCHOOL.STAFF.LEAVE",
		"DET.ACE",
	],
}
const PROD_BASE_URL = "https://saap.api.education.nsw.gov.au/"
const TEST_BASE_URL =
	"https://j1noidc46c.execute-api.ap-southeast-2.amazonaws.com/new-secure/"
const RESOURCE = {
	PROD: {
		ASSESSMENTS: {
			GETASSESSMENT: PROD_BASE_URL + "assessment",
			FETCHQUALITYAREAS: PROD_BASE_URL + "qualityareas",
			FETCHQUALITYAREASTRUCTURE: PROD_BASE_URL + "qualityareas",
			SETPHILOSOPHYSTATEMENT: PROD_BASE_URL + "setPhilosophyStatement",
			CHECKLIST: PROD_BASE_URL + "saveChecklist",
			RENDERQIP: PROD_BASE_URL + "renderQIP",
		},
		SAVESETTINGS: PROD_BASE_URL + "saveSettings",
		GETCENTRE: PROD_BASE_URL + "getCentreForUser",
		GUIDES: {
			GET: PROD_BASE_URL + "getGuideMarkdown",
		},
		STRENGTHS: {
			SETSINGLE: PROD_BASE_URL + "updateSingleStrength",
			REGISTEREVIDENCE: PROD_BASE_URL + "evidence",
			DELETEOLDEVIDENCE: PROD_BASE_URL + "evidence",
			GETEVIDENCE: PROD_BASE_URL + "evidence",
			GETEVIDENCEDATA: PROD_BASE_URL + "evidencedata",
		},
		ISSUES: {
			FETCHISSUES: PROD_BASE_URL + "issues",
			ADDISSUE: PROD_BASE_URL + "issues",
			ADDGOAL: PROD_BASE_URL + "goals",
			FETCHGOALS: PROD_BASE_URL + "goals",
			EDITGOAL: PROD_BASE_URL + "goals",
			DELETEGOAL: PROD_BASE_URL + "goals",
			DELETEISSUE: PROD_BASE_URL + "issues",
		},
		NOTIFICATIONS: {
			CHECKSTILLCOMPLIANT: PROD_BASE_URL + "checkStillCompliant",
		},
	},

	PRE: {
		ASSESSMENTS: {
			GETASSESSMENT: PROD_BASE_URL + "assessment",
			FETCHQUALITYAREAS: PROD_BASE_URL + "qualityareas",
			FETCHQUALITYAREASTRUCTURE: PROD_BASE_URL + "qualityareas",
			SETPHILOSOPHYSTATEMENT: PROD_BASE_URL + "setPhilosophyStatement",
			CHECKLIST: PROD_BASE_URL + "saveChecklist",
			RENDERQIP: PROD_BASE_URL + "renderQIP",
		},
		SAVESETTINGS: PROD_BASE_URL + "saveSettings",
		GETCENTRE: PROD_BASE_URL + "getCentreForUser",
		GUIDES: {
			GET: PROD_BASE_URL + "getGuideMarkdown",
		},
		STRENGTHS: {
			SETSINGLE: PROD_BASE_URL + "updateSingleStrength",
			REGISTEREVIDENCE: PROD_BASE_URL + "evidence",
			DELETEOLDEVIDENCE: PROD_BASE_URL + "evidence",
			GETEVIDENCE: PROD_BASE_URL + "evidence",
			GETEVIDENCEDATA: PROD_BASE_URL + "evidencedata",
		},
		ISSUES: {
			FETCHISSUES: PROD_BASE_URL + "issues",
			ADDISSUE: PROD_BASE_URL + "issues",
			ADDGOAL: PROD_BASE_URL + "goals",
			FETCHGOALS: PROD_BASE_URL + "goals",
			EDITGOAL: PROD_BASE_URL + "goals",
			DELETEGOAL: PROD_BASE_URL + "goals",
			DELETEISSUE: PROD_BASE_URL + "issues",
		},
		NOTIFICATIONS: {
			CHECKSTILLCOMPLIANT: PROD_BASE_URL + "checkStillCompliant",
		},
	},

	TEST: {
		ASSESSMENTS: {
			GETASSESSMENT: TEST_BASE_URL + "assessment",
			FETCHQUALITYAREAS: TEST_BASE_URL + "qualityareas",
			FETCHQUALITYAREASTRUCTURE: TEST_BASE_URL + "qualityareas",
			SETPHILOSOPHYSTATEMENT: TEST_BASE_URL + "philosophystatement",
			CHECKLIST: TEST_BASE_URL + "checklist",
			RENDERQIP: TEST_BASE_URL + "renderQIP",
		},
		SAVESETTINGS: TEST_BASE_URL + "saveSettings",
		GETCENTRE: TEST_BASE_URL + "centres/user",
		GUIDES: {
			GET: TEST_BASE_URL + "guidemarkdown",
		},
		STRENGTHS: {
			SETSINGLE: TEST_BASE_URL + "strength",
			REGISTEREVIDENCE: TEST_BASE_URL + "evidence",
			DELETEOLDEVIDENCE: TEST_BASE_URL + "evidence",
			GETEVIDENCE: TEST_BASE_URL + "evidence",
			GETEVIDENCEDATA: TEST_BASE_URL + "evidencedata",
		},
		ISSUES: {
			FETCHISSUES: TEST_BASE_URL + "issues",
			ADDISSUE: TEST_BASE_URL + "issues",
			ADDGOAL: TEST_BASE_URL + "goals",
			FETCHGOALS: TEST_BASE_URL + "goals",
			EDITGOAL: TEST_BASE_URL + "goals",
			DELETEGOAL: TEST_BASE_URL + "goals",
			DELETEISSUE: PROD_BASE_URL + "issues",
		},
		NOTIFICATIONS: {
			CHECKSTILLCOMPLIANT: TEST_BASE_URL + "checkstillcompliant",
		},
	},
}

//console.log(process.env.VUE_APP_BUILD)

export const APIRESOURCE =
	process.env.VUE_APP_BUILD == "production"
		? RESOURCE.PROD
		: process.env.VUE_APP_BUILD == "pre-prod"
		? RESOURCE.PRE
		: RESOURCE.TEST
