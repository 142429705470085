<template>
	<v-file-input
		v-bind="$attrs"
		v-on="$listeners"
		outlined
		multiple
		chips
		:label="label"
		prepend-icon=""
		prepend-inner-icon="mdi-paperclip"
		v-model="myFile"
		ref="input"
	/>
</template>

<script>
const AdsFileInput = {
	name: "AdsFileInput",
	model: {
		/* chain the model, sneaky! */ prop: "myFile",
		event: "change",
	},
	props: {
		label: {
			type: String,
			default: "Select your files",
		},
		file: {},
	},
	computed: {
		myFile: {
			get() {
				return this.file
			},
			set(file) {
				this.$emit("update:file", file)
			},
		},
	},
	methods: {},
}

export default AdsFileInput
</script>

<style lang="scss" scoped>
@import "../scss/ads.scss";

::v-deep .theme--light.v-chip:not(.v-chip--active) {
	background: $ads-blue-2;
}

::v-deep .v-chip.v-size--default {
	border-radius: 4px;
}

::v-deep .theme--light.v-chip {
	color: $ads-white;
}
</style>
