<template>
	<Banner
		v-bind="$attrs"
		:heading="header"
		default-background1
		:background-colour="$vuetify.breakpoint.xsOnly ? '#041E42' : ''"
		id="main-content"
		tab-index="-1"
		class="d-flex-child"
	>
		<template #subtitle>
			<div class="ml-4 mb-2">{{ subheader }}</div>
			<div class="appSubtitle">
				<template v-if="loading">
					<v-skeleton-loader type="paragraph" />
				</template>
				<template v-else>
					<h2 v-if="progress > -1">Progress: {{ roundedProgress }}%</h2>
					<h2 v-else>Progress: --</h2>
					<template v-if="term > -1"> Term {{ term }} </template>
				</template>
			</div>
		</template>
	</Banner>
</template>

<script>
import { Banner } from "@nswdoe/doe-ui-core"
const AssessmentBanner = {
	name: "AssessmentBanner",
	components: { Banner },
	props: {
		header: {
			type: String,
			default: "",
		},
		subheader: {
			type: String,
			default: "",
		},
		progress: {
			type: Number,
			default: -1,
		},
		term: {
			type: Number,
			default: -1,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		roundedProgress() {
			return Math.round(this.progress)
		},
	},
}

export default AssessmentBanner
</script>

<style lang="scss" scoped>
</style>
