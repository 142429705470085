export default {
	acecqa_service_id: "9079",
	associated_assessment: "c3cfe76d-8967-40eb-bc79-86f23ac07ec7",
	settings: {
		service: {
			principal: "TEST",
			provider: "",
			contactName: "TEST",
			name: "Test School",
			contactNumber: "TEST",
			providerContact: "TEST",
			educators: "TEST",
			schoolmotto: "TEST",
			approvalNumber: "TEST",
			providerNumber: "TEST",
			providerName: "TEST",
		},
		notifications: {},
	},
	name: "ATP qa PS Test School",
	ad_group: false,
}
