export default [
	{
		id: "1",
		icon: "settings",
		title: "Buncha columns!",
		html: ``,
		content: ``,
		markdown: `| Column A | Column B | Column C |
|-|-|-|
| You can have | | empty cells |
| This row | is too short |
| This row | has | too many | cells |
`,
	},
	{
		id: "2",
		icon: "settings",
		title: "Some code!",
		html: ``,
		content: ``,
		markdown: `# Hey, look at this code
\`let x = 3;\`
(and this code) \`let y = 5;\`
### Brilliant
`,
	},
	{
		id: "3",
		icon: "settings",
		title: "Images and text tricks",
		html: ``,
		content: ``,
		markdown: `## Hey lets try a link!
[Github](https://github.com/)

## And why not an image?!
![someImage.png](http://placekitten.com/200/300)

*italic* **strong**

A LINE! WOAH
___
Amazin
`,
	},
	{
		id: "4",
		icon: "settings",
		title: "A video? What a challenge.",
		html: ``,
		content: "",
		markdown: `
### Videos wont work, at least not inline ;(

### But! we can just link them in! [![image placeholder](youtubeVideoExample.png)](https://www.youtube.com/watch?v=xbs7FT7dXYc&t=216s)
`,
	},
]
