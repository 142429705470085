export default {
	issues: [
		{
			standard: "program",
			owner: "bridget.bardon",
			date: "Mon Apr 17 2023 04:31:21 GMT+0000 (Coordinated Universal Time)",
			details: "Test",
			id: "58cab365-dd33-48d5-a7be-219663000651",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "sylvana.cham",
			date: "Mon Apr 17 2023 04:37:31 GMT+0000 (Coordinated Universal Time)",
			details: "TEST",
			id: "89c7f5c6-edd1-479e-b5cb-ded4c845fbdd",
			priority: 1,
			status: 0,
		},
		{
			standard: "program",
			owner: "matthew.worboys3",
			date: "Tue Apr 25 2023 23:18:11 GMT+0000 (Coordinated Universal Time)",
			details: "Details of the issue",
			id: "52e8809b-7c29-4a4b-9002-a4ad244ed28c",
			priority: 1,
			status: 0,
		},
		{
			standard: "program",
			owner: "bridget.bardon",
			date: "Thu May 04 2023 00:58:34 GMT+0000 (Coordinated Universal Time)",
			details: "Test",
			id: "8d59d7ae-a447-4feb-9b3a-faadde1eecaa",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "lyndal.woodward",
			date: "Tue May 30 2023 02:10:44 GMT+0000 (Coordinated Universal Time)",
			details: "test",
			id: "95ee93e1-efe7-4f37-a335-ad182ff0f78a",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "colleen.new",
			date: "Wed Aug 23 2023 06:07:54 GMT+0000 (Coordinated Universal Time)",
			details: "wegweghhhhhhhhhhhhhhhhhhhhhhhhhhhhh",
			id: "ebd2e031-9091-4a29-b609-215bf2128e6b",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "colleen.new",
			date: "Wed Aug 23 2023 23:31:36 GMT+0000 (Coordinated Universal Time)",
			details: "Testing",
			id: "b6b6bf6b-8123-414a-9403-58f0a8fc1b02",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "colleen.new",
			date: "Thu Aug 24 2023 03:51:42 GMT+0000 (Coordinated Universal Time)",
			details: "test",
			id: "f7a7e4bc-405f-4965-a2c4-a1a82fd7f32f",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "nicole.lawson",
			date: "Mon Sep 18 2023 08:20:23 GMT+0000 (Coordinated Universal Time)",
			details: "testing",
			id: "5f9e1822-02dd-49e0-bc32-b3dec85484ff",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "nicole.lawson",
			date: "Mon Sep 18 2023 08:46:41 GMT+0000 (Coordinated Universal Time)",
			details: "sjkbsfd",
			id: "c74740e7-521b-4c46-952a-79e9b413bde9",
			priority: 0,
			status: 0,
		},
		{
			standard: "program",
			owner: "nicole.lawson",
			date: "Mon Sep 18 2023 09:24:55 GMT+0000 (Coordinated Universal Time)",
			details: "rkrr",
			id: "26bf5701-616d-46be-aef7-5d2a76285652",
			priority: 0,
			status: 0,
		},
	],
}
