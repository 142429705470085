<template>
	<v-expansion-panels
		v-model="expandedPanelIndex"
		v-bind="$attrs"
		v-if="items.length > 0"
		accordion
		multiple
	>
		<template slot:actions>
			<v-icon>caret_right</v-icon>
		</template>
		<v-expansion-panel v-for="(item, i) in items" :key="i">
			<v-expansion-panel-header
				class="panel"
				:class="item.headerClass"
				:aria-expanded="getExpandStatus(i)"
				disable-icon-rotate
				@click="panelClicked(item)"
			>
				<template #actions>
					<template v-if="!loading">
						<template
							v-if="item.date !== undefined && $vuetify.breakpoint.smAndUp"
						>
							<ProgressChip :date="item.date" />
						</template>
						<v-icon large>navigate_next</v-icon>
					</template>
					<template v-else>
						<v-skeleton-loader
							class="chip-loader"
							:type="item.date ? 'chip, avatar' : 'avatar'"
						/>
					</template>
				</template>
				<div>
					<div style="display: flex">
						<template v-if="!loading">
							<slot :name="'header' + (item.id ? item.id : i + 1)">
								<!--span class="panel--icon" v-if="item.icon"
									><v-icon size="24" class="pr-4 material-icons-outlined">{{
										item.icon
									}}</v-icon></span
								-->
								<h3 v-html="item.title ? item.title : ''" style="width: 90%" />
							</slot>
						</template>
						<template v-else>
							<v-skeleton-loader
								class="text-loader"
								:type="item.subtitle ? 'heading, text' : 'heading'"
								width="100%"
							/>
						</template>
					</div>
					<template v-if="!loading">
						<div
							:class="{ 'subtitle-1': $vuetify.breakpoint.smAndUp }"
							style="font-weight: 500"
						>
							{{ item.subtitle }}
						</div>
					</template>
					<template v-if="!loading && $vuetify.breakpoint.xsOnly">
						<ProgressChip class="mt-3" :date="item.date" />
					</template>
				</div>
			</v-expansion-panel-header>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
const arraysEqual = (a1, a2) => {
	return a1.length === a2.length && a1.every((v) => a2.indexOf(v) !== -1)
}
import ProgressChip from "./ProgressChip.vue"

export default {
	name: "MultiCardPanel",
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		items: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			expandedPanelIndex: [...this.value],
		}
	},
	watch: {
		value(val) {
			if (!arraysEqual(val, this.expandedPanelIndex)) {
				// sync external changes
				this.expandedPanelIndex = val
			}
		},
		expandedPanelIndex(val) {
			if (!arraysEqual(val, this.value)) {
				// emit internal changes
				this.$emit("input", val)
			}
		},
	},
	methods: {
		getExpandStatus(index) {
			if (this.expandedPanelIndex.length > 0) {
				return this.expandedPanelIndex.indexOf(index) > -1 ? "true" : "false"
			}
			return "false"
		},
		panelClicked(item) {
			this.$emit("click", item)
		},
	},
	components: {
		ProgressChip,
	},
}
</script>
<style lang="scss" scoped>
@import "../scss/variables.scss";

.panel--icon {
	max-width: 40px;
}
.panel {
	line-height: 24px;
	padding: 22px 28px;
}
.theme--dark {
	.v-expansion-panels .v-expansion-panel .subtitle-1,
	.panel--icon .v-icon {
		color: $ads-navy-dark !important;
	}
	::v-deep .v-icon {
		color: $ads-navy-dark !important;
	}
}

// v-expansion-panel a11y focus state update
.theme--light {
	.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus {
		.v-expansion-panel-header__icon {
			border: 2px solid $ads-navy;
			border-radius: 50%;
		}
	}
}
.theme--dark {
	.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus {
		.v-expansion-panel-header__icon {
			border: 2px solid $ads-navy-dark;
			border-radius: 50%;
		}
	}
}
::v-deep .v-expansion-panel-header__icon {
	align-items: center;
}
::v-deep .text-loader {
	.v-skeleton-loader__heading {
		width: 25%;
	}
	.v-skeleton-loader__text {
		margin-top: 7px;
		width: 50%;
	}
}
::v-deep .chip-loader {
	display: flex;
	align-items: center;
	.v-skeleton-loader__chip {
		margin-right: 7px;
	}
	.v-skeleton-loader__avatar {
		width: 32px;
		height: 32px;
	}
}
</style>
