import qs from "qs"
import API from "../apiHelper"
import { APIRESOURCE } from "../../utils/constants"
import createAuthHeaders from "../helpers"

const fetchAssessment = (assessmentId) => {
	//console.log(`Fetch assessment api { ${centreId} }`)
	return API.get({
		endpoint:
			APIRESOURCE.ASSESSMENTS.GETASSESSMENT +
			"?" +
			qs.stringify({ assessmentId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchAssessment",
		spinner: false,
	})
}

const fetchQualityAreaStructure = (qualityArea) => {
	/*console.log(
		`Fetch Quality Area Structure Api { ${qualityArea} }   @ ${APIRESOURCE.ASSESSMENTS.FETCHQUALITYAREASTRUCTURE}`,
	)*/
	return API.get({
		endpoint:
			APIRESOURCE.ASSESSMENTS.FETCHQUALITYAREASTRUCTURE + "/" + qualityArea,
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchQualityAreaStructure",
		spinner: false,
	})
}

const fetchQualityAreas = () => {
	/*console.log(
		`Fetch Quality Areas Api   @ ${APIRESOURCE.ASSESSMENTS.FETCHQUALITYAREAS}`,
	)*/
	return API.get({
		endpoint: APIRESOURCE.ASSESSMENTS.FETCHQUALITYAREAS,
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchQualityAreas",
		spinner: false,
	})
}

const checkStillCompliant = (assessmentId) => {
	//console.log(`Check still compliant api`)
	return API.get({
		endpoint:
			APIRESOURCE.NOTIFICATIONS.CHECKSTILLCOMPLIANT +
			"?" +
			qs.stringify({ assessmentId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "checkStillCompliant",
		spinner: false,
	})
}

const setPhilosophyStatement = (assessmentId, statement) => {
	//console.log(`Set philosophy statement api`)

	// const endpoint = `${APIRESOURCE.ASSESSMENTS.SETPHILOSOPHYSTATEMENT}?${qs.stringify({ assessmentId })}`
	// console.log('endpoint', endpoint);
	// console.log('statement', statement);

	return API.post({
		endpoint:
			APIRESOURCE.ASSESSMENTS.SETPHILOSOPHYSTATEMENT +
			"?" +
			qs.stringify({ assessmentId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "setPhilosophyStatement",
		spinner: false,
		payload: { philosophyStatement: statement },
	})
}

const saveChecklist = (assessmentId, qualityArea, checklist) => {
	/*console.log(
		`Save Checklist api, { assessmentId: ${assessmentId}, checklist: ${JSON.stringify(
			checklist,
		)}}    @ ${APIRESOURCE.ASSESSMENTS.CHECKLIST}`,
	)*/
	return API.post({
		endpoint:
			APIRESOURCE.ASSESSMENTS.CHECKLIST +
			"?" +
			qs.stringify({ assessmentId, qualityArea }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "saveChecklist",
		spinner: false,
		payload: { checklistData: checklist },
	})
}

const renderQip = (assessmentId, thingsToInclude) => {
	/*console.log(
		`render QIP api, { assessmentId: ${assessmentId}, checklist: ${JSON.stringify(
			thingsToInclude,
		)} }    @ ${APIRESOURCE.ASSESSMENTS.RENDERQIP}`,
	)*/

	return API.get({
		endpoint:
			APIRESOURCE.ASSESSMENTS.RENDERQIP +
			"?" +
			qs.stringify({ assessmentId, include: thingsToInclude }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "saveChecklist",
		spinner: false,
	})
}

export default {
	fetchAssessment,
	fetchQualityAreaStructure,
	fetchQualityAreas,
	checkStillCompliant,
	setPhilosophyStatement,
	saveChecklist,
	renderQip,
}
