/* eslint-disable no-irregular-whitespace */
export default {
	title: "Guidance to Standard 2.1 - Health",
	markdown: `
### How to demonstrate your strengths in Standard 2.1
**1.** Go to the [Guide to National Quality Framework](#).

**2.** Refer to the questions to guide reflection on practice for Standard 2.1

**3.** Read through the guidance on all elements within Standard 2.1


#### Consider:
* Where you are at now
* What data/evidence you have to demonstrate your practice and where it is sourced from
* Where you want to be and how you might get there

#### When developing your key practice statements:
* Look for, discuss and reflect on your service’s key practices with your team for each element in this standard
* Consider how your service philosophy influences the decisions you make 
* Consider how an authorised officer will confirm this practice (will they be able to sight, observe or discuss?) 
* Write your practice statements in response to the questions under each element in a way which makes your practice visible.
---
### Prompts to consider exceeding practice for Standard 2.1
When thinking about your practice examples for standard 2.1, consider the following to decide if your evidence
within each element demonstrates one or more of the exceeding themes: 

#### Embed
Describe what you do to ensure that practices and procedures which support children’s comfort,
health and understanding of healthy lifestyles are embedded across day-to-day practice,
including when different educators are in the room? How do you check you are achieving embedded practice?

#### Critically reflect
How does your ongoing critical reflection inform and influence health practices and procedures that contribute
to positive outcomes for children and families? What informs your reflections? What actions have you taken as a result? 

#### Meaningful engagement
How does your engagement with families and the community influence your practices to support children’s comfort,
health and understanding of healthy lifestyles? What makes it meaningful and authentic engagement?
How does it inform your practice? How do you balance this input with quality and legislative standards?

`,
}
