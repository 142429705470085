export default {
	response: 200,
	text: "Success!",
	data: {
		goal: {
			due: "2023-12-18T13:00:00.000Z",
			summary: "bnbnb",
			purpose: "fgfg",
			strategies: ",mmm,",
			measures: "jhjkhj",
			who: "samtestgoal",
			progressNotes: [],
			issues: [
				"89c7f5c6-edd1-479e-b5cb-ded4c845fbdd",
				"52e8809b-7c29-4a4b-9002-a4ad244ed28c",
			],
			status: 0,
		},
	},
}
