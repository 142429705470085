<template>
	<div>
		<template v-if="type == 0">
			<Chip color="green" class="px-5 pt-1" v-if="date == 2"> COMPLETE </Chip>
			<Chip
				color="primary"
				class="px-3 pt-1"
				:outlined="true"
				v-else-if="date == 1"
			>
				IN PROGRESS
			</Chip>
			<Chip color="grey" class="pt-1" v-else-if="date == 0"> NOT STARTED </Chip>
			<div v-else-if="date == -1">N/A</div>
		</template>
		<template v-else-if="type == 1">
			<Chip color="primary" class="px-5 pt-1" :outlined="true" v-if="date == 0">
				PENDING
			</Chip>
			<Chip color="saap-info" class="px-2 pt-1" v-if="date == 1">
				GOAL CREATED
			</Chip>
		</template>
		<template v-else-if="type == 2">
			<Chip color="primary" class="px-3 pt-1" :outlined="true" v-if="date == 0">
				IN PROGRESS
			</Chip>
			<Chip color="saap-info" class="px-6 pt-1" v-if="date == 1">
				ACHIEVED
			</Chip>
		</template>
	</div>
</template>

<script>
import { Chip } from "@nswdoe/doe-ui-core"
const ProgressChip = {
	name: "ProgressChip",
	props: {
		date: {
			type: Number,
			default: -2,
		},
		type: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		formattedDate() {
			let tDate = new Date(this.date)
			return `Updated ${tDate.getDate()}/${tDate.getMonth()}/${tDate.getFullYear()}`
		},
	},
	methods: {},
	watch: {},
	components: { Chip },
}
export default ProgressChip
</script>

<style lang="scss" scoped>
::v-deep .v-chip {
	height: 60%;
	font-weight: 600;
	&.theme--light.saap-info {
		background-color: $ads-primary-teal;
		color: $ads-primary-blue !important;
		border: 0.5px $ads-primary-teal;
	}
}
::v-deep .v-chip.v-chip--outlined {
	border-width: 2px;
}
</style>
