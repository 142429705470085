export const val = (rootObj, subkeys) => {
	/*
    Allows us to access json values without getting reference errors
    if the object structure does not exist. This can save a lot of 
    ugly conditional logic when accessing nested values.

      rootObj - Root object, e.g. an api response object
      subkeys - String of keys, e.g. "data.profile.username"

    Example:
      return val(apiResponse, "data.profile.username") || "User not found"
  */
	try {
		return eval(`rootObj.${subkeys}`)
	} catch (e) {
		return ""
	}
}

const colourwayToThemeMap = [
	{ colourway: "red", themeColor: "kids_red" },
	{ colourway: "orange", themeColor: "kids_orange" },
	{ colourway: "yellow", themeColor: "kids_yellow" },
	{ colourway: "purple", themeColor: "kids_purple" },
	{ colourway: "pink", themeColor: "kids_pink" },
	{ colourway: "gray", themeColor: "kids_dark" },
	{ colourway: "junior", themeColor: "kids_light_blue" },
	{ colourway: "middle", themeColor: "kids_dark_blue" },
	{ colourway: "green", themeColor: "kids_light_green" },
	{ colourway: "green-dark", themeColor: "kids_dark_green" },
]

export const getColourwayFromThemeColor = (themeColor) => {
	// Gets Liferay colourway for specified front-end theme colour
	try {
		return colourwayToThemeMap.find((map) => map.themeColor === themeColor)
			.colourway
	} catch (e) {
		return null // Unknown theme colour provided
	}
}

export const getThemeColorFromColourway = (colourway) => {
	// Gets front-end theme colour for specified Liferay colourway
	try {
		return colourwayToThemeMap.find((map) => map.colourway === colourway)
			.themeColor
	} catch (e) {
		return null // Unknown theme colour provided
	}
}

export const isFormIncomplete = (formRef) => {
	/*
    Returns true if the specified form is incomplete (i.e. has missing or invalid fields). 
    If true, the focus will be set to the first missing/invalid field. 
    
    Note that Vuetify validation can give incorrect results when cross-field validation is used
    (validating one field based on the value of another). This function revalidates the whole form,
    so will always catch cross-field validation errors.
  */

	formRef.validate()
	let firstInvalidField = formRef.inputs.find((input) => input.hasError)
	if (firstInvalidField) {
		firstInvalidField.focus()
	}
	return !!firstInvalidField
}
