const CONFIG = {
	VUE_APP_OAUTH_URL: process.env.VUE_APP_OAUTH_URL
		? process.env.VUE_APP_OAUTH_URL
		: "",
	VUE_APP_OAUTH_CLIENT_ID: process.env.VUE_APP_OAUTH_CLIENT_ID
		? process.env.VUE_APP_OAUTH_CLIENT_ID
		: "",
	VUE_APP_OAUTH_SCOPES: process.env.VUE_APP_OAUTH_SCOPES
		? process.env.VUE_APP_OAUTH_SCOPES
		: ".default", // 3eab1d7d-e35a-4297-ae75-83547a0a2bd6/.default
	VUE_APP_OAUTH_REDIRECT_URL: process.env.VUE_APP_OAUTH_REDIRECT_URL
		? process.env.VUE_APP_OAUTH_REDIRECT_URL
		: "",
	VUE_APP_OAUTH_CLIENT_SECRET: process.env.VUE_APP_OAUTH_CLIENT_SECRET
		? process.env.VUE_APP_OAUTH_CLIENT_SECRET
		: "",
	VUE_APP_OAUTH_LOGOUT_URL: process.env.VUE_APP_OAUTH_LOGOUT_URL
		? process.env.VUE_APP_OAUTH_LOGOUT_URL
		: "",
	VUE_APP_OAUTH_TOKEN_URL: process.env.VUE_APP_OAUTH_TOKEN_URL
		? process.env.VUE_APP_OAUTH_TOKEN_URL
		: "",
}

export default CONFIG
