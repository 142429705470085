<template>
	<div>
		<AssessmentBanner
			:header="centre.name"
			:loading="assessmentIsLoading"
			:progress="totalProgress"
			:term="assessment.term"
		/>
		<v-container :class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'">
			<v-row v-if="!compliant" class="mt-3 mb-n3">
				<v-col cols="12">
					<Alert
						class="embeddedalert"
						show-alert
						type="warning"
						text="Have you checked that you're still compliant?"
						subtext="It is recommended that you check and re-submit your compliance checklist on a regular basis."
						in-page
						allow-dismiss
					/>
				</v-col>
			</v-row>
			<v-row :no-gutters="true" class="my-6 mt-3">
				<v-col cols="12" sm="12" md="4" lg="6" class="mt-1">
					<h1 :align="$vuetify.breakpoint.mdAndUp ? 'left' : 'center'">
						Quality Areas
					</h1>
				</v-col>
				<v-col cols="12" sm="6" md="4" lg="3" class="px-2 mt-1">
					<AdsButton
						button-text="Philosophy statement"
						icon="add_circle_outline"
						:secondary="true"
						width="100%"
						@click="openPhilosophyStatementDialog"
					/>
				</v-col>
				<v-col cols="12" sm="6" md="4" lg="3" class="px-2 mt-1">
					<AdsButton
						width="100%"
						button-text="Generate QIP"
						icon="download"
						@click="openQIPDialog"
					/>
				</v-col>
			</v-row>
			<v-fade-transition>
				<v-card v-if="$vuetify.breakpoint.smAndUp || true">
					<AdsDataTable
						@click:row="clickDataRow"
						:headers="[
							{ text: 'Quality Area', value: 'name' },
							{ text: 'Standards', value: 'standard1' },
							{ text: '.', value: 'standard2' },
							{ text: '.', value: 'standard3' },
							{ text: 'Overall status', value: 'status' },
						]"
						:loading="assessmentIsLoading"
						:items="assessment.qualityAreas"
					>
						<template #loading>
							<v-skeleton-loader
								:types="{ trow: 'table-cell@5' }"
								class="mt-3"
								:type="'trow@3'"
							></v-skeleton-loader>
						</template>
						<template v-for="i in [1, 2, 3]" #[`item.standard${i}`]="{ item }">
							<div :key="i">
								<div v-if="i == 2 && $vuetify.breakpoint.xsOnly">
									<v-icon large>navigate_next</v-icon>
								</div>
								<ProgressChip
									v-if="!$vuetify.breakpoint.xsOnly"
									:date="
										item.standards[i - 1] ? item.standards[i - 1].progress : -1
									"
								/>
							</div>
						</template>
						<template #[`item.name`]="{ item }">
							<b>
								{{ structure[item.name] ? structure[item.name].number : "" }} -
								{{ structure[item.name] ? structure[item.name].name : "" }}
							</b>
						</template>
						<template #[`item.status`]="{ item }">
							<div v-if="getItemStatus(item) <= 0">Not Started</div>
							<v-progress-linear
								v-else-if="true || getItemStatus(item) < 100"
								:value="getItemStatus(item)"
								:rounded="true"
								height="15"
								style="min-width: 90px"
								color="green"
							/>
							<v-chip v-else color="green"> Done! </v-chip>
						</template>
					</AdsDataTable>
				</v-card>
			</v-fade-transition>
		</v-container>
		<v-dialog
			v-model="generateQIPDialogOpen"
			max-width="800px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<GenerateQIP @handleBack="handleCloseDialogs" />
		</v-dialog>
		<v-dialog
			v-model="philosophyStatementDialogOpen"
			max-width="800px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<AddPhilosophyStatement @handleBack="handleCloseDialogs" />
		</v-dialog>
	</div>
</template>

<script>
import { AdsButton, AdsDataTable, Alert } from "@nswdoe/doe-ui-core"
import {
	ProgressChip,
	AssessmentBanner,
	GenerateQIP,
	AddPhilosophyStatement,
} from "../components/"
import { mapGetters } from "vuex"

const selfAssessment = {
	title: "Self-Assessment",
	name: "selfAssessment",
	created() {
		if (!this.assessmentIsLoading) {
			this.$store.dispatch("checkCompliance")
		}
	},
	computed: {
		...mapGetters({
			userProfile: "profile",
			assessment: "selectedAssessment",
			assessmentIsLoading: "assessmentIsLoading",
			compliant: "isCompliant",
		}),
		structure() {
			if (this.$store.getters.qualityAreas.sort)
				return this.$store.getters.qualityAreas.sort(
					(a, b) => a.number - b.number,
				)
			return this.$store.getters.qualityAreas
		},
		totalProgress() {
			if (this.assessmentIsLoading) return -1
			let status = 0
			let highestPossibleStatus = 0
			for (let qa of this.assessment.qualityAreas) {
				status += this.getItemStatus(qa)
				highestPossibleStatus += 100
			}
			return (status / highestPossibleStatus) * 100
		},
		centre() {
			return this.$store.getters.centre
		},
	},
	watch: {
		assessmentIsLoading(newVal) {
			if (newVal == false) {
				this.$store.dispatch("checkCompliance")
			}
		},
	},
	data() {
		return {
			generateQIPDialogOpen: false,
			philosophyStatementDialogOpen: false,
		}
	},
	methods: {
		openPhilosophyStatementDialog() {
			this.philosophyStatementDialogOpen = true
		},
		openQIPDialog() {
			this.generateQIPDialogOpen = true
		},
		handleCloseDialogs() {
			this.generateQIPDialogOpen = false
			this.philosophyStatementDialogOpen = false
		},
		clickDataRow(a) {
			if (!a.name) return
			this.$router.push({
				name: "Quality Area",
				params: { qualityArea: a.name },
			})
		},
		getItemStatus(item) {
			let status = 0
			let highestPossibleStatus = 0
			for (let standard of item.standards) {
				if (standard.progress > -1) {
					status += standard.progress
					highestPossibleStatus += 2
				}
			}
			return (status / highestPossibleStatus) * 100
		},
	},
	components: {
		AdsButton,
		AdsDataTable,
		ProgressChip,
		AssessmentBanner,
		GenerateQIP,
		Alert,
		AddPhilosophyStatement,
	},
}

export default selfAssessment
</script>

<style lang="scss" scoped>
@import "../scss/invert-banner.scss";

#main-content {
	padding-bottom: 0px !important;
	::v-deep div.pl-0.col {
		padding-top: 0px !important;
	}
}
::v-deep .v-data-table__wrapper {
	.v-data-table-header th.text-start {
		.v-btn__content {
			font-weight: 500;
		}
	}
	tr td:nth-child(1) {
		font-weight: 500 !important;
		font-size: 1.05em;
	}
	tbody tr {
		cursor: pointer;
		td.text-start {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}

	tr td:nth-child(2) .v-data-table__mobile-row__cell b {
		color: $ads-navy;
	}
}
::v-deep .mobile .v-data-table__wrapper {
	tbody tr {
		border-bottom: 1px solid black;
		td:nth-child(3),
		td:nth-child(4),
		td:nth-child(5) {
			min-height: 0px !important;
			height: 0px !important;
		}
		td:nth-child(4) {
			display: flex;
			float: right;
			margin-top: -10px;
		}
		td.v-data-table__mobile-row {
			padding-right: 1em;
		}
	}
}
::v-deep .searchInput {
	display: none;
}
::v-deep .v-skeleton-loader__trow.v-skeleton-loader__bone {
	display: flex;
	justify-content: space-between;
	.v-skeleton-loader__table-cell {
		width: 100px;
		.v-skeleton-loader__text {
			height: 45%;
		}
	}
}
::v-deep .embeddedalert .v-alert.v-sheet {
	box-shadow: none !important;
}
</style>
