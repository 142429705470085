/*
  Allows key endpoints to be overridden by setting a global variable (window.linksConfig)
  in the html hosted in Liferay ("index.html"). The idea is that changes could be made 
  without having to rebuild the front-end code. However it seems doubtful that this 
  feature will ever be used.
*/

import { val } from "."

const defaults = {
	baseApiUrl: process.env.VUE_APP_ROOT_API,
	logout: process.env.VUE_APP_LOGOUT_URL,
	originalPortalUrl: "https://portal.det.nsw.edu.au",
}

export const getUrl = (item) => {
	return val(window.linksConfig, item) || val(defaults, item)
}

export const baseApiUrl = getUrl("baseApiUrl")
