<template>
	<div>
		<h2 class="mb-3">Compliance</h2>
		<p>
			While compliance with
			<a href="https://www.acecqa.gov.au/nqf/national-law-regulations"
				>legislation</a
			>
			is an everyday requirement, it is also a crucial part of self-assessment.
			Once you have critically reflected and assessed how your practice is
			meeting and perhaps exceeding the standards, consider whether you are also
			still compliant. Complete the following checklist and if any items are not
			being met, address those immediately.
		</p>

		<div>
			<template v-if="isLoading">
				<v-card v-for="i in [1, 2]" :key="i" outlined class="my-3">
					<v-card-title>
						<v-skeleton-loader
							class="chip-loader"
							:type="'heading, avatar'"
							width="100%"
						/>
					</v-card-title>
				</v-card>
			</template>
			<template v-else>
				<AdsExpansionPanel :items="items" class="mb-8">
					<template v-for="(item, i) of items" #[`content${i+1}`]>
						<div :key="i" class="px-3">
							<v-row v-for="(check, j) of item.data" :key="j">
								<v-checkbox
									class="px-6"
									:dense="true"
									v-model="assessmentQA.checklist[check.id]"
									@change="trackDirty"
									:disabled="check.blank"
									:readonly="viewOnly"
									:class="{
										'ml-6': check.title.slice(0, 2) == '- ',
										'mt-n1': check.title.slice(0, 2) == '- ',
									}"
								>
									<template #label>
										<v-col cols="9" sm="10">
											<div
												v-for="(line, k) in check.title.split('\n')"
												:key="k"
												class="pr-2"
												:class="{
													'pt-2': k > 0,
													'pl-5': line.indexOf('\t') != -1,
												}"
											>
												{{ line }}
											</div>
										</v-col>
										<v-col cols="3" sm="2">
											<b class="source"> {{ check.source }} </b>
										</v-col>
									</template>
								</v-checkbox>
							</v-row>
						</div>
					</template>
				</AdsExpansionPanel>
			</template>
		</div>
	</div>
</template>

<script>
import { AdsExpansionPanel } from "@nswdoe/doe-ui-core"
const ComplianceChecklist = {
	name: "ComplianceChecklist",
	components: { AdsExpansionPanel },
	props: {},
	created() {
		window.addEventListener("beforeunload", this.saveChecklist)
	},
	beforeUnmount() {
		this.saveChecklist()
	},
	computed: {
		items() {
			if (this.isLoading) return

			let toRet = []
			this.selectedQualityAreaStructure.children.standards.forEach(
				(standard) => {
					toRet.push({
						title: `Standard ${standard.number}`,
						data: standard.checklist,
					})
				},
			)
			return toRet
		},
		assessmentQA() {
			return this.$store.getters.selectedAssessment?.qualityAreas?.find(
				(v) => v.name == this.selectedQualityAreaStructure.short,
			)
		},
		selectedQualityAreaStructure() {
			return this.$store.getters.selectedQualityAreaStructure
		},
		isLoading() {
			return (
				this.$store.getters.qualityAreaStructureIsLoading ||
				this.$store.getters.assessmentIsLoading
			)
		},
		viewOnly() {
			return this.$store.getters.viewOnly
		},
	},
	data() {
		return {
			dirtySaveDelay: 5000,
			dirtySaveTimeoutId: null,
			isDirty: false,
		}
	},
	methods: {
		async saveChecklist() {
			try {
				await this.$store.dispatch("saveChecklist")
				this.isDirty = false
				return true
			} catch (err) {
				console.error(err)
				return false
			}
		},
		trackDirty() {
			if (this.dirtySaveTimeoutId) clearTimeout(this.dirtySaveTimeoutId)

			this.isDirty = true
			this.dirtySaveTimeoutId = setTimeout(() => {
				this.saveChecklist()
			}, this.dirtySaveDelay)
		},
	},
}

export default ComplianceChecklist
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";

::v-deep .chip-loader {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.v-skeleton-loader__heading {
		margin-right: 7px;
		width: 200px;
		max-width: 40%;
	}
	.v-skeleton-loader__avatar {
		width: 32px;
		height: 32px;
	}
}
::v-deep label.v-label {
	color: $ads-dark;
}
::v-deep
	.v-expansion-panel-content__wrap
	.v-input--dense.v-input--selection-controls.v-input--checkbox {
	width: 100%;
	margin-top: 12px;
	margin-bottom: 4px;
	.source {
		overflow-wrap: break-word;
	}
	.v-label div {
		padding-top: 4px;
		padding-bottom: 4px;
	}
	.v-messages {
		min-height: 0px;
	}
}
</style>
