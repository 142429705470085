/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import locations from "./modules/locations"
import assessments from "./modules/assessments"
import centre from "./modules/centre"
import guides from "./modules/guides"
import strengths from "./modules/strengths"
import issues from "./modules/issues"

export default {
	locations,
	assessments,
	centre,
	guides,
	strengths,
	issues,
}
