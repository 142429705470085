import API from "../apiHelper"
import { APIRESOURCE } from "../../utils/constants"
import createAuthHeaders from "../helpers"
import qs from "qs"
import axios from "axios"

const updateStrength = async (assessmentId, strengthId, strengthData) => {
	/*console.log(
		`UpdateStrength API -> { ${assessmentId}, ${strengthId}   ${JSON.stringify(
			strengthData,
		)} }`,
	)*/

	try {
		const response = await axios({
			method: 'post',
			url: APIRESOURCE.STRENGTHS.SETSINGLE +
			"?" +
			qs.stringify({ assessmentId, strengthId }),
			data: { strengthData: strengthData },
			headers: createAuthHeaders({
				"Content-Type": "application/json",
				"x-correlation-id": "MarkTest-34545-23234-345345",
			}),
			spinner: false,
		});
		
		return response;
	} catch (error) {
		console.log("update Strength failed..." + error);
	}
	// API.post({
	// 	endpoint:
	// 		APIRESOURCE.STRENGTHS.SETSINGLE +
	// 		"?" +
	// 		qs.stringify({ assessmentId, strengthId }),
	// 	headers: createAuthHeaders({
	// 		"Content-Type": "application/json",
	// 		"x-correlation-id": "MarkTest-34545-23234-345345",
	// 	}),
	// 	payload: { strengthData: strengthData },
	// 	// mock:  "updateStrength",
	// 	spinner: false,
	// }).then((data) => {
	// 	return data
	// })
}

const registerEvidence = (evidence) => {
	//console.log(`registerEvidence API -> { ${JSON.stringify(evidence)} }`)

	let formData = new FormData()
	formData.append("file", evidence.file)
	formData.append("id", evidence.id)
	formData.append("centre_id", evidence.centre_id)
	formData.append("comment", evidence.comment)

	return API.post({
		endpoint: APIRESOURCE.STRENGTHS.REGISTEREVIDENCE,
		headers: createAuthHeaders({
			"Content-Type": "multipart/form-data",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		payload: formData,
		// mock:  "registerEvidence",
		spinner: false,
	})
}

const getEvidenceData = (id) => {
	return API.get({
		endpoint:
			APIRESOURCE.STRENGTHS.GETEVIDENCEDATA + "?" + qs.stringify({ id }),
		headers: createAuthHeaders({
			"Content-Type": "multipart/form-data",
			"x-correlation-id": "MarkTest-34545-23234-345345",
			Accept: "application/octet-stream",
		}),
		spinner: false,
	})
}

const getEvidence = (URL) => {
	return axios.get(
		`${APIRESOURCE.STRENGTHS.GETEVIDENCE}?${qs.stringify({
			key: btoa(URL) + "",
		})}`,
		{
			headers: createAuthHeaders({
				"Content-Type": "multipart/form-data",
				"x-correlation-id": "MarkTest-34545-23234-345345",
				//Accept: "application/octet-stream",
			}),
			//responseType: "blob",
			spinner: false,
		},
	)
	/*return API.get({
		endpoint: `${APIRESOURCE.STRENGTHS.GETEVIDENCE}?${qs.stringify({
			key: btoa(URL) + "",
		})}`,
		headers: createAuthHeaders({
			"Content-Type": "multipart/form-data",
			"x-correlation-id": "MarkTest-34545-23234-345345",
			Accept: "application/octet-stream",
		}),
		//responseType: "blob",
		spinner: false,
	})*/
}

const deleteOldEvidence = (id) => {
	//console.log(`deleteOldEvidence API -> { ${id}}`)

	return API.delete({
		endpoint: APIRESOURCE.STRENGTHS.DELETEOLDEVIDENCE,
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		payload: { id },
	})
}

export default {
	updateStrength,
	registerEvidence,
	deleteOldEvidence,
	getEvidence,
	getEvidenceData,
}
