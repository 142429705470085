export default [
	{
		id: 23961,
		requestId: "50bbd6bb-d42f-4062-9adb-efab4be160fc",
		appName: "StaffPortalApp",
		fromAppName: "OES",
		displayMethod: "",
		emailAddress: "doris.justinwilson@det.nsw.edu.au",
		role: "",
		channels: ["other"],
		segments: null,
		messageTitle: "Provisioning staff access to ebs",
		messageBody:
			"From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",
		htmlBody:
			"<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",
		messageType: "text/plain",
		messageDeeplink:
			"https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",
		messageDeeplinkType: "essentials",
		receivedDatetime: "2021-07-12T06:43:15Z",
		messageContent: {
			body: "From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",
			data: {
				time: "03:37 PM",
				type: "essentials",
				deeplink:
					"https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",
			},
			title: "Provisioning staff access to ebs",
			htmlBody:
				"<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",
			messageType: "text/plain",
		},
		payload:
			'{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "",\r\n    "channels": [\r\n    "other"\r\n  ],\r\n  "fromAppName": "OES",\r\n  "emails" : ["doris.justinwilson@det.nsw.edu.au"],\r\n  "message": {\r\n    "title": "Provisioning staff access to ebs",\r\n    "body": "From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",\r\n "htmlBody": "<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",\r\n        "data": {\r\n      "deeplink": "https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",\r\n      "type": "essentials",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  }\r\n}',
	},
	{
		id: 23960,
		requestId: "82aa2eb1-c126-4429-9d2d-bf379381bbb4",
		appName: "StaffPortalApp",
		fromAppName: "OES",
		displayMethod: "",
		emailAddress: "doris.justinwilson@det.nsw.edu.au",
		role: "",
		channels: ["other"],
		segments: null,
		messageTitle: "Provisioning staff access to ebs",
		messageBody:
			"From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",
		htmlBody:
			"<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",
		messageType: "text/plain",
		messageDeeplink:
			"https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",
		messageDeeplinkType: "essentials",
		receivedDatetime: "2021-07-12T06:43:06Z",
		messageContent: {
			body: "From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",
			data: {
				time: "03:37 PM",
				type: "essentials",
				deeplink:
					"https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",
			},
			title: "Provisioning staff access to ebs",
			htmlBody:
				"<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",
			messageType: "text/plain",
		},
		payload:
			'{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "",\r\n    "channels": [\r\n    "other"\r\n  ],\r\n  "fromAppName": "OES",\r\n  "emails" : ["doris.justinwilson@det.nsw.edu.au"],\r\n  "message": {\r\n    "title": "Provisioning staff access to ebs",\r\n    "body": "From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",\r\n "htmlBody": "<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",\r\n        "data": {\r\n      "deeplink": "https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",\r\n      "type": "essentials",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  }\r\n}',
	},
	{
		id: 23959,
		requestId: "6aa7dfff-42cd-4224-ba32-2e9df6476012",
		appName: "StaffPortalApp",
		fromAppName: "OES",
		displayMethod: "portal_banner",
		emailAddress: "doris.justinwilson@det.nsw.edu.au",
		role: "",
		channels: ["other"],
		segments: null,
		messageTitle: "Provisioning staff access to ebs",
		messageBody:
			"From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",
		htmlBody:
			"<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",
		messageType: "text/plain",
		messageDeeplink:
			"https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",
		messageDeeplinkType: "essentials",
		receivedDatetime: "2021-07-12T06:42:50Z",
		messageContent: {
			body: "From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",
			data: {
				time: "03:37 PM",
				type: "essentials",
				deeplink:
					"https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",
			},
			title: "Provisioning staff access to ebs",
			htmlBody:
				"<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",
			messageType: "text/plain",
		},
		payload:
			'{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "portal_banner",\r\n    "channels": [\r\n    "other"\r\n  ],\r\n  "fromAppName": "OES",\r\n  "emails" : ["doris.justinwilson@det.nsw.edu.au"],\r\n  "message": {\r\n    "title": "Provisioning staff access to ebs",\r\n    "body": "From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.",\r\n "htmlBody": "<p>From 4pm to 7pm, Thursday 10 June, OES for schools will be unavailable due to system maintenance.</p>",\r\n        "data": {\r\n      "deeplink": "https://education.nsw.gov.au/inside-the-department/human-resources/pay-leave-and-benefits/pay-and-salaries/payment-summary",\r\n      "type": "essentials",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  }\r\n}',
	},
]
