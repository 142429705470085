function debounce(func, wait, immediate) {
	let timeout
	return function () {
		let context = this
		let args = arguments
		let callNow = immediate && !timeout
		clearTimeout(timeout)

		// Set the new timeout
		timeout = setTimeout(() => {
			// Inside the timeout function, clear the timeout variable
			// which will let the next execution run when in 'immediate' mode
			timeout = null
			if (!immediate) {
				func.apply(context, args)
			}
		}, wait)

		if (callNow) func.apply(context, args)
	}
}

export { debounce }
