import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { IeBlocker, OAuth } from "@nswdoe/doe-ui-core";
import api from "./api";
import apiHelper from "./api/apiHelper";
import VueSimpleMarkdown from "vue-simple-markdown";
import "vue-simple-markdown/dist/vue-simple-markdown.css";
import gtag from "./gtag";
import CONFIG from "./utils/authConfig"; // Adjust the path as needed

global.Buffer = global.Buffer || require("buffer").Buffer;

/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
Vue.mixin(titleMixin);
apiHelper.setStore(store);

Vue.use(VueSimpleMarkdown);
import titleMixin from "./mixins/titleMixin";

let renderComponent, renderProps;

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: "Self-Assessment and Planning",
    },
  };
} else {
  // render App
  renderComponent = App;
  renderProps = {};

  // Config the App root instance (oAuth plugin, etc.)
  Vue.config.productionTip = false;

  /**
   * Global event bus
   * $eventHub is used in some components such as AppBar and NavigationDrawer for cross-component communication.
   * For example, in AppBar it can emit an event 'toggleNavigationDrawer'
     <v-app-bar-nav-icon
        v-if="showNavIcon && !showBackButton"
        @click.stop="$eventHub.$emit('toggleNavigationDrawer')"
        class="ml-3 mr-2"
        aria-label="Toggle navigation menu"
     />
   Then in other components you can listen to this event.
   More details on event in Vue.js: https://vuejs.org/v2/guide/events.html
   */
  Vue.prototype.$eventHub = new Vue();

  // Unified interface for API requests
  Vue.prototype.$api = api;
}

const GTM_CONFIG = {
  GTM_ID: process.env.GTM_ID ? process.env.GTM_ID : "GTM-5KFV3F4H",
  GTM_AUTH: process.env.GTM_AUTH ? process.env.GTM_AUTH : "",
  GTM_PREVIEW: process.env.GTM_PREVIEW ? process.env.GTM_PREVIEW : "",
  GTM_WIN: process.env.GTM_WIN ? process.env.GTM_WIN : "",
};
//console.log(CONFIG, process.env.VUE_APP_OAUTH_REDIRECT_URL)
// eslint-disable-next-line no-console

const oAuthConfig = {
  router: router,
  authoriseUri: CONFIG.VUE_APP_OAUTH_URL,
  logoutUri:
    CONFIG.VUE_APP_OAUTH_LOGOUT_URL ||
    "https://ssoaddonsext.det.nsw.edu.au/ssoaddon/logout",
  tokenUri: CONFIG.VUE_APP_OAUTH_TOKEN_URL,
  enablePopupAuth: true,
  secureApp: false,
  forceProdMode: true,
  clientSecret: "",
  // authorise url params
  pkce: true,
  //   afterLogin: (a, b) => {
  //     // eslint-disable-next-line no-console
  // 	// console.log('CONFIG', CONFIG);
  //     // console.log("oAuth Login succeeded", a, "/", b);
  //   },
  params: {
    client_id: CONFIG.VUE_APP_OAUTH_CLIENT_ID,
    redirect_uri: `${window.location.origin}/login/callback`,
    scope: encodeURIComponent(process.env.VUE_APP_OAUTH_SCOPES), //CONFIG.VUE_APP_OAUTH_SCOPES),
  },
};

Vue.use(OAuth, oAuthConfig);

gtag(Vue, router, GTM_CONFIG);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(renderComponent, renderProps),
}).$mount("#app");

import Ripple from "vuetify/lib/directives/ripple";
Vue.directive("ripple", Ripple);
