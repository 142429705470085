<template>
	<v-container
		:class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile pb-5' : 'px-10 pb-5'"
	>
		<v-row class="my-6" :no-gutters="true">
			<div style="display: flex; align-items: center" @click="goBack()">
				<v-icon class="mr-2">arrow_back</v-icon>
				<a :align="'center'" style="color: #041e42">Back</a>
			</div>
		</v-row>
		<MarkdownAlert
			v-if="!$vuetify.breakpoint.xsOnly"
			markdown-text="#### Identifying issues and determining priorities for improvement
**Step 1.** You may identify an issue from:
* limited examples based on the questions for an element
* limited evidence of exceeding themes based on your examples
* whole school or community goals
* areas of strength that you would like to do better.
**Step 2.** Review your issues; consolidate, combine and re-prioritise them.

**Step 3.** Select one or more issues from the list below to create a goal."
		/>
		<!--Alert
			class="embeddedalert"
			show-alert
			type="info"
			html-text="
				<div class='snackbar--text font-weight-bold'> Identifying issues and determining priorities for improvement </div>
				<div class='pt-1 alert--textsimple font-weight-regular'>
					<span class='font-weight-bold'> Step 1. </span>
					Review your issues: What things are most important and why?
				</div>
				<div class='pt-1 alert--textsimple font-weight-regular'>
					<span class='font-weight-bold'> Step 2. </span>
					Assign a priority of high, medium or low. Consider which issues relate to whole school improvement goals. 
				</div>
				<div class='pt-1 alert--textsimple font-weight-regular'>
					<span class='font-weight-bold'> Step 3. </span>
					Select issues to form a goal (some issues may be related and can be combined)
				</div>
			"
			in-page
		/-->
		<v-row style="justify-content: right" class="pb-4 pt-2">
			<div class="px-2">
				<AdsButton
					:icon="'add_circle_outline'"
					:secondary="true"
					button-text="Add new issue"
					:disabled="loading"
					@click="openAddNewIssue"
				/>
			</div>
			<div class="px-2">
				<AdsButton
					:disabled="!hasSelectedIssues || loading"
					button-text="Create goal"
					@click="openGoalDialog"
				/>
			</div>
		</v-row>
		<AdsDataTable
			:headers="dataTableHeaders"
			:loading="loading"
			:items="issues"
			show-select
			@input="logCurrentItems"
		>
			<template #loading>
				<v-skeleton-loader
					:types="{ trow: 'table-cell@6' }"
					class="mt-3"
					:type="'trow@3'"
				></v-skeleton-loader>
			</template>
			<template #[`item.standard`]="{ item }">
				<b> {{ getStandardByShort(item.standard).name }} </b>
			</template>
			<template #[`item.details`]="{ item }">
				{{ item.details.slice(0, 20)
				}}{{ item.details.length > 20 ? "..." : "" }}
			</template>
			<template #[`item.owner`]="{ item }">
				<div class="secondary-text">{{ item.owner }}</div>
			</template>
			<template #[`item.date`]="{ item }">
				<div class="secondary-text">{{ formatDate(item.date) }}</div>
			</template>
			<template #[`item.priority`]="{ item }">
				<div v-if="item.priority == 0">Low</div>
				<div v-if="item.priority == 1">Medium</div>
				<div v-if="item.priority == 2">High</div>
			</template>
			<template #[`item.status`]="{ item }">
				<ProgressChip :date="item.status" :type="1" />
			</template>
			<template #[`item.data-table-select`]="{ item, isSelected, select }">
				<v-simple-checkbox
					:disabled="item.status == 1"
					:value="isSelected"
					@input="select"
				/>
			</template>
			<template #[`item.edit`]="{ item }">
				<div v-if="item.status == 0" class="edit-icon-wrapper">
					<v-icon :outlined="true" @click="handleDelete(item)"
						>mdi-delete-outline</v-icon
					>
				</div>
			</template>
		</AdsDataTable>

		<v-dialog
			v-model="addingNewIssue"
			max-width="1000px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<v-card tile>
				<v-card-title
					class="d-flex align-center justify-space-between pa-2 px-5"
					:class="{ 'pl-3': $vuetify.breakpoint.xsOnly }"
				>
					<div class="d-flex align-center">
						<h3 style="display: flex; align-items: center">
							<span class="pt-1">
								Quality Area {{ selectedQualityArea.number }}
							</span>
						</h3>
					</div>
					<div class="ml-3">
						<v-btn icon aria-label="close" @click="handleBack">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</v-card-title>
				<v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

				<v-card-text class="mt-5 px-8 normal-text">
					<v-row>
						<b class="subtitle-1"> Standard </b>
					</v-row>
					<v-radio-group row v-model="newIssueData.standard" class="pb-4">
						<v-radio
							v-for="(standard, i) of standards"
							:key="i"
							:label="standard.name"
							:value="standard.short"
						/>
					</v-radio-group>
					<v-row class="pb-2 px-3">
						<v-textarea
							v-model="newIssueData.details"
							outlined
							placeholder=""
							rows="3"
							auto-grow
							:label="'Details of the issue'"
							:counter="500"
							:rules="[rules.required, rules.under500]"
							ref="issueDetails"
						/>
					</v-row>
					<v-row class="pb-1">
						<b class="subtitle-1"> Priority </b>
					</v-row>
					<v-radio-group row class="pb-1" v-model="newIssueData.priority">
						<v-col
							v-for="(label, i) of priorityOptions"
							cols="4"
							class="py-0 px-0"
							:key="i"
						>
							<v-radio class="bordered px-4 py-8" :label="label" :value="i" />
						</v-col>
					</v-radio-group>
					<v-row class="bottom-row">
						<v-col cols="12">
							<AdsButton class="mx-2" @click="handleBack" :secondary="true"
								>Cancel</AdsButton
							>
							<AdsButton
								class="mx-2"
								@click="submitIssue"
								:disabled="canSubmitIssue"
								>Done</AdsButton
							>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="viewingGoal"
			max-width="1000px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<GoalDisplay
				mode="add"
				@handleBack="handleBack"
				:selected-issues="selectedItems"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import { AdsButton, AdsDataTable } from "@nswdoe/doe-ui-core"
import ProgressChip from "./ProgressChip.vue"
import GoalDisplay from "./GoalDisplay.vue"
import MarkdownAlert from "./MarkdownAlert.vue"
import { mapGetters } from "vuex"
import api from "../api"

export default {
	components: {
		MarkdownAlert,
		AdsButton,
		AdsDataTable,
		ProgressChip,
		GoalDisplay,
	},
	name: "IdentifiedIssues",
	props: {},
	created() {
		if (this.readyToLoadIssues) {
			this.$store.dispatch("fetchIssues")
			this.$store.dispatch("fetchGoals", this.selectedQualityArea.short)
		}
	},
	data() {
		return {
			dataTableHeaders: [
				{ text: "Standard", value: "standard" },
				{ text: "Summary", value: "details" },
				{ text: "Priority", value: "priority" },
				{ text: "Added by", value: "owner" },
				{ text: "Date added", value: "date" },
				{ text: "Goal status", value: "status" },
				{ text: "", value: "edit" },
			],
			addingNewIssue: false,
			viewingGoal: false,
			newIssueData: {
				standard: "",
				details: "",
				priority: 0,
			},
			priorityOptions: ["Low", "Medium", "High"],
			rules: {
				required: (value) => !!value || "Please fill out this field.",
				under500: (value) =>
					(value && value.length <= 500) || "Must be less than 500 characters.",
			},
			selectedItems: [],
		}
	},
	watch: {
		readyToLoadIssues(newVal) {
			if (newVal == true) {
				this.$store.dispatch("fetchIssues")
				this.$store.dispatch("fetchGoals", this.selectedQualityArea.short)
			}
		},
	},
	computed: {
		...mapGetters({
			issues: "issues",
			structure: "selectedQualityAreaStructure",
			assessment: "selectedAssessment",
			issuesAreLoading: "issuesAreLoading",
			assessmentIsLoading: "assessmentIsLoading",
			structureIsLoading: "qualityAreaStructureIsLoading",
			hasSelectedQualityArea: "hasSelectedQualityArea",
			selectedQualityArea: "selectedQualityArea",
			userProfile: "profile",
			username: "username",
		}),
		canSubmitIssue() {
			return (
				this.newIssueData.details &&
				this.$refs.issueDetails &&
				!this.$refs.issueDetails.valid
			)
		},
		hasSelectedIssues() {
			return this.selectedItems.length > 0
		},
		loading() {
			return (
				this.issuesAreLoading ||
				this.assessmentIsLoading ||
				this.structureIsLoading
			)
		},
		standards() {
			return this.structure.children?.standards
		},
		readyToLoadIssues() {
			return (
				!this.assessmentIsLoading &&
				!this.structureIsLoading &&
				this.hasSelectedQualityArea
			)
		},
	},
	methods: {
		getStandardByShort(short) {
			return this.structure.children?.standards.find((a) => a.short == short)
		},
		goBack() {
			this.$emit("goBack")
		},
		handleDelete(item) {
			this.deleteIssue(item)
		},
		async deleteIssue(item) {
			this.addingNewIssue = false
			let issueId = item.id

			let data = await api.issues
				.deleteIssue(
					this.assessment.assessment_id,
					this.selectedQualityArea.short,
					issueId,
				)
				.catch((err) => {
					this.$store.dispatch("displayApiError", [
						"your issue",
						"delete",
						err?.reason || err?.data?.reason || err?.status,
					])
				})

			if (data) {
				this.$store.dispatch(
					"quickConfirmation",
					"Successfully deleted your issue",
				)
				if (data) {
					console.log(this.issues.filter((v) => v.id !== issueId))
					this.$store.commit("setIssues", {
						issues: this.issues.filter((v) => v.id !== issueId),
					})
				}
			}

			this.handleBack()
		},
		formatDate(date) {
			if (!date) return ""
			if (typeof date == "string" && new Date(date) != "Invalid Date")
				date = new Date(date)
			else if (typeof date == "string") return ""
			return date.toLocaleDateString("en-au")
		},
		handleBack() {
			this.addingNewIssue = false
			this.viewingGoal = false
		},
		openAddNewIssue() {
			this.addingNewIssue = true
			this.newIssueData.standard = this.standards[0].short
			this.newIssueData.details = ""
			this.newIssueData.priority = 0
		},
		openGoalDialog() {
			this.viewingGoal = true
		},
		async submitIssue() {
			this.newIssueData.owner = this.userProfile.username
			this.newIssueData.status = 0
			this.addingNewIssue = false

			await api.issues
				.addNewIssue(
					this.assessment.assessment_id,
					this.selectedQualityArea.short,
					this.newIssueData,
				)
				.then((data) => {
					if (data) {
						this.$store.dispatch(
							"quickConfirmation",
							"Successfully added your issue",
						)
						this.$store.commit("setIssues", data.data)
					}
				})
				.catch((err) => {
					console.error(err)
					this.$store.dispatch("displayApiError", [
						"your issue",
						"add",
						err?.reason || err?.data?.reason || err?.status || "error:unknown",
					])
				})
				.finally(() => {
					this.handleBack()
				})
		},
		logCurrentItems(d) {
			this.selectedItems = d
		},
	},
}
</script>

<style lang="scss" scoped>
@import "../scss/ads.scss";

.bordered {
	border-radius: 5px;
	border: 1px solid $ads-light-40;
}
.v-radio.bordered.v-item--active {
	background: $ads-custom-navy-2;
	border: 2px solid $ads-navy;
}
.theme--light {
	.normal-text :deep(.v-label) {
		color: $ads-dark;
	}
	b.subtitle-1 {
		color: $ads-navy;
	}
}
.bottom-row {
	justify-content: right;
	.col {
		display: flex;
		justify-content: right;
	}
}
.secondary-text {
	color: $ads-dark-60;
}
:deep(.v-input__control .v-input__slot .v-text-field__slot label.v-label) {
	min-height: 1.1em;
}
:deep(.v-data-table__wrapper) {
	tbody tr {
		td:first-child {
			padding: 21px 31px;
		}
		td {
			b {
				color: $ads-navy;
			}
		}
	}
}
:deep(.v-skeleton-loader__trow.v-skeleton-loader__bone) {
	display: flex;
	justify-content: space-between;
	.v-skeleton-loader__table-cell {
		width: 100px;
		.v-skeleton-loader__text {
			height: 45%;
		}
	}
}
:deep(.v-input__control .v-input__slot legend) {
	width: 9em !important;
}
/* replicate .v-label--active permanently */
:deep(.v-input__control .v-input__slot .v-text-field__slot label.v-label) {
	@extend .v-label--active;
	transform: translateY(-24px) scale(0.75);
	font-size: 1.25rem !important;
	padding-top: 2px;
}

:deep(.embeddedalert .v-alert.v-sheet) {
	background: #e0e9ff;
	box-shadow: none !important;
}

:deep(.v-counter) {
	padding-top: 2px;
}
</style>
