import qs from "qs"
import API from "../apiHelper"
import { APIRESOURCE } from "../../utils/constants"
import createAuthHeaders from "../helpers"

const getCentre = (user, location) => {
	//console.log(`Get centre { ${user}, ${location} }`)
	return API.get({
		endpoint:
			APIRESOURCE.GETCENTRE +
			"?" +
			qs.stringify({ userId: user, location: location }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchSettings",
		spinner: false,
	})
}

const setSettings = (centreId, settings) => {
	//console.log(`Set settings { ${centreId}, ${settings} }`)
	return API.post({
		endpoint: APIRESOURCE.SAVESETTINGS + "?" + qs.stringify({ centreId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "setSettings",
		payload: { settings: settings },
		spinner: false,
	})
}

export default { getCentre, setSettings }
