<!-- eslint-disable prettier/prettier -->
<template>
	<v-card tile class="goal-display" @input="checkValid">
		<v-card-title
			class="d-flex align-center justify-space-between pa-2 px-5"
			:class="{ 'pl-3': $vuetify.breakpoint.xsOnly }"
		>
			<div class="d-flex align-center pt-3">
				<h3>
					<div class="d-flex align-center">
						<FeatureIcon
							class="mr-3 mt-4"
							size="42"
							color="navy"
							background-color="light blue"
							icon="sports_score"
						/>
						<span class="pt-1"> Goal for {{ selectedQualityArea.name }} </span>
					</div>
					<p class="normal-text pl-8 ml-6 mt-n3">
						Quality Area {{ selectedQualityArea.number }}
					</p>
				</h3>
			</div>
			<div class="ml-3">
				<v-btn icon aria-label="close" @click="handleBack">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

		<TabNavigation :items="tabItems" :center-active="true" class="custom-tab">
			<template #content1>
				<v-card-text class="mt-5 px-8 normal-text">
					<v-row>
						<Alert
							class="embeddedalert col-12 mt-n5"
							show-alert
							type="info"
							html-text="
								<div class='snackbar--text font-weight-bold'> Consider the following when developing your goal based on the issue selected: </div>
								<div class='pt-1 alert--textsimple font-weight-regular'>
									<span class='font-weight-bold'> 1. </span>
									What will you do differently?
								</div>
								<div class='pt-1 alert--textsimple font-weight-regular'>
									<span class='font-weight-bold'> 2. </span>
									What will your work achieve? 
								</div>
								<div class='pt-1 alert--textsimple font-weight-regular'>
									<span class='font-weight-bold'> 3. </span>
									What will your change in practice look like for children, educators, families or community?
								</div>
								<div class='pt-1 alert--textsimple font-weight-regular'>
									<span class='font-weight-bold'> 4. </span>
									How will this goal contribute to improved outcomes for children?
								</div>
							"
							in-page
						/>
					</v-row>
					<v-row class="mb-3">
						<v-col>
							<span class="px-2 secondary-text">
								{{ selectedIssues.length }}
								{{ mode == "add" ? "issue(s) selected" : "relevant issue(s)" }}
							</span>
							<a @click="toggleShowIssues">
								<b class="blue-text">
									<template v-if="!showIssues"> Show </template>
									<template v-else> Hide </template>
								</b>
							</a>
						</v-col>
						<v-col>
							<p class="secondary-text float-right">
								<v-checkbox
									label="Mark as achieved"
									class="pt-0 mt-0"
									v-model="goalStatus"
								/>
							</p>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="showIssues">
							<div v-for="(issue, i) of selectedIssues" :key="i" class="mb-5">
								<b class="mb-0">
									Standard {{ getStandard(issue.standard).number }}
								</b>
								<p class="mb-0">{{ issue.details }}</p>
								<p class="secondary-text">
									{{
										issue.priority == 0
											? "Low"
											: issue.priority == 1
											? "Medium"
											: "High"
									}}
								</p>
							</div>
						</div>
					</v-expand-transition>

					<div>
						<v-row class="mb-n5">
							<v-col>
								<v-textarea
									outlined
									placeholder=""
									rows="6"
									auto-grow
									:label="'Purpose'"
									:counter="500"
									:rules="[rules.under500]"
									ref="issueDetails"
									v-model="goal.purpose"
								/>
							</v-col>
						</v-row>
						<span class="secondary-text"
							>What is the reason for focusing on this goal?</span
						>

						<v-row class="mb-n5 mt-8 small-label">
							<v-col>
								<v-textarea
									outlined
									placeholder=""
									rows="6"
									auto-grow
									:label="'Goal'"
									:counter="500"
									:rules="[rules.under500]"
									ref="issueDetails"
									v-model="goal.summary"
								/>
							</v-col>
						</v-row>
						<span class="secondary-text"
							>What goal or outcome do you want to achieve? Include any relevant
							links to the Strategic Improvement Plan (SIP)</span
						>

						<v-row class="mb-n5 mt-8 big-label">
							<v-col>
								<v-textarea
									outlined
									placeholder=""
									rows="6"
									auto-grow
									:label="'Steps, strategies, and resourcing'"
									:counter="500"
									:rules="[rules.under500]"
									ref="issueDetails"
									v-model="goal.strategies"
								/>
							</v-col>
						</v-row>
						<span class="secondary-text"
							>What are the steps, strategies or resourcing required?</span
						>

						<v-row class="mb-n5 mt-8 medium-label">
							<v-col>
								<v-textarea
									outlined
									placeholder=""
									rows="6"
									auto-grow
									:label="'Success measures'"
									:counter="500"
									:rules="[rules.under500]"
									ref="issueDetails"
									v-model="goal.measures"
								/>
							</v-col>
						</v-row>
						<span class="secondary-text"
							>What does success of this goal look like for children and
							families?</span
						>

						<v-row class="mt-8">
							<v-col class="small-label">
								<AdsTextField
									:label="'Who?'"
									class="mb-n3"
									:rules="[rules.under200]"
									:counter="200"
									ref="issueWho"
									v-model="goal.who"
								/>
								<span class="secondary-text"> Who will lead the changes? </span>
							</v-col>
							<v-col>
								<DatePicker
									:top="true"
									:label="'By when?'"
									class="mb-n3"
									v-model="goal.due"
									:rules="[]"
									:output-date-format="'DD/MM/YYYY'"
								/>
								<span class="secondary-text">
									When do we expect to achieve this?
								</span>
							</v-col>
						</v-row>
					</div>

					<v-row class="bottom-row">
						<v-col cols="12" class="justify-space-between">
							<SaveStatus class="mt-3" :status="status" />
							<div>
								<AdsButton
									tertiary
									class="mx-2 red-btn"
									@click="handleDelete"
									v-if="mode === 'edit'"
								>
									<v-icon class="mr-2">mdi-delete-outline</v-icon>
									<span class="mt-1"> Delete goal </span>
								</AdsButton>
								<AdsButton class="mx-2" @click="handleBack" :secondary="true"
									>Cancel</AdsButton
								>
								<AdsButton
									class="mx-2"
									:disabled="!goalIsValid || goalIsSaving"
									@click="submitGoal()"
									>Done</AdsButton
								>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</template>
			<template #content2>
				<v-card-text class="mt-5 px-8 normal-text">
					<v-row class="mb-n5 regular-label">
						<v-col>
							<v-textarea
								outlined
								placeholder=""
								rows="6"
								auto-grow
								:label="'Progress notes'"
								:counter="2500"
								ref="issueDetails"
								v-model="newProgressNoteText"
							/>
						</v-col>
					</v-row>
					<span class="secondary-text"
						>What does success of this goal look like for children and
						families?</span
					>

					<div class="mt-8">
						<b> Note history </b> ({{ goal.progressNotes.length }})

						<div>
							<div
								v-for="(note, i) in goal.progressNotes"
								class="my-6"
								:key="i"
							>
							<div v-if="editMode === i">
									<v-textarea
										outlined
										placeholder=""
										:label="'Progress note'"
										:counter="2500"
										rows="6"
										auto-grow
										v-model="note.text"
									/>
									<AdsButton class="mx-2" @click="handleProgressNoteCancel(i)" :secondary="true">
										Cancel
									</AdsButton>
								<AdsButton
									class="mx-2"
									:disabled="!goalIsValid"
									@click="submitProgressNote(i)">Done
								</AdsButton>
							</div>
							<div v-else>
								<p class="mb-2">{{ formatDate(note.date) }}</p>
								<p class="mb-2">{{ note.text }}</p>
								<p class="secondary-text">
									Added by {{ note.addedby }} at
									{{ getTimestampOfDate(note.date) }}
								</p>
								<div class="edit-icon-wrapper">
										<v-icon :outlined="true" @click="handleEditProgressNoteClick(i)"
											>mode_edit_outline</v-icon
										>
									</div>
							</div>
							</div>
						</div>
					</div>

					<v-row class="bottom-row mt-5">
						<v-col cols="12" class="justify-space-between">
							<SaveStatus :status="status" />
							<div>
								<AdsButton
									tertiary
									class="mx-2 red-btn"
									@click="handleDelete"
									v-if="mode === 'edit'"
								>
									<v-icon class="mr-2">mdi-delete-outline</v-icon>
									<span class="mt-1"> Delete goal </span>
								</AdsButton>
								<AdsButton class="mx-2" @click="handleBack" :secondary="true"
									>Cancel</AdsButton
								>
								<AdsButton
									class="mx-2"
									:disabled="!goalIsValid"
									@click="submitGoal()">Done
								</AdsButton>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</template>
		</TabNavigation>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex"
import api from "../api"
import {
	AdsButton,
	TabNavigation,
	FeatureIcon,
	Alert,
	AdsTextField,
} from "@nswdoe/doe-ui-core"
import { debounce } from "../utils/debounce"
import DatePicker from "./DatePicker.vue"
import SaveStatus from "./SaveStatus.vue"

export default {
	components: {
		AdsButton,
		TabNavigation,
		FeatureIcon,
		Alert,
		AdsTextField,
		DatePicker,
		SaveStatus,
	},
	name: "GoalDisplay",
	props: {
		selectedIssues: {
			type: Array,
			default: () => [],
		},
		goalId: {
			type: String,
			default: "",
		},
		mode: {
			type: String,
			default: "add", // can also be "edit"
		},
	},
	watch: {
		goalId: {
			immediate: true,
			handler() {
				console.log('watch for goalId');
				if (this.mode == "edit" && !this.goalsAreLoading) {
					this.onGoalDataLoaded()
				}
			},
		},
		goalsAreLoading: {
			immediate: true,
			handler(newVal) {
				console.log('watch for goalsAreLoading');
				if (newVal == false && this.mode == "edit") {
					this.onGoalDataLoaded()
				}
			},
		},
		goalStatus: {
			handler() {
				console.log('watch for goalStatus');
				this.queueAutoSave()
			},
		},
	},
	created() {
		if (this.mode == "add") {
			this.newGoal = {
				due: new Date().toLocaleDateString("en-au"),
				summary: "",
				purpose: "",
				strategies: "",
				measures: "",
				who: "",
				progressNotes: [],
				issues: this.selectedIssues.map((issue) => issue.id),
			}
		}
		this.newProgressNote.addedby = this.username

		if (this.mode == "edit") {
			if (this.goalsAreLoading == false && this.goals.length > 0) {
				this.onGoalDataLoaded()
			} else {
				this.$store.dispatch("fetchGoals", this.selectedQualityArea.short)
			}
			this.debouncedSave = debounce(this.trySave, 2000)
		}
	},
	data() {
		return {
			tabItems: [
				{ id: 1, tab: "GOAL" },
				{ id: 2, tab: "PROGRESS NOTES" },
			],
			showIssues: false,
			rules: {
				required: (value) => !!value || "Please fill out this field.",
				under500: (v) =>
					(typeof v === "string" &&
						(this.mode == "edit" || v.length > 0) &&
						v.length <= 500) ||
					"Must be less than 500 characters.",
				under50: (v) =>
					(typeof v === "string" &&
						(this.mode == "edit" || v.length > 0) &&
						v.length <= 50) ||
					"Must be less than 50 characters.",
				under200: (v) =>
					(typeof v === "string" &&
						(this.mode == "edit" || v.length > 0) &&
						v.length <= 200) ||
					"Must be less than 200 characters.",
			},
			newGoal: {},
			goalIsValid: false,
			goalIsSaving: false,
			newProgressNote: {
				text: "",
				addedby: "",
				date: new Date(),
			},
			goalData: {},
			status: 0,
			hoveredNoteIndex: null,
			editMode: null,
			originalProgressNote: ""
		}
	},
	computed: {
		...mapGetters({
			issues: "issues",
			goals: "goals",
			goalsAreLoading: "goalsAreLoading",
			structure: "selectedQualityAreaStructure",
			assessment: "selectedAssessment",
			issuesAreLoading: "issuesAreLoading",
			assessmentIsLoading: "assessmentIsLoading",
			structureIsLoading: "qualityAreaStructureIsLoading",
			hasSelectedQualityArea: "hasSelectedQualityArea",
			selectedQualityArea: "selectedQualityArea",
			userProfile: "profile",
			username: "username",
		}),
		goalStatus: {
			get() {
				return this.goal.status ? true : false
			},
			set(v) {
				this.goal.status = v ? 1 : 0
			},
		},
		newProgressNoteText: {
			get() {
				return this.newProgressNote.text
			},
			set(v) {
				this.newProgressNote.text = v
			},
		},
		goal() {
			if (this.mode == "add" && this.newGoal) return this.newGoal
			else if (this.mode == "edit" && this.goalData) return this.goalData
			else return { progressNotes: [] }
		},
	},
	methods: {
		queueAutoSave() {
			if (this.mode === "add") return

			this.dirty()
			this.debouncedSave()
			console.log("debounce save")
		},
		dirty() {
			this.status = 1
			if (this.clearStatusTimeout != undefined) {
				clearTimeout(this.clearStatusTimeout)
			}
		},
		trySave() {
			if (this.checkValid(false)) {
				this.submitGoal(false)
			}
		},
		tryParseDate(date) {
			if (!date) return ""
			if (typeof date == "string" && new Date(date) != "Invalid Date")
				date = new Date(date)
			else if (typeof date == "string") return ""
			return date
		},
		getTimestampOfDate(date) {
			date = this.tryParseDate(date)
			if (date) {
				return (
					(date.getHours() + "").padStart(2, 0) +
					":" +
					(date.getMinutes() + "").padStart(2, 0)
				)
			}
			return ""
		},
		onGoalDataLoaded() {
			this.goalData = JSON.parse(
				JSON.stringify(this.goals.find((x) => x.id == this.goalId)),
			)
			if (!this.goalData?.id) {
				this.$store.dispatch("quickWarning", "Couldn't find goal!")
				this.goalData = {}
				return this.handleBack()
			}
			if (this.goalData.due && typeof this.goalData.due === "object") {
				this.goalData.due = this.goalData.due
					.toLocaleDateString("en-au")
					.slice(0, 10)
			} else if (
				this.goalData.due === null ||
				this.goalData.due === undefined
			) {
				this.goalData.due = new Date().toLocaleDateString("en-au").slice(0, 10)
			} else if (this.goalData.due.length > 10) {
				this.goalData.due = new Date(this.goalData.due)
					.toLocaleDateString("en-au")
					.slice(0, 10)
			}

			this.goalIsSaving = false
		},
		handleBack() {
			// Empty progress notes
			this.newProgressNote.text = ""
			
			this.$emit("handleBack")
		},
		handleDelete() {
			this.$emit("handleDelete", this.goalId)
			this.deleteGoal()
		},
		async deleteGoal() {
			this.goalIsSaving = true

			await api.issues
				.deleteGoal(
					this.assessment.assessment_id,
					this.selectedQualityArea.short,
					this.goal.id,
				)
				.then((data) => {
					if (data) {
						this.$store.dispatch(
							"quickConfirmation",
							"Successfully deleted your goal",
						)
						if (data) {
							console.log(this.goals.filter((v) => v.id !== this.goalId))
							this.$store.commit("setGoals", {
								goals: this.goals.filter((v) => v.id !== this.goalId),
							})
						}
					}
				})
				.catch((err) => {
					this.$store.dispatch("displayApiError", [
						"your goal",
						"delete",
						err?.reason || err?.data?.reason || err?.status,
					])
				})

			this.goalIsSaving = false
			this.handleBack()
		},
		async submitGoal(shouldCloseAfter = true) {
			console.log('in the submitgoal method');
			this.goalIsSaving = true

			let tGoal = JSON.parse(JSON.stringify(this.goal))

			if (this.newProgressNote.text)
				tGoal.progressNotes.push(
					JSON.parse(JSON.stringify(this.newProgressNote)),
				)
			tGoal.due = new Date(
				tGoal.due.slice(3, 5) +
					"/" +
					tGoal.due.slice(0, 2) +
					"/" +
					tGoal.due.slice(6),
			) // dear americans, you know you have the power to stop using the wrong date format, right?
			this.status = 2

			if (this.mode == "edit") {
				await api.issues
					.editGoal(
						this.assessment.assessment_id,
						this.selectedQualityArea.short,
						this.goal.id,
						tGoal,
					)
					.then((data) => {
						if (data) {
							this.$store.dispatch(
								"quickConfirmation",
								"Successfully updated your goal",
							)
							if (data && data.data && data.data.goals) {
								this.$store.commit("setGoals", data.data)
							}
						}
						this.status = 3
						this.clearStatusTimeout = setTimeout(() => {
							this.status = 0
						}, 1000)
					})
					.catch((err) => {
						console.error(err)
						this.$store.dispatch("displayApiError", [
							"your goal",
							"save",
							err?.reason || err?.data?.reason || err?.status,
						])
						this.status = 1
					})
					.finally(() => {
						this.goalIsSaving = false
						if (shouldCloseAfter) this.handleBack()
					})
			} else {
				await api.issues
					.addNewGoal(
						this.assessment.assessment_id,
						this.selectedQualityArea.short,
						tGoal,
					)
					.then((data) => {
						if (data) {
							this.$store.dispatch(
								"quickConfirmation",
								"Successfully added your goal",
							)
							console.log(data.data)
							if (data && data.data && data.data.goals) {
								this.$store.commit("setGoals", data.data)
							}
							this.$store.commit("updateIssueStatus", tGoal.issues)
						}
					})
					.catch((err) => {
						console.error(err)

						this.$store.dispatch("displayApiError", [
							"your goal",
							"add",
							err?.reason || err?.data?.reason || err?.status,
						])
					})
					.finally(() => {
						this.goalIsSaving = false
						if (shouldCloseAfter) this.handleBack()
					})
			}
		},
		toggleShowIssues() {
			this.showIssues = !this.showIssues
		},
		getStandard(short) {
			return this.structure.children.standards.find((x) => x.short == short)
		},
		formatDate(date) {
			date = this.tryParseDate(date)
			if (date) return date.toUTCString().slice(5, 16)
			return date
		},
		checkValid(shouldQueueSave = true) {
			this.goalIsValid =
				this.goal &&
				typeof this.goal.summary === "string" &&
				(this.mode == "edit" || this.goal.summary.length > 0) &&
				this.goal.summary.length < 500 &&
				typeof this.goal.purpose === "string" &&
				(this.mode == "edit" || this.goal.purpose.length > 0) &&
				this.goal.purpose.length < 500 &&
				typeof this.goal.strategies === "string" &&
				(this.mode == "edit" || this.goal.strategies.length > 0) &&
				this.goal.strategies.length < 500 &&
				typeof this.goal.measures === "string" &&
				(this.mode == "edit" || this.goal.measures.length > 0) &&
				this.goal.measures.length < 500 &&
				(this.mode == "edit" || this.goal.who.length > 0) &&
				this.goal.who.length < 200 &&
				this.goal.due &&
				/^\d{2}\/\d{2}\/\d{4}$/.test(this.goal.due) &&
				this.selectedIssues.length >= 1

			if (shouldQueueSave) this.queueAutoSave()
			return this.goalIsValid
		},
		handleEditProgressNoteClick(index){
			this.editMode = index;
			this.originalProgressNote = this.goal.progressNotes[index].text;
		},
		handleProgressNoteCancel(index) {
			this.editMode = null;
			this.goal.progressNotes[index].text = this.originalProgressNote;
		},
		submitProgressNote(index){
			// eslint-disable-next-line no-console
			console.log(`the following index was saved ${index}. The value is: ${this.goal.progressNotes[index].text}`);

			this.editMode = null;
		}
	},
}
</script>

<style lang="scss" scoped>
@import "../scss/ads.scss";
@import "../scss/custom-tab.scss";

.theme--light .custom-tab {
	::v-deep {
		.v-window,
		.v-window-item .container {
			background-color: $ads-white;
			min-height: unset;
			.v-card.v-sheet {
				box-shadow: unset;
			}
		}
	}
}
.theme--light {
	.normal-text ::v-deep.v-label,
	.normal-text {
		color: $ads-dark;
		font-size: 16px !important;
		font-weight: 500 !important;
	}
	b.subtitle-1 {
		color: $ads-navy;
	}
	.blue-text {
		color: $ads-blue-1;
	}
}
.bottom-row {
	justify-content: right;
	.col {
		display: flex;
		justify-content: right;
	}
}
.secondary-text {
	color: $ads-dark-60;
}
::v-deep .v-input__control .v-input__slot .v-text-field__slot label.v-label {
	min-height: 1.1em;
}
.goal-display ::v-deep {
	.v-input__control .v-input__slot legend {
		width: 4.3em !important;
	}
	/* replicate .v-label--active permanently */
	.v-input__control .v-input__slot .v-text-field__slot label.v-label {
		@extend .v-label--active;
		transform: translateY(-24px) scale(0.75);
		font-size: 1.4rem !important;
		padding-top: 5px;
	}
}
.goal-display .small-label ::v-deep.v-input__control .v-input__slot legend {
	width: 2.6em !important;
}
.goal-display .regular-label ::v-deep.v-input__control .v-input__slot legend {
	width: 7.4em !important;
}
.goal-display .medium-label ::v-deep.v-input__control .v-input__slot legend {
	width: 9.4em !important;
}
.goal-display .big-label ::v-deep.v-input__control .v-input__slot legend {
	width: 15.7em !important;
}
::v-deep .embeddedalert .v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
::v-deep .v-counter {
	padding-top: 2px;
}
::v-deep button.red-btn.v-btn.theme--light {
	.v-btn__content {
		color: $ads-red;
	}
}
</style>
