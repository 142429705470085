<template>
	<v-card tile>
		<!--v-toolbar
			style="position: fixed"
			v-if="$vuetify.breakpoint.xsOnly"
			dark
			class="mb-0"
		>
			<v-toolbar-title
				style="width: 100%"
				class="d-flex flex-grow justify-space-between"
			>
				<div class="d-flex align-center">
					<div class="title" style="color: #fff">
						<v-icon>mdi-help-circle-outline</v-icon> Change Password
					</div>
				</div>
				<div class="ml-3">
					<v-btn color="#fff" icon aria-label="close" @click="handleBack">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-toolbar-title>
		</v-toolbar-->
		<v-card-title
			class="d-flex align-center justify-space-between flex-nowrap pa-6"
			:class="{ 'pl-3': $vuetify.breakpoint.xsOnly }"
		>
			<div class="d-flex align-center grey-01--text">
				<h3 style="display: flex; align-items: center; word-break: break-word">
					<FeatureIcon
						class="mr-3 flex-shrink-0"
						size="42"
						color="primary-teal"
						background-color="light blue"
						icon="mdi-help-circle-outline"
					/>
					<span v-if="hasSelectedGuide" class="pt-1">
						{{ selectedGuide.title }}
					</span>
					<span v-else-if="selectedGuideIsLoading" class="pt-1">
						<v-skeleton-loader type="heading" />
					</span>
					<span v-else class="pt-1"> No guide found :( </span>
				</h3>
			</div>
			<div class="align-self-start ml-3">
				<v-btn icon aria-label="close" @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<GuideMarkdownRenderer
			:loading="selectedGuideIsLoading"
			:markdown="markdown"
		/>
	</v-card>
</template>

<script>
import { FeatureIcon, AdsButton } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"
import GuideMarkdownRenderer from "./GuideMarkdownRenderer.vue"

const StandardGuideDisplay = {
	name: "StandardGuideDisplay",
	components: { FeatureIcon, GuideMarkdownRenderer, AdsButton },
	created() {
		if (this.hasSelectedStandard) {
			this.$store.commit("selectGuide", [
				"standard",
				this.selectedStandard.number,
			])
			this.$store.dispatch("getSelectedGuide")
		}
	},
	watch: {
		hasSelectedStandard: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					this.$store.commit("selectGuide", [
						"standard",
						this.selectedStandard.number,
					])
					this.$store.dispatch("getSelectedGuide")
				}
			},
		},
	},
	props: {},
	data() {
		return {}
	},
	methods: {
		closeDialog() {
			this.$emit("closeDialog")
		},
	},
	computed: {
		...mapGetters([
			"selectedGuide",
			"hasSelectedGuide",
			"selectedGuideIsLoading",
			"selectedStandard",
			"hasSelectedStandard",
		]),
		markdown() {
			return this.selectedGuide ? this.selectedGuide.markdown : ""
		},
	},
}
export default StandardGuideDisplay
</script>

<style scoped lang="scss">
</style>
