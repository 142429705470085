<template>
	<div>
		<Banner
			heading="Help and support"
			subtitle="Answers to some of the more commonly identified questions"
			icon="help_outline"
			content-class="pl-10"
			default-background1
		/>
		<v-container
			:class="$vuetify.breakpoint.xsOnly ? 'px-0 mt-5 mobile' : 'px-10 mt-8'"
		>
			<AdsExpansionPanel :items="items" class="mb-8">
				<template v-for="(item, i) of items" :slot="'content' + (i + 1)">
					<div class="pa-5" :key="i">
						<template v-if="item.html">
							<div :v-html="item.html"></div>
						</template>
						<template v-if="item.markdown">
							<vue-simple-markdown
								:source="item.markdown"
							></vue-simple-markdown>
						</template>
					</div>
				</template>
			</AdsExpansionPanel>
		</v-container>
	</div>
</template>

<script>
import { Banner, AdsExpansionPanel } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"

const HelpAndSupport = {
	name: "HelpAndSupport",
	created() {
		this.$store.commit("selectGuide", ["help", "index"])
		this.$store.dispatch("getSelectedGuide")
	},
	computed: {
		...mapGetters(["selectedGuide"]),
		userProfile() {
			return this.$store.getters.profile
		},
		items() {
			return Object.values(this.selectedGuide).map((a, i) => {
				return {
					markdown: a.markdown
						.split("%PUBLICPATH%/img/")
						.join(this.publicPath + "hns/"),
					content: "",
					icon: false,
					id: i + 1 + "",
					title: a.title,
				}
			})
		},
	},
	data() {
		return {
			myImage: localStorage.getItem("image"),
			publicPath: process.env.BASE_URL,
		}
	},
	components: {
		Banner,
		AdsExpansionPanel,
	},
	methods: {},
}

export default HelpAndSupport
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";
@import "../scss/invert-banner.scss";

.custom-tab {
	::v-deep {
		.v-window,
		.v-window-item .container {
			background-color: $ads-light-10;
			box-shadow: none;
			border-radius: 0;
			min-height: 40vh;
		}
		.v-tab.v-tab-navigation.theme--light .v-item-group.v-slide-group {
			background-color: $ads-white;
		}
		.v-tabs-slider-wrapper {
			height: 4px !important;
			.v-tabs-slider {
				background-color: $ads-red;
			}
		}
	}
}
</style>
