import qs from "qs"
import API from "../apiHelper"
import { APIRESOURCE } from "../../utils/constants"
import createAuthHeaders from "../helpers"

const fetchIdentifiedIssues = (assessmentId, qualityArea) => {
	//console.log(`Fetch Identified Issues Api ${assessmentId}, ${qualityArea}`)
	return API.get({
		endpoint:
			APIRESOURCE.ISSUES.FETCHISSUES +
			"?" +
			qs.stringify({ assessmentId, qualityArea }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchIdentifiedIssues",
		spinner: false,
	})
}

const addNewIssue = (assessmentId, qualityArea, issue) => {
	//console.log(`Add New Issue Api ${assessmentId}, ${qualityArea}, ${issue}`)
	return API.post({
		endpoint:
			APIRESOURCE.ISSUES.ADDISSUE +
			"?" +
			qs.stringify({ assessmentId, qualityArea }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		payload: { issue: issue },
		// mock:  "addNewIssue",
		spinner: false,
	})
}

const fetchGoals = (assessmentId, qualityArea) => {
	//console.log(`Fetch goals Api ${assessmentId}, ${qualityArea}`)
	return API.get({
		endpoint:
			APIRESOURCE.ISSUES.FETCHGOALS +
			"?" +
			qs.stringify({ assessmentId, qualityArea }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchGoals",
		spinner: false,
	})
}

const addNewGoal = (assessmentId, qualityArea, goal) => {
	//console.log(`Add new goals Api ${assessmentId}, ${qualityArea}, ${goal}`)
	return API.post({
		endpoint:
			APIRESOURCE.ISSUES.ADDGOAL +
			"?" +
			qs.stringify({ assessmentId, qualityArea }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		payload: { goal: goal },
		// mock:  "addNewGoal",
		spinner: false,
	})
}

const editGoal = (assessmentId, qualityArea, goalId, goalData) => {
	console.log(
		`Edit goals Api ${assessmentId}, ${qualityArea}, ${goalId}, ${JSON.stringify(
			goalData,
		)}`,
	)

	return API.put({
		endpoint:
			APIRESOURCE.ISSUES.EDITGOAL +
			"?" +
			qs.stringify({ assessmentId, qualityArea, goalId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		payload: { goal: goalData },
		// mock:  "addNewGoal",
		spinner: false,
	})
}

const deleteGoal = (assessmentId, qualityArea, goalId) => {
	return API.delete({
		endpoint:
			APIRESOURCE.ISSUES.DELETEGOAL +
			"?" +
			qs.stringify({ assessmentId, qualityArea, goalId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "deleteGoal",
		spinner: false,
	})
}

const deleteIssue = (assessmentId, qualityArea, issueId) => {
	return API.delete({
		endpoint:
			APIRESOURCE.ISSUES.DELETEISSUE +
			"?" +
			qs.stringify({ assessmentId, qualityArea, issueId }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "deleteGoal",
		spinner: false,
	})
}

export default {
	fetchIdentifiedIssues,
	addNewIssue,
	fetchGoals,
	addNewGoal,
	editGoal,
	deleteGoal,
	deleteIssue
}
