<template>
	<div>
		<AssessmentBanner
			:class="{'banner--no-margins': showGuidance && $vuetify.breakpoint.mdAndUp}"
			:header="
				selectedStandard.name
					? selectedStandard.name.split(selectedStandard.number + ' - ')[1]
					: ''
			"
			:subheader="`Standard ${
				selectedStandard.number ? selectedStandard.number : '?'
			}`"
			:loading="loading"
			:progress="progress"
		/>
		<div :class="{'grid-container': showGuidance && $vuetify.breakpoint.mdAndUp}">
			<v-container :class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'">
				<v-row class="my-6 spacebetween" :no-gutters="true">
					<div style="display: flex; align-items: center" @click="goBack()">
						<v-icon class="mr-2">arrow_back</v-icon>
						<a :align="'center'">Back</a>
					</div>
					<div
						v-if="!showGuidance"
						style="display: flex; align-items: center"
						@click.stop="toggleGuidance()"
					>
						<v-icon class="mr-2">help_outline</v-icon>
						<a :align="'center'">Guidance</a>
					</div>
				</v-row>
				<ChildSelection
					:quality-area-short="$route.params.qualityArea"
					:standard-short="$route.params.standard"
				/>
			</v-container>
			<v-navigation-drawer v-if="showGuidance && $vuetify.breakpoint.mdAndUp" width="100%" right permanent>
				<StandardGuideDisplay @closeDialog="toggleGuidance()" />
			</v-navigation-drawer>
		</div>
		<v-dialog
			v-if="$vuetify.breakpoint.smAndDown"
			v-model="showGuidance"
			max-width="800px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<StandardGuideDisplay @closeDialog="toggleGuidance()" />
		</v-dialog>
	</div>
</template>

<script>
import { AdsButton, AdsDataTable, Alert } from "@nswdoe/doe-ui-core"
import {
	AssessmentBanner,
	ChildSelection,
	StandardGuideDisplay,
} from "../components"
const standard = {
	title: "Standard",
	name: "standard",
	async created() {
		this.$store.commit("selectGuide", ["standard", this.$route.params.standard])
	},
	computed: {
		selectedQualityArea() {
			return this.$store.getters.selectedQualityArea
		},
		selectedStandard() {
			return this.$store.getters.selectedStandard
		},
		selectedAssessment() {
			return this.$store.getters.selectedAssessment
		},
		loading() {
			return (
				this.$store.getters.qualityAreasAreLoading ||
				this.$store.getters.assessmentIsLoading
			)
		},
		progress() {
			if (this.loading) return 0

			let els = this.selectedAssessment.qualityAreas
				.find((v) => v.name == this.selectedQualityArea.short)
				?.standards.find((v) => v.name == this.selectedStandard.short)?.elements
			if (!els) return -1

			console.log(JSON.stringify(els));
			let total = 0
			for (let standard of els) {
				if (standard.progress > -1) 
					total += standard.progress
			}



			return (total / (els.length * 2)) * 100
		},
	},
	data() {
		return {
			showGuidance: false,
		}
	},
	methods: {
		goBack() {
			this.$router.push({
				name: "Quality Area",
				params: {
					qualityArea: this.$route.params.qualityArea,
				},
			})
		},
		toggleGuidance() {
			this.showGuidance = !this.showGuidance
		},
	},
	components: {
		AdsButton,
		AdsDataTable,
		AssessmentBanner,
		Alert,
		ChildSelection,
		StandardGuideDisplay,
	},
}

export default standard
</script>

<style lang="scss" scoped>
@import "../scss/invert-banner.scss";

.banner--no-margins {
	:deep(.container) {
		margin-left: 0;
		margin-right: 0;
	}
}
#main-content {
	padding-bottom: 0px !important;
	:deep(div.pl-0.col) {
		padding-top: 0px !important;
	}
}
.spacebetween {
	justify-content: space-between;
}
:deep(.embeddedalert .v-alert.v-sheet) {
	background: #e0e9ff;
	box-shadow: none !important;
}
.grid-container {
	display: grid;
	grid-template-columns: 2fr 1fr;
}
:deep .v-navigation-drawer {
	height: fit-content !important;

	&__content {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}
</style>
