import qs from "qs"
import API from "../apiHelper"
import { APIRESOURCE } from "../../utils/constants"
import createAuthHeaders from "../helpers"

const fetchGuide = (guideType, guide) => {
	//console.log(`Fetch standard guide api { ${guideType}, ${guide} }`)
	return API.get({
		endpoint: APIRESOURCE.GUIDES.GET + "?" + qs.stringify({ guideType, guide }),
		headers: createAuthHeaders({
			"Content-Type": "application/json",
			"x-correlation-id": "MarkTest-34545-23234-345345",
		}),
		// mock:  "fetchStandardGuide",
		spinner: false,
	})
}

export default { fetchGuide }
