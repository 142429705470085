import mockLocation from "../data/schoolNames"
import { APIRESOURCE } from "../../utils/constants"
import axios from "axios"

const fetchMatchLocations = () => {
	return new Promise(function (resolve) {
		setTimeout(function () {
			resolve(mockLocation)
		}, 100)
	})
}
const getLocation = (locations) => {
	return fetchMatchLocations(locations)
}
const getName = (locations, apiToken) => {
	return axios(APIRESOURCE.LOCATION.LOCATION, {
		method: "POST",
		headers: {
			Authorization: "Bearer " + apiToken,
			"Content-Type": "application/json",
			"x-correlation-id": "12345678",
		},
		data: JSON.stringify({
			query: {
				schoolCodes: locations.split(","),
				responseProperties: ["schoolCode", "schoolFullName"],
			},
		}),
	}).catch(() => {
		return fetchMatchLocations()
	})
}
export default { getName, getLocation, fetchMatchLocations }
