import Vue from "vue"
import Vuetify from "vuetify/lib"

import doeColors from "../settings/doe-colors"
import { ADS_Colors } from '@nswdoe/doe-ui-core'; // use pre-defined ADS colors constants

Vue.use(Vuetify)

export default new Vuetify({
	icons: {
		iconfont: "mdi",
	},
	theme: {
		themes: {
			light: {
				primary: doeColors.primary.navy,
				secondary: doeColors.primary.lightblue,
				accent: doeColors.secondary.blue1,
				success: doeColors.secondary.blue2,
				'primary-teal': ADS_Colors.PRIMARY_TEAL,
				'secondary-blue': ADS_Colors.SECONDARY_BLUE,
				'grey-01': ADS_Colors.GREY_01,
			},
		},
	},
})
