<template>
	<div>
		<v-tabs
			:value="value"
			@input="$emit('input')"
			v-bind="$attrs"
			v-on="$listeners"
			:icons-and-text="iconsAndText"
			:next-icon="nextIcon"
			:prev-icon="prevIcon"
			show-arrows
			class="v-tab-navigation"
		>
			<v-tab v-for="item in items" :key="item.tab">
				<span v-if="!iconsOnly">{{ item.tab }}</span>
				<v-icon v-if="iconsAndText || iconsOnly">{{ item.icon }}</v-icon>
			</v-tab>
		</v-tabs>

		<v-tabs-items
			:value="value"
			@input="$emit('input')"
			class="v-tab-navigation"
		>
			<v-tab-item v-for="(item, i) in items" :key="i">
				<slot :name="'content' + (item.id ? item.id : i + 1)">
					<v-card>
						<v-card-text v-html="item.content"></v-card-text>
					</v-card>
				</slot>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
export default {
	name: "TabNavigation",
	data() {
		return { tab: 0 }
	},
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		iconsOnly: {
			type: Boolean,
			default: false,
		},
		iconsAndText: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default: () => [],
		},
		nextIcon: {
			type: String,
			default: "keyboard_arrow_right",
		},
		prevIcon: {
			type: String,
			default: "keyboard_arrow_left",
		},
		showArrows: {
			type: Boolean,
			default: true,
		},
	},
}
</script>

<style>
.v-tab-navigation .v-slide-group__wrapper,
.v-tab-navigation .v-window__container {
	padding-left: 0 !important;
}

.theme--light.v-tabs .v-tab:focus::before {
	opacity: inherit;
	border: 4px solid #ce0037 !important;
}

.v-tab:before {
	background-color: inherit !important;
}
</style>
