<template>
	<ErrorPage
		:title="title"
		:error-code="ERRORCODE"
		:subtitle="subtitle"
		:is404page="false"
		:actions="actions"
	/>
</template>

<script>
import { ErrorPage } from "@nswdoe/doe-ui-core"

const ErrorTimedOut = {
	title: "Timed Out",
	name: "TimedOut",
	created() {
		// this.$store.commit("isLoading", false)
	},
	data() {
		return {}
	},
	computed: {
		ERRORCODE() {
			return this.$route.params.ERRORCODE || "USER-SESSION-TIMED-OUT"
		},
		title() {
			return "Sorry, your session was timed out"
		},
		subtitle() {
			return "Your session timed out due to inactivity. Your work has been auto-saved. To continue working, please login again."
		},
		actions() {
			return [
				{
					text: "Login",
					attrs: {
						href: "/",
					},
				},
			]
		},
	},
	components: { ErrorPage },
}

export default ErrorTimedOut
</script>
