/* eslint-disable prettier/prettier */
import Vue from "vue";
import Vuex from "vuex";

import locationsModule from "./modules/locations";
import centreModule from "./modules/centre";
import assessmentsModule from "./modules/assessments";
import guidesModule from "./modules/guides";

import router from "../router/"; // Import the router

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    locationsModule,
    centreModule,
    assessmentsModule,
    guidesModule,
  },
  state: {
    isLoading: false,
    loaderCount: 0,
    profile: {},
    groupInfo: {},
    snackbar: {
      show: false,
    },
    appLevelError: {
      errorStatus: false,
      errorMessage: "",
    },
    knownErrors: {
      "error:unauthorized": (callee, method) =>
        `You are not authorized to ${method} ${callee}. You may have been logged out, try logging back in.`,
      "error:unknown": (callee, method) =>
        `There was an unknown error when trying to ${method} ${callee}. Please try again later.`,
      "error:invalidcall": (callee, method) =>
        `There was an error in the ${method} request for ${callee}.`,
      "error:notfound": (callee) => `We couldn't find the ${callee}.`,
      "error:badgateway": (callee, method) =>
        `There was an internet issue while trying to ${method} ${callee}.`,
      "error:invalidperms": (callee, method) =>
        `You don't have the right permissions to ${method} ${callee}. You can only view this data, not edit it.`,
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    profile: (state) => state.profile,
    username: (state) => state.profile.username,
    groupInfo: (state) => state.groupInfo,
    viewOnly: (state) => (state.groupInfo.canWrite ? false : true),
  },
  mutations: {
    SET(state, [key, value]) {
      state[key] = value;
    },
    setLoading(state, [key, value]) {
      if (key !== "isLoading") {
        state.loadingFor[key] = value == "true" ? true : false;
      } else {
        state.isLoading = value == "true" ? true : false;
      }
    },
    setAppError(state, errorObj) {
      state.appLevelError = errorObj;
    },
    SET_IS_LOADING(state, isVisible) {
      if (isVisible) {
        state.loaderCount = state.loaderCount + 1;
        state.isLoading = state.loaderCount > 0;
        state["spinnerMessage"] = "Loading...";
      } else {
        state.loaderCount = state.loaderCount - 1;
        state.isLoading = state.loaderCount > 0;
        state.loaderCount <= 0 ? (state["spinnerMessage"] = null) : null;
      }
    },
    showSpinner(state, message) {
      state.loaderCount = state.loaderCount + 1;
      state.isLoading = true;
      state["spinnerMessage"] = message || "Loading...";
    },
    hideSpinner(state) {
      state.loaderCount = state.loaderCount - 1;
      state.isLoading = false;
      state.loaderCount <= 0 ? (state["spinnerMessage"] = null) : null;
    },
  },
  actions: {
    displayApiError({ dispatch, state }, [callee, method, errorType]) {
      errorType = tryConvertStatusToError(errorType) + "";
      console.log("errorType:", errorType);
      let errorMessage = "";
      if (!errorType || errorType.slice(0, 6) !== "error:")
        errorType = "error:unknown";
      if (state.knownErrors[errorType]) {
        errorMessage = state.knownErrors[errorType](callee, method);
      } else {
        errorMessage = state.knownErrors["error:unknown"](callee, method);
      }
      return dispatch("quickWarning", errorMessage);
    },
    quickConfirmation({ dispatch }, messageOrParams) {
      // Shows confirmation message that shortly disappears.
      // messageOrParams - Either message text (string) or json params
      let { message, timeout } = messageOrParams;
      dispatch("snackbar", {
        message: message || messageOrParams,
        type: "success",
        timeout: timeout !== undefined ? timeout : 3000,
      });
    },

    quickWarning({ dispatch }, messageOrParams) {
      // Shows warning message that shortly disappears.
      // messageOrParams - Either message text (string) or json params
      let { message, timeout } = messageOrParams;
      dispatch("snackbar", {
        message: message || messageOrParams,
        type: "error",
        timeout: timeout !== undefined ? timeout : 4000,
      });
    },
    snackbar({ commit }, { message, timeout, type }) {
      // quickConfirmation and quickWarning both use this Snackbar
      commit("SET", ["snackbar", { show: false }]);
      // Timeout is necessary to clear any old snackbar before showing new one
      setTimeout(() => {
        commit("SET", [
          "snackbar",
          {
            text: message,
            type: type,
            show: true,
            timeout: timeout,
          },
        ]);
      }, 0);
    },
    setAppError({ commit }, messageInfo) {
      let errorObj = {
        errorStatus: true,
        errorMessage: messageInfo,
      };
      commit("setAppError", errorObj);
    },
    setProfile({ commit, dispatch }, profile) {
      if (!profile.username) {
        if (profile.email) profile.username = profile.email.split("@")[0];
        if (profile["det-username"]) profile.username = profile["det-username"];
      }
      if (!profile.roles) {
        if (profile["det-roles"]) profile.roles = profile["det-roles"];
      }
      if (!profile.groups) {
        console.error("Profile has no groups. Setting to empty array.");
        profile.groups = [];
      }
      console.log("SETTINGS PROFILE TO ", profile);
      let groupData = findAndConvertGroup(profile.groups);

      let defaultSchool = groupData[0];

      if (!defaultSchool) {

        commit("SET_IS_LOADING", false);

        // Redirect to the Error 401 page
        console.error("User has no default school. Routing to error page.");

        router.push({ name: 'Error 401', params: { ERRORCODE: 'CENTRE-401' } });
        return;
      }

      if (!defaultSchool?.canWrite) {
        dispatch(
          "quickConfirmation",
          "Please note: you have logged in with View Only access."
        );
      }
      commit("SET", ["groupInfo", defaultSchool]);
      commit("SET", ["schools", groupData]);
      commit("SET", ["profile", profile]);
    },
    beginFinalInactivityTimer({ commit }) {
      this._vm.$activityMonitor.deactivate();
      commit("set", ["showTimeoutBanner", true]);
    },
  },
});

function findAndConvertGroup(groups) {
  //   groups.push("~SCH9999SAAPFA");

  let saapGroups = [];

  groups.forEach((item) => {
    if (item.startsWith("~SCH") && item.includes("SAAP")) {
      const match = item.match(/~SCH(\d+)SAAP(FA|VO)/);

      if (match) {
        const location = parseInt(match[1], 10);
        const canRead = match[2] === "FA" ? true : true;
        const canWrite = match[2] === "VO" ? false : true;
        saapGroups.push({
          location: location,
          canRead: canRead,
          canWrite: canWrite,
        });
      }
    }
  });

  console.log(groups);
  console.log(saapGroups);

  if (!saapGroups || saapGroups.length === 0) {
    console.log("User does not belong to a group with access to SAAP!");
    return [];
  }

  return saapGroups;

  //   let access = {};
  //   let group = groups.find(
  //     (group) => group.indexOf("~SCH") != -1 && group.indexOf("SAAPFA") != -1
  //   );
  //   if (!group) {
  //     group = groups.find(
  //       (group) => group.indexOf("~SCH") != -1 && group.indexOf("SAAPVO") != -1
  //     );
  //   }
  //   if (!group) {
  //     console.log("User does not belong to a group with access to SAAP!");
  //     return false;
  //   }

  //   console.log(group);

  //   if (group.slice(-2) == "FA") {
  //     access = {
  //       canRead: true,
  //       canWrite: true,
  //       location: -1,
  //     };
  //   } else if (group.slice(-2) == "VO") {
  //     access = {
  //       canRead: true,
  //       canWrite: false,
  //       location: -1,
  //     };
  //   }
  //   access.location = Number.parseInt(group.slice(4, group.indexOf("SAAP")));
  //   return access;
}

function tryConvertStatusToError(status) {
  let conversionDict = {
    400: "error:invalidcall",
    401: "error:unauthorized",
    403: "error:unauthorized",
    404: "error:notfound",
    500: "error:unknown",
    502: "error:badgateway",
  };
  if (!status) return status;
  if ((status + "").length != 3) return status;
  if (conversionDict[status]) return conversionDict[status];
  return status;
}
