<template>
	<v-card style="min-width: 300px">
		<v-card-title>
			<InitialsIcon :given-name="givenName" :surname="surname" class="mr-3" />
			<span>{{ contactName }}</span>
		</v-card-title>
		<v-card-text style="margin-left: 52px">
			{{ contactRole }}
		</v-card-text>
	</v-card>
</template>

<script>
import { InitialsIcon } from "@nswdoe/doe-ui-core"

const ContactCard = {
	name: "ContactCard",
	components: {
		InitialsIcon,
	},
	props: {
		contactName: {
			type: String,
			default: "",
		},
		contactRole: {
			type: String,
			default: "",
		},
	},
	computed: {
		givenName: function () {
			return this.contactName.split(" ")[0]
		},
		surname: function () {
			return this.contactName.split(" ")[1]
		},
	},
}

export default ContactCard
</script>

<style scoped></style>
