export default {
	service: {
		name: "Little Einsteins",
		schoolmotto: "All our kids are little einsteins!",
		principal: "finhs.principal01",
		approvalNumber: "0411 222 333",
		contactName: "Medium Sized Einsteins",
		contactNumber: "0422 111 333",
		provider: "Johnny co",
		providerNumber: "0433 111 222",
		providerContact: "Johnathan 'MC' Mello",
		educators: "John, Johnnie, Johnny, Johnathan, Jon, The Jonster",
	},
	notifications: {},
}
