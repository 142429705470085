<template>
	<v-snackbar
		:value="value"
		:class="snackbarClass"
		:light="!$vuetify.theme.dark"
		bottom
		:timeout="timeout"
	>
		<slot name="content">
			<div class="d-flex align-center">
				<slot name="icon">
					<v-icon :color="typeProperties.color" size="35" class="mr-2">
						{{ sbIcon }}
					</v-icon>
				</slot>
				<slot name="text">
					<div v-if="htmlText" class="snackbar--text" v-html="htmlText" />
					<div v-if="!htmlText" class="snackbar--text">
						{{ text }}
					</div>
				</slot>
				<div>
					<v-btn v-if="allowDismiss" icon @click="dismiss">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</div>
		</slot>
	</v-snackbar>
</template>

<script>
const types = {
	success: {
		class: "snackbar--success",
		icon: "mdi-check-circle",
		color: "#00A908",
	},
	warning: {
		class: "snackbar--warning",
		icon: "mdi-alert",
		color: "#DC5800",
	},
	error: {
		class: "snackbar--error",
		icon: "mdi-alert-circle",
		color: "#B81237",
	},
	info: {
		class: "snackbar--info",
		icon: "mdi-information",
		color: "#6D7079",
	},
}

export default {
	props: {
		type: {
			type: String,
			default: "info",
		},
		icon: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			default: "Error: Unknown Error",
		},
		allowDismiss: {
			type: Boolean,
			default: true,
		},
		value: {
			type: Boolean,
		},
		timeout: {
			type: Number,
			default: -1,
		},
		htmlText: {
			type: String,
			default: "",
		},
	},
	computed: {
		typeProperties() {
			return types[this.type.toLowerCase()]
		},
		snackbarClass() {
			return "ml-2 mr-2 " + this.typeProperties.class
		},
		sbIcon() {
			return this.icon || this.typeProperties.icon
		},
	},
	methods: {
		dismiss: function () {
			this.$store.commit("SET", [
				"snackbar",
				{
					show: false,
					type: this.$store.state.snackbar.type,
				},
			])
		},
	},
	watch: {
		$route: function () {
			if (this.$store.state.snackbar.show) {
				this.dismiss()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
div.v-snack {
	width: auto;
	margin: auto;
	font-size: 1rem;
	::v-deep .v-snack__content {
		padding: 25px;
	}
	.snackbar--text {
		min-width: 14em;
	}
	.theme--light {
		.snackbar--text {
			color: #333333;
		}
		::v-deep .v-snack__wrapper {
			background-color: #ffffff;
		}
	}
	&.v-snack--bottom {
		left: 20px;
		bottom: 20px;
	}
}
</style>
