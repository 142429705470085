<template>
	<div
		:class="(inPage ? 'alert--element' : 'alert--snackbar') + ' embeddedalert'"
	>
		<v-snackbar
			v-if="!inPage"
			:light="this.$vuetify.theme.dark ? false : true"
			v-bind="$attrs"
			v-model="displayAlert"
			:class="snackbarClass"
			:bottom="positionElement.bottom"
			:left="positionElement.left"
			:top="positionElement.top"
			:right="positionElement.right"
			:timeout="sbTimeout"
			:id="generatedId"
			multi-line
		>
			<slot name="content">
				<div class="d-flex align-start">
					<slot name="icon">
						<v-icon
							:color="
								this.$vuetify.theme.dark
									? typeProperties.darkColor
									: typeProperties.color
							"
							size="27"
							class="mr-4"
						>
							{{ sbIcon }}
						</v-icon>
					</slot>
					<slot name="text">
						<div class="align-start flex-column">
							<div v-if="htmlText" class="alert--text" v-html="htmlText" />
							<div v-if="!htmlText" class="alert--text">
								{{ text }}
							</div>
							<slot name="optional">
								<div class="pt-1 alert--textsimple" v-if="subtext">
									{{ subtext }}
								</div>
								<div
									v-if="actionText"
									class="pt-3 alert--text"
									:style="{
										color: this.$vuetify.theme.dark
											? typeProperties.darkColor
											: typeProperties.color,
									}"
								>
									<span
										@click="actionHandler ? actionHandler() : noop()"
										:class="{
											'alert--clickable-action': actionHandler != null,
										}"
										>{{ actionText }}
									</span>
								</div>
							</slot>
						</div>
					</slot>
					<div>
						<v-btn
							v-if="allowDismiss"
							class="alert--text"
							text
							icon
							@click="dismiss"
						>
							<v-icon
								small
								:color="
									this.$vuetify.theme.dark
										? typeProperties.darkColor
										: typeProperties.color
								"
							>
								cancel
							</v-icon>
						</v-btn>
					</div>
				</div>
			</slot>
		</v-snackbar>
		<v-alert
			v-if="inPage"
			:type="type"
			:color="
				this.$vuetify.theme.dark
					? typeProperties.darkColor
					: typeProperties.color
			"
			:class="alertClass"
			:id="generatedId"
			:border="border"
			v-bind="$attrs"
			:icon="sbIcon"
			:elevation="elevation"
			colored-border
		>
			<slot name="content">
				<div class="d-flex align-center">
					<slot name="text">
						<div class="align-start flex-column">
							<div
								v-if="htmlText"
								class="snackbar--text font-weight-bold"
								v-html="htmlText"
							/>
							<div v-if="markdownText" class="mt-n3 ml-n9">
								<ShowMore
									v-if="$vuetify.breakpoint.xsAndDown"
									:text="markdownText"
									:max-chars="100"
								/>
								<div v-else><vue-simple-markdown :source="markdownText" /></div>
							</div>
							<div
								v-if="!htmlText && !markdownText"
								class="snackbar--text font-weight-bold"
							>
								{{ text }}
							</div>
							<slot name="optional">
								<div class="pt-1 alert--textsimple" v-if="subtext">
									{{ subtext }}
								</div>
								<div
									v-if="actionText"
									class="pt-3 alert--text font-weight-bold"
									:style="{
										color: this.$vuetify.theme.dark
											? typeProperties.darkColor
											: typeProperties.color,
									}"
								>
									<span
										@click="actionHandler ? actionHandler() : noop()"
										:class="{
											'alert--clickable-action': actionHandler != null,
										}"
										>{{ actionText }}</span
									>
								</div>
							</slot>
						</div>
					</slot>
					<v-spacer />
					<slot name="close">
						<div v-if="allowDismiss" class="pl-5">
							<v-btn
								@click="dismiss"
								icon
								:color="
									this.$vuetify.theme.dark
										? typeProperties.darkColor
										: typeProperties.color
								"
							>
								<v-icon>cancel</v-icon>
							</v-btn>
						</div>
					</slot>
				</div>
			</slot>
		</v-alert>
	</div>
</template>
<script>
const types = {
	success: {
		icon: "mdi-check-circle",
		color: "#00A908",
		darkColor: "#69B32D",
		class: "success-alert",
		alertClass: "alertsuccess",
	},
	warning: {
		icon: "mdi-alert",
		darkColor: "#FF7F2F",
		class: "warning-alert",
		color: "#DC5800",
		alertClass: "alertwarning",
	},
	error: {
		icon: "mdi-alert-circle",
		color: "#B81237",
		class: "error-alert",
		darkColor: "#EE3C48",
		alertClass: "alerterror",
	},
	info: {
		icon: "mdi-information",
		color: "#2E5299",
		class: "info-alert",
		darkColor: "#7EA0E4",
		alertClass: "alertinfo",
	},
}

export default {
	name: "MarkdownAlert",
	data() {
		return {
			show: this.showAlert,
			persisted: false,
			elementPosition: null,
		}
	},
	props: {
		type: {
			type: String,
			default: "info",
		},
		inPage: {
			type: Boolean,
			default: true,
		},
		border: {
			type: String,
			default: "left",
		},
		position: {
			type: String,
			default: "Bottom-Right",
		},
		icon: {
			type: String,
			default: null,
		},
		text: {
			type: String,
			default: null,
		},
		allowDismiss: {
			type: Boolean,
			default: false,
		},
		showAlert: {
			type: Boolean,
			default: false,
		},
		timeout: {
			type: Number,
			default: 0,
		},
		htmlText: {
			type: String,
			default: null,
		},
		markdownText: {
			type: String,
			default: null,
		},
		subtext: {
			type: String,
			default: null,
		},
		actionText: {
			type: String,
			default: null,
		},
		actionHandler: {
			type: Function,
			default: null,
		},
		persistBetweenRoutes: {
			type: Boolean,
			default: false,
		},
		alertClass: {
			type: String,
			default: "",
		},
		elevation: {
			type: [String, Number],
			default: "2",
		},
	},
	model: {
		prop: "showAlert",
		event: "input",
	},
	computed: {
		displayAlert: {
			get() {
				return this.showAlert
			},
			set(newValue) {
				this.$emit("input", newValue)
			},
		},
		typeProperties() {
			return types[this.type.toLowerCase()]
		},
		positionElement() {
			let defaultPosition = {
				top: false,
				right: false,
				bottom: false,
				left: false,
			}
			const positions = this.position.split("-")
			const positionsMap = positions.reduce((a, k) => {
				a[k] = true
				return a
			}, {})
			return { ...defaultPosition, ...positionsMap }
		},
		snackbarClass() {
			return (this.alertClass || "ml-2 mr-2") + " " + this.typeProperties.class
		},
		sbIcon() {
			return this.icon || this.typeProperties.icon
		},
		sbTimeout() {
			const timeout = parseInt(this.timeout)
			return isNaN(timeout) ? 2500 : timeout
		},
		generatedId() {
			let elements = 0
			if (this.persistBetweenRoutes || !this.inPage) {
				elements = document.getElementsByClassName("alert---snackbar").length
				return "persistent--element" + elements
			} else {
				elements = document.getElementsByClassName("alert---element").length
				return "alert--element" + elements
			}
		},
	},
	mounted: function () {
		this.$nextTick(function () {
			this.persistent()
		})
	},
	updated: function () {
		this.$nextTick(function () {
			this.showInstance()
			this.persistent()
		})
	},
	methods: {
		dismiss: function () {
			this.$el.style.display = "none"
			this.$emit("dismiss")
		},
		persistent() {
			if (this.persistBetweenRoutes) {
				let alertElement = this.$el
				this.elementPosition = this.$el.parentElement
				this.persisted = true
				if (alertElement.children.length > 0) {
					if (this.allowDismiss) {
						alertElement
							.getElementsByTagName("button")[0]
							.addEventListener("click", function () {
								alertElement.style.display = "none"
							})
					}
					this.$root.$el.appendChild(alertElement)
				}
			}
			return this.persistBetweenRoutes
		},
		showInstance() {
			if (this.$el.style.display == "none" && this.showAlert) {
				this.$el.style.display = "block"
			}
		},
		noop() {
			return
		},
	},
	watch: {
		$route: function () {
			if (!this.persistBetweenRoutes && this.show) {
				this.dismiss()
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import "../scss/variables.scss";

::v-deep .v-alert__icon.v-icon {
	font-size: 27px;
	height: 27px;
	min-width: 27px;
}
div.v-snack {
	width: auto;
	margin: auto;
	::v-deep .v-snack__content {
		padding: 25px;
	}
	&.v-snack--bottom {
		left: 20px;
		bottom: 20px;
	}
}
::v-deep .v-btn--icon.v-size--default {
	height: 30px;
	width: 29px;
	padding: 0;
}
#persistent--element1 .v-snack {
	bottom: 20px !important;
}
#persistent--element2 .v-snack {
	bottom: 170px !important;
}
#persistent--element3 .v-snack {
	bottom: 320px !important;
}
#persistent--element4 .v-snack {
	bottom: 470px !important;
}
#persistent--element5 .v-snack {
	bottom: 620px !important;
}
.alert--clickable-action {
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: bold;
}
.theme--light {
	.alertsuccess {
		background: #def3d8 !important;
	}
	.alertwarning {
		background: #fae8dd !important;
	}
	.alertinfo {
		background: #e0e9ff !important;
	}
	.alerterror {
		background: #f5e1e3 !important;
	}
	div.v-snack {
		.snackbar--text {
			color: #333333;
			font-weight: bold;
		}
		.alert--text {
			color: #333333;
			font-weight: bold;
		}
		.alert--textsimple {
			color: #333333;
		}
	}
}
.theme--dark {
	.v-snack,
	.v-alert {
		.alertsuccess {
			background: #333333 !important;
		}
		.alertwarning {
			background: #333333 !important;
		}
		.alertinfo {
			background: #333333 !important;
		}
		.alerterror {
			background: #333333 !important;
		}
	}
}

.embeddedalert ::v-deep.v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
::v-deep .vue-simple-markdown.markdown-body {
	line-height: 24px;
	h3 {
		margin-top: 0px;
		margin-bottom: 5px;
		font-size: 16px;
		/*color: $ads-blue-1;*/
	}
	h4 {
		margin-top: 0px;
		margin-bottom: 5px;
		padding-left: 30px;
		font-size: 16px;
	}
	a {
		color: $ads-blue-1;
	}
	ul li {
		margin-bottom: 0.7em;
	}
	hr {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	ul {
		margin-top: 0.7em;
		margin-bottom: -5px;
		/*margin-bottom: 5px;*/
	}
}
</style>
