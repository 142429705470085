<template>
	<div>
		<template v-if="!loading && selectedParent.children.standards.length === 0">
			<NothingFoundCard />
		</template>
		<template v-else>
			<!--Alert
				v-if="blurb"
				class="embeddedalert"
				show-alert
				type="info"
				:text="blurb.title"
				in-page
			>
				<template v-slot:optional>
					<ShowMore
						v-if="$vuetify.breakpoint.xsAndDown"
						:text="blurb.desc"
						:max-chars="100"
					/>
					<div v-else>{{ blurb.desc }}</div>
				</template>
			</Alert-->
			<MarkdownAlert v-if="blurb" :markdown-text="blurb" />

			<MultiCardPanel
				class="mb-8"
				:items="standards"
				:loading="loading"
				@click="onItemClicked"
			/>
		</template>
	</div>
</template>

<script>
import { AdsButton, AdsDataTable, Alert, ShowMore } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"
import {
	ProgressChip,
	MultiCardPanel,
	NothingFoundCard,
	MarkdownAlert,
} from "."

const ChildSelection = {
	name: "ChildSelection",
	async created() {},
	props: {
		qualityAreaShort: String,
		standardShort: String,
	},
	computed: {
		...mapGetters({
			userProfile: "profile",
			assessment: "selectedAssessment",
			assessmentIsLoading: "assessmentIsLoading",
			qualityAreasAreLoading: "qualityAreasAreLoading",
			qualityAreaStructureIsLoading: "qualityAreaStructureIsLoading",
			hasSelectedQualityArea: "hasSelectedQualityArea",
			structure: "selectedQualityAreaStructure",
		}),
		standards() {
			let toRet = []

			if (this.qualityAreasAreLoading) return toRet
			if (this.loading) {
				for (let i = 0; i < 2; i++) {
					toRet.push({ title: true, subtitle: true, date: true })
				}
				return toRet
			}

			for (let child of this.selectedParent.children.standards) {
				toRet.push({
					title: child.name,
					subtitle: child.subtitle,
					date: this.getChildData(child.short)?.progress,
					short: child.short,
				})
			}
			return toRet
		},
		selectedParent() {
			if (this.standardShort) {
				if (this.$store.getters.selectedStandard)
					return this.$store.getters.selectedStandard
				else return { name: "", number: "" }
			} else {
				if (this.$store.getters.selectedQualityAreaStructure)
					return this.$store.getters.selectedQualityAreaStructure
				else return { name: "", number: "" }
			}
		},
		selectedParentData() {
			if (this.loading) return false
			let toRet = this.assessment.qualityAreas.find(
				(v) => v.name == this.qualityAreaShort,
			)

			if (this.standardShort) {
				return toRet?.standards?.find((v) => v.name == this.standardShort)
			}
			return toRet
		},
		blurb() {
			return this.selectedParent.blurb
		},
		loading() {
			return (
				this.assessmentIsLoading ||
				this.qualityAreaStructureIsLoading ||
				!this.hasSelectedQualityArea ||
				!this.selectedParent.children
			)
		},
	},
	data() {
		return {
			hasShownBrokenChildDataAlert: false,
		}
	},
	methods: {
		onItemClicked(item) {
			if (!item.short || !this.qualityAreaShort) return
			if (this.standardShort) {
				this.$router.push({
					name: "Element",
					params: {
						qualityArea: this.qualityAreaShort,
						standard: this.standardShort,
						element: item.short,
					},
				})
			} else {
				this.$router.push({
					name: "Standards",
					params: {
						qualityArea: this.qualityAreaShort,
						standard: item.short,
					},
				})
			}
		},
		// eslint-disable-next-line no-unused-vars
		getChildData(short) {
			let childData
			if (this.loading) return false
			if (this.selectedParentData?.elements) {
				childData = this.selectedParentData.elements.find(
					(v) => v.name == short,
				)
			} else if (this.selectedParentData?.standards) {
				childData = this.selectedParentData.standards.find(
					(v) => v.name == short,
				)
			}
			if (childData) {
				return childData
			}
			if (!this.hasShownBrokenChildDataAlert) {
				this.$store.dispatch(
					"quickWarning",
					"Couldn't find assessment data for this standard.",
				)
				this.hasShownBrokenChildDataAlert = true
			}
			return false
		},
	},
	components: {
		AdsButton,
		AdsDataTable,
		ProgressChip,
		MultiCardPanel,
		NothingFoundCard,
		Alert,
		ShowMore,
		MarkdownAlert,
	},
}

export default ChildSelection
</script>

<style lang="scss" scoped>
#main-content {
	padding-bottom: 0px !important;
	::v-deep div.pl-0.col {
		padding-top: 0px !important;
	}
}
.spacebetween {
	justify-content: space-between;
}
::v-deep .embeddedalert .v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
</style>
