/* eslint-disable prettier/prettier */
/*
  All API calls should use this helper instead of calling axios directly.
  This helper provides the following:

  - Mock response infrastructure
  - Automatic path resolution (where partial URLs will be prefixed with full api root)
  - Global api error handling
  - Default headers
  - Custom data transformations
  - Automatic loading spinners
*/

import axios from "axios";
import { baseApiUrl } from "@/utils/urlConfig";
// REMOVE POST DEVELOPMENT {
const useMockData = true; //process.env.NODE_ENV === "development"
// } REMOVE POST DEVELOPMENT
const mockResponseDelay = 0.55; // Mock response delay in seconds. A delay makes the mock experience more realistic, giving us a chance to see spinners and any "pop-in" effect.
var $store;

export default {
  setStore(store) {
    // Called by main.js to give apiHelper a store reference. This allows it to
    // control things like spinners and message boxes when needed.
    $store = store;
  },

  get({ endpoint, spinner, headers, mock, transformation, responseType }) {
    /*
      Makes a GET request for a specified endpoint.
        endpoint <string> - Required endpoint, e.g. "applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
        headers <object> - Optional headers to use instead of defaults
        mock <string> - Optional filename of mock data within api/mock-responses folder. If specified, mock data will be returned as a promise when running localhost.
        transformation <function> - Optional data transformation. With GET requests, transformation is performed on "response.data". With POST/PUT requests, transformation is performed on payload.
    */

    return request({
      method: "get",
      payload: null,
      endpoint,
      spinner,
      headers,
      mock,
      transformation,
      responseType,
    });
  },

  post({
    endpoint,
    payload,
    spinner,
    headers,
    mock,
    transformation,
    responseType,
  }) {
    /*
      Makes a POST request for a specified endpoint.
        endpoint <string> - Required endpoint, e.g. "applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
        headers <object> - Optional headers to use instead of defaults
        mock <string> - Optional filename of mock data within api/mock-responses folder. If specified, mock data will be returned as a promise when running localhost.
        transformation <function> - Optional data transformation. With GET requests, transformation is performed on "response.data". With POST/PUT requests, transformation is performed on payload.
    */
    return request({
      method: "post",
      payload,
      endpoint,
      spinner,
      headers,
      mock,
      transformation,
      responseType,
    });
  },

  patch({
    endpoint,
    payload,
    spinner,
    headers,
    mock,
    transformation,
    responseType,
  }) {
    /*
      Makes a PATCH request for a specified endpoint.
        endpoint <string> - Required endpoint, e.g. "applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
        headers <object> - Optional headers to use instead of defaults
        mock <string> - Optional filename of mock data within api/mock-responses folder. If specified, mock data will be returned as a promise when running localhost.
        transformation <function> - Optional data transformation. With GET requests, transformation is performed on "response.data". With POST/PUT requests, transformation is performed on payload.
    */
    return request({
      method: "patch",
      payload,
      endpoint,
      spinner,
      headers,
      mock,
      transformation,
      responseType,
    });
  },

  put({
    endpoint,
    payload,
    spinner,
    headers,
    mock,
    transformation,
    responseType,
  }) {
    /*
      Makes a PUT request for a specified endpoint.
        endpoint <string> - Required endpoint, e.g. "applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
        payload <json> - Optional payload
        spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
        headers <object> - Optional headers to use instead of defaults
        mock <string> - Optional filename of mock data within api/mock-responses folder. If specified, mock data will be returned as a promise when running localhost.
        transformation <function> - Optional data transformation. With GET requests, transformation is performed on "response.data". With POST/PUT requests, transformation is performed on payload.
    */
    return request({
      method: "put",
      payload,
      endpoint,
      spinner,
      headers,
      mock,
      transformation,
      responseType,
    });
  },

  delete({
    endpoint,
    payload,
    spinner,
    headers,
    mock,
    transformation,
    responseType,
  }) {
    /*
    Makes a PUT request for a specified endpoint.
      endpoint <string> - Required endpoint, e.g. "applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
      payload <json> - Optional payload
      spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
      headers <object> - Optional headers to use instead of defaults
      mock <string> - Optional filename of mock data within api/mock-responses folder. If specified, mock data will be returned as a promise when running localhost.
      transformation <function> - Optional data transformation. With GET requests, transformation is performed on "response.data". With POST/PUT requests, transformation is performed on payload.
  */
    return request({
      method: "delete",
      payload,
      endpoint,
      spinner,
      headers,
      mock,
      transformation,
      responseType,
    });
  },
};
// Local helper functions ---------------------------------------------
function request({
  method,
  endpoint,
  payload,
  spinner = true,
  headers,
  mock,
  transformation,
  responseType,
}) {
  /*
    Makes a request for a specified endpoint.
      method <string> - Required http verb ('get', 'post', 'patch', 'put', or 'delete')
      endpoint <string> - Required endpoint, e.g. "/applications/<id>". Will be prefixed with API url unless endpoint is already a URL ("http...")
      payload <json> - Optional payload
      spinner <boolean> - Optional flag to specify if spinner should appear during the request. Default is "true".
      headers <object> - Optional headers to use instead of defaults
      mock <string> - Optional filename of mock data within api/mock-responses folder. If specified, mock data will be returned when running localhost.
      transformation <function> - Optional data transformation. With GET requests, transformation is performed on "response.data". With POST/PUT requests, transformation is performed on payload.
  */

  return new Promise((resolve, reject) => {
    // Return mock data if running localhost...
    let mockData = getMockData(mock, method, transformation);
    if (mockData) {
      setTimeout(() => {
        if (mockData.response >= 300) {
          reject(mockData);
        } else {
          resolve(mockData);
        }
      }, mockResponseDelay * 1000 + Math.random() * 500);
      return;
    }
    axios({
      method: method,
      url: resolveEndpoint(endpoint),
      data: transformation && payload ? transformation(payload) : payload,
      headers: headers || getHeaders(),
      responseType: responseType || undefined,
    })
      .then((response) => {
        if (method === "get") {
          // For GET requests, return response data with any transformation applied.
          // console.log(
          //   `calling an api: method: ${method} and endpoint: ${endpoint} and got: ${JSON.stringify(
          //     response.data
          //   )}`
          // );
          resolve(
            transformation ? transformation(response.data) : response.data
          );
        } else {
          // For PUT/POST/DELETE requests, return response object.
          resolve(response);
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error.response);
        hideSpinner(spinner);
      });
  });
}

function resolveEndpoint(endpoint) {
  /*
    Endpoints can be full or relative. If relative, they will automatically
    be prefixed with the API root. The opening slash is optional for
    relative endpoints.
  */
  if (
    endpoint.indexOf("http") === 0 ||
    endpoint.indexOf("https") === 0 ||
    endpoint.indexOf("//") === 0
  ) {
    return endpoint;
  }
  if (endpoint.indexOf("/") !== 0) {
    endpoint = `/${endpoint}`;
  }
  return baseApiUrl + endpoint;
}

function getHeaders() {
  setupAjaxErrorHandling();
  return {};
}

function setupAjaxErrorHandling() {
  // Globally handles ajax errors without need for "catch" methods
  // on every call.
  if (!axios.interceptors.response.handlers.length) {
    axios.interceptors.response.use(
      function (response) {
        // Success responses passed back untouched...
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }
}

function hideSpinner() {
  /*
    Unless turned off, each request shows a spinner until complete. However each request
    can't just hide the spinner when complete because there could be other incomplete requests
    still running that need the spinner. So a counter is used to keep track of incomplete requests,
    and the spinner is only hidden after all requests have completed (incompleteRequests = 0)
  */
  $store.commit("hideSpinner");
}

function getMockData(mockDataFilename, httpMethod, transformation) {
  /*
    When running on localhost, this returns the specified mock data. On production
    environments no mock data is returned or even imported ("require" allows us to
    conditionally import).

      mockDataFilename <string> - Specifies the file to import from "api/mock-responses" (no ".js" at the end)
      httpMethod <string> - API request type, e.g. 'get'
      transformation <function> - Optional api data transformation function
  */
  if (useMockData && mockDataFilename) {
    let mockData = require(`./mock-responses/${mockDataFilename}`).default;
    if (transformation && httpMethod === "get") {
      /*
          Only perform transformation for GET requests. In the rare event that we are mocking
          a PUT/POST response, it is the payload that should be transformed, not the response.
          The payload transformation is done elsewhere.
        */
      mockData = transformation(mockData);
    }
    return mockData;
  }
}
