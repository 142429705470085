<template>
	<v-card tile>
		<v-card-title
			class="d-flex align-center justify-space-between pa-2 px-5"
			:class="{ 'pl-3': $vuetify.breakpoint.xsOnly }"
		>
			<div class="d-flex align-center pt-3">
				<h3>
					<div class="d-flex align-center">
						<span class="pt-1"> Service philosophy statement </span>
					</div>
				</h3>
			</div>
			<div class="ml-3">
				<v-btn icon aria-label="close" @click="handleBack">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>

		<v-card-text class="mt-5 px-8 normal-text inputwrapper">
			<v-row v-if="!loading">
				<v-col>
					<v-textarea
						outlined
						placeholder=""
						rows="6"
						auto-grow
						:label="'Input philosophy statement'"
						:counter="5000"
						:rules="[rules.required, rules.under5000]"
						class="big-label"
						ref="issueDetails"
						v-model="philosophyStatement"
					/>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col>
					<v-skeleton-loader type="article" width="100%" />
				</v-col>
			</v-row>
			<v-row class="mt-3 d-flex justify-right">
				<AdsButton
					class="mx-2"
					button-text="Cancel"
					:secondary="true"
					@click="handleBack"
				>
				</AdsButton>
				<AdsButton
					class="mx-2"
					button-text="Done"
					@click="handleSubmit"
					:disabled="cantSubmit"
				>
				</AdsButton>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import api from "../api"
import { AdsButton } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"

export default {
	name: "AddPhilosophyStatement",
	data() {
		return {
			philosophyStatement: "",
			rules: {
				required: (value) => !!value || "Please fill out this field.",
				under5000: (value) =>
					(value && value.length <= 5000) ||
					"Must be less than 5000 characters.",
			},
		}
	},
	computed: {
		...mapGetters({
			statement: "philosophyStatement",
			loading: "assessmentIsLoading",
			assessment: "selectedAssessment",
		}),
		assessmentId() {
			return this.assessment.assessment_id
		},
		cantSubmit() {
			return (
				this.loading ||
				this.philosophyStatement.length <= 0 ||
				this.philosophyStatement.length > 5000
			)
		},
	},
	created() {
		if (this.statement) this.philosophyStatement = this.statement
	},
	watch: {
		loading(newVal) {
			if (newVal === false) {
				this.philosophyStatement = this.statement
			}
		},
	},
	methods: {
		handleBack() {
			this.$emit("handleBack")
		},
		async handleSubmit() {
			await api.assessments
				.setPhilosophyStatement(this.assessmentId, this.philosophyStatement)
				.then((data) => {
					if (data) {
						this.$store.dispatch(
							"quickConfirmation",
							"Successfully updated your philosophy statement!",
						)
						this.$store.commit(
							"setPhilosophyStatement",
							this.philosophyStatement,
						)
					} else {
						throw Promise.reject(data)
					}
				})
				.catch((reason) => {
					this.$store.dispatch("displayApiError", [
						"your philosophy statement",
						"set",
						reason.reason || reason.data?.reason || reason.status,
					])
				})
				.finally(() => {
					setTimeout(() => {
						this.handleBack()
					}, 600)
				})
		},
	},
	components: {
		AdsButton,
	},
}
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";

.justify-right {
	justify-content: right;
}
.theme--light {
	.normal-text ::v-deep.v-label,
	.normal-text {
		color: $ads-dark;
		font-size: 16px !important;
		font-weight: 500 !important;
	}
	b.subtitle-1 {
		color: $ads-navy;
	}
	.blue-text {
		color: $ads-blue-1;
	}
}

::v-deep .v-input__control .v-input__slot .v-text-field__slot label.v-label {
	min-height: 1.1em;
}
::v-deep .v-counter {
	padding-top: 2px;
}

.inputwrapper ::v-deep {
	.v-input__control .v-input__slot legend {
		width: 4.3em !important;
	}
	/* replicate .v-label--active permanently */
	.v-input__control .v-input__slot .v-text-field__slot label.v-label {
		@extend .v-label--active;
		transform: translateY(-24px) scale(0.75);
		font-size: 1.4rem !important;
		padding-top: 2px;
	}
}
.inputwrapper .small-label ::v-deep.v-input__control .v-input__slot legend {
	width: 2.6em !important;
}
.inputwrapper .regular-label ::v-deep.v-input__control .v-input__slot legend {
	width: 7.4em !important;
}
.inputwrapper .medium-label ::v-deep.v-input__control .v-input__slot legend {
	width: 9.4em !important;
}
.inputwrapper .big-label ::v-deep.v-input__control .v-input__slot legend {
	width: 13em !important;
}
</style>
