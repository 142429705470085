<template>
	<div class="d-flex justify-right px-2 save-status-wrapper subtitle-2">
		<v-fade-transition>
			<div v-if="status == 1">
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-icon class="orange-icon" v-on="on" v-bind="attrs"
							>warning_amber</v-icon
						>
						<template v-if="$vuetify.breakpoint.smAndUp">Unsaved</template>
					</template>
					<span> You have some unsaved data. </span>
				</v-tooltip>
			</div>
			<div v-if="status == 2">
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-icon class="grey-icon spin-me" v-on="on" v-bind="attrs"
							>sync</v-icon
						>
						<template v-if="$vuetify.breakpoint.smAndUp">Saving...</template>
					</template>
					<span> Your data is currently saving. </span>
				</v-tooltip>
			</div>
			<div v-if="status == 3 || status == 0" v-show="status == 3">
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-icon class="green-icon" v-on="on" v-bind="attrs"
							>check_circle_outline</v-icon
						>
						<template v-if="$vuetify.breakpoint.smAndUp">Saved!</template>
					</template>
					<span> Your data is saved! </span>
				</v-tooltip>
			</div>
		</v-fade-transition>
	</div>
</template>

<script>
const SaveStatus = {
	name: "SaveStatus",
	components: {},
	props: {
		status: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {}
	},
	created() {},
}

export default SaveStatus
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";
.justify-right {
	justify-content: right;
}
.spin-me {
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

.save-status-wrapper {
	min-height: 24px;
	div {
		color: $ads-grey-02;
		font-weight: 500;
	}
}
.orange-icon,
.grey-icon,
.green-icon {
	margin-right: 5px;
}
.green-icon {
	color: $ads-success-green;
}
.orange-icon {
	color: $ads-warning-orange;
}
.grey-icon {
	color: $ads-dark-60;
}
</style>
