var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h2',{staticClass:"mb-3"},[_vm._v("Compliance")]),_vm._m(0),_c('div',[(_vm.isLoading)?_vm._l(([1, 2]),function(i){return _c('v-card',{key:i,staticClass:"my-3",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-skeleton-loader',{staticClass:"chip-loader",attrs:{"type":'heading, avatar',"width":"100%"}})],1)],1)}):[_c('AdsExpansionPanel',{staticClass:"mb-8",attrs:{"items":_vm.items},scopedSlots:_vm._u([_vm._l((_vm.items),function(item,i){return {key:`content${i+1}`,fn:function(){return [_c('div',{key:i,staticClass:"px-3"},_vm._l((item.data),function(check,j){return _c('v-row',{key:j},[_c('v-checkbox',{staticClass:"px-6",class:{
									'ml-6': check.title.slice(0, 2) == '- ',
									'mt-n1': check.title.slice(0, 2) == '- ',
								},attrs:{"dense":true,"disabled":check.blank,"readonly":_vm.viewOnly},on:{"change":_vm.trackDirty},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-col',{attrs:{"cols":"9","sm":"10"}},_vm._l((check.title.split('\n')),function(line,k){return _c('div',{key:k,staticClass:"pr-2",class:{
												'pt-2': k > 0,
												'pl-5': line.indexOf('\t') != -1,
											}},[_vm._v(" "+_vm._s(line)+" ")])}),0),_c('v-col',{attrs:{"cols":"3","sm":"2"}},[_c('b',{staticClass:"source"},[_vm._v(" "+_vm._s(check.source)+" ")])])]},proxy:true}],null,true),model:{value:(_vm.assessmentQA.checklist[check.id]),callback:function ($$v) {_vm.$set(_vm.assessmentQA.checklist, check.id, $$v)},expression:"assessmentQA.checklist[check.id]"}})],1)}),1)]},proxy:true}})],null,true)})]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" While compliance with "),_c('a',{attrs:{"href":"https://www.acecqa.gov.au/nqf/national-law-regulations"}},[_vm._v("legislation")]),_vm._v(" is an everyday requirement, it is also a crucial part of self-assessment. Once you have critically reflected and assessed how your practice is meeting and perhaps exceeding the standards, consider whether you are also still compliant. Complete the following checklist and if any items are not being met, address those immediately. ")])
}]

export { render, staticRenderFns }