<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<template>
	<div>
		<AssessmentBanner
			:class="{'banner--no-margins': showGuidance && $vuetify.breakpoint.mdAndUp}"
			:header="selectedElement.name"
			:subheader="
				selectedStandard.name
					? selectedStandard.name.split(selectedStandard.number + ' - ')[1]
					: '?'
			"
			:loading="loading"
			:progress="progress"
		/>
		<div :class="{'grid-container': showGuidance && $vuetify.breakpoint.mdAndUp}">
			<v-container :class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'">
				<v-form ref="form" model="valid">
					<v-row class="my-6 spacebetween px-5" :no-gutters="true">
						<v-col
							style="display: flex; align-items: center; justify-content: left"
							@click="goBack()"
						>
							<v-icon class="mr-2">arrow_back</v-icon>
							<a :align="'center'">Back</a>
						</v-col>
						<v-col
							style="display: flex; align-items: center; justify-content: right"
						>
							<div
								v-if="!showGuidance"
								style="display: flex; align-items: center"
								@click.stop="toggleGuidance()"
							>
								<v-icon class="mr-2">help_outline</v-icon>
								<a :align="'center'">Guidance</a>
							</div>
						</v-col>
					</v-row>
					<div>
						<MarkdownAlert v-if="selectedElement.subtitle" :subtext="selectedElement.subtitle"/>
						<AdsExpansionPanel :items="[{id: 0,title: '<span><b>' + this.selectedElement.name + '</b></span>'}]" class="mb-8">
							<template slot="content1">
								<v-row class="mb-6 spacebetween px-5" :no-gutters="true">
									<v-col style="display: flex; align-items: center">
										<h2 class="font-weight-bold">Record strengths</h2>
									</v-col>
								</v-row>
								<v-row class="mb-6 spacebetween px-5 initialblurb" :no-gutters="true">
									<v-col>
										Enter <b>up to five</b> examples of strengths. If you have any
										concerns or gaps, you can record them in the
										<a href="#" @click="openIdentifiedIssues">identified issues</a> tab
										for this Quality Area.
									</v-col>
								</v-row>
								<RecordStrength
									v-for="(strength, index) of strengths"
									class="my-5"
									:index="index"
									:key="strength.id"
									:title="strength.text"
									:strength="getStrengthData(strength.id)"
									:loading="loading"
								/>
							</template>
						</AdsExpansionPanel>
					</div>
				</v-form>
			</v-container>
			<v-navigation-drawer v-if="showGuidance && $vuetify.breakpoint.mdAndUp" width="100%" right permanent>
				<StandardGuideDisplay @closeDialog="toggleGuidance()" />
			</v-navigation-drawer>
		</div>
		<v-dialog
			v-if="$vuetify.breakpoint.smAndDown"
			v-model="showGuidance"
			max-width="800px"
			:fullscreen="$vuetify.breakpoint.xsOnly"
		>
			<StandardGuideDisplay @closeDialog="toggleGuidance()" />
		</v-dialog>
	</div>
</template>

<script>
import { AssessmentBanner, RecordStrength, StandardGuideDisplay, MarkdownAlert } from "../components"
import { AdsButton, AdsExpansionPanel } from "@nswdoe/doe-ui-core"
import { isFormIncomplete } from "@/utils"
import { mapGetters } from "vuex"

const elementDisplay = {
	title: "Element",
	name: "elementDisplay",
	async created() {
		if (this.qualityAreasAreLoading === false && !this.hasSelectedQualityArea) {
			this.loadQualityAreaStructure()
		}
		if (this.hasSelectedQualityArea) {
			this.$store.commit("hardSelectElement", [
				this.$route.params.standard,
				this.$route.params.element,
			])
		}
	},
	watch: {
		qualityAreasAreLoading: {
			immediate: true,
			handler(newVal) {
				if (newVal === false) this.loadQualityAreaStructure()
			},
		},
		hasSelectedQualityArea: {
			immediate: true,
			handler(newVal) {
				if (newVal === true) {
					this.$store.commit("hardSelectElement", [
						this.$route.params.standard,
						this.$route.params.element,
					])
				}
			},
		},
		elementData: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					if (this.elementData.strengths.length < 1) {
						this.$store.dispatch(
							"quickWarning",
							"Couldn't find strength data for this element.",
						)
					}
				}
			},
		},
	},
	computed: {
		...mapGetters([
			"qualityAreasAreLoading",
			"qualityAreaStructureIsLoading",
			"hasSelectedQualityArea",
			"selectedElement",
			"selectedStandard",
			"selectedAssessment",
			"assessmentIsLoading",
		]),
		strengths() {
			return this.selectedElement.children?.strengths
		},
		loading() {
			return (
				!this.selectedElement ||
				this.qualityAreasAreLoading ||
				this.qualityAreaStructureIsLoading ||
				this.assessmentIsLoading
			)
		},
		elementData() {
			if (this.loading) return false
			return this.selectedAssessment.qualityAreas
				.find((v) => v.name == this.$route.params.qualityArea)
				?.standards.find((v) => v.name == this.$route.params.standard)
				?.elements.find((v) => v.name == this.$route.params.element)
		},
		progress() {
			if (this.loading) return -1
			return this.getStrengthProgress(this.strengths)
		}
	},
	data() {
		return {
			showGuidance: false,
		}
	},
	methods: {
		getStrengthProgress(strengths) {
			let allStrengthsEdited = 0
			for (let strength of strengths) {
				if (!this.strengthEmpty(this.getStrengthData(strength.id)))
					allStrengthsEdited += 1
			}
			return (allStrengthsEdited / strengths.length) * 100
		},
		strengthEmpty(strength) {
			if (strength.text.length > 1) return false
			if (strength.reflect || strength.embed || strength.engagement)
				return false
			if (strength.evidenceAttached) return false
			if (strength.wontRespond) return false
			return true
		},
		openIdentifiedIssues() {
			this.$router.push({
				name: "Quality Area",
				params: {
					qualityArea: this.$route.params.qualityArea,
				},
				query: {
					tab: 1,
				},
			})
		},
		getStrengthData(id) {
			if (!this.elementData) return {}
			return this.elementData.strengths.find((v) => v.id == id)
		},
		goBack() {
			this.$router.push({
				name: "Standards",
				params: {
					qualityArea: this.$route.params.qualityArea,
					standard: this.$route.params.standard,
				},
			})
		},
		sendToQIP() {
			if (isFormIncomplete(this.$refs.form)) {
				this.$store.dispatch("quickWarning", "Please complete all fields")
				return
			}
		},
		loadQualityAreaStructure() {
			this.$store.commit("selectQualityArea", this.$route.params.qualityArea)
			if (!this.$store.getters.selectedQualityAreaStructure) {
				this.$store.dispatch(
					"fetchQualityAreaStructure",
					this.$route.params.qualityArea,
				)
			}
		},
		toggleGuidance() {
			this.showGuidance = !this.showGuidance
		},
	},
	components: {
		AssessmentBanner,
		AdsButton,
		RecordStrength,
		AdsExpansionPanel,
		StandardGuideDisplay,
		MarkdownAlert,
	},
}

export default elementDisplay
</script>

<style lang="scss" scoped>
@import "../scss/invert-banner.scss";

#main-content {
	padding-bottom: 0px !important;
	::v-deep div.pl-0.col {
		padding-top: 0px !important;
	}
}
.spacebetween {
	justify-content: space-between;
}
::v-deep .embeddedalert .v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
.initialblurb {
	a {
		color: $ads-blue-1;
	}
}
.banner--no-margins {
	:deep(.container) {
		margin-left: 0;
		margin-right: 0;
	}
}
.grid-container {
	display: grid;
	grid-template-columns: 2fr 1fr;
}
:deep .v-navigation-drawer {
	height: fit-content !important;

	&__content {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}
</style>
