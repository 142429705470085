<!-- eslint-disable prettier/prettier -->
<template>
	<v-card @input="dirty">
		<v-card-title v-if="isDialog" class="d-flex justify-space-between">
			<h3 class="d-flex">
				<FeatureIcon
					class="mr-3"
					size="48"
					color="navy"
					background-color="light blue"
					icon="mdi-pencil"
				/>
				<span class="pt-3"> Edit </span>
			</h3>

			<div class="ml-3">
				<v-btn icon aria-label="close" @click="handleBack">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-card-title>
		<v-card-text v-if="loading">
			<v-skeleton-loader type="article" />
		</v-card-text>
		<v-card-text :class="{ 'px-6': !isDialog, 'px-6 py-3': isDialog }" v-else>
			
			<v-row class="d-flex justify-space-between align-center">
				<v-col class="left-col" cols="auto">
					<span class="body-1 font-weight-bold">Example {{ index + 1 }}</span>
				</v-col>
				<v-col class="right-col" cols="auto">
					<div>
						<v-switch v-model="wontRespond">
							<template v-slot:label>
								<span class="font-weight-bold">No response at this time</span>
							</template>
						</v-switch>
					</div>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<span class="body-1 font-weight-regular">{{ title }}</span>
				</v-col>
			</v-row>
			
			<v-expand-transition>
				<div v-if="!wontRespond">
					<v-textarea
						outlined
						placeholder=""
						rows="3"
						auto-grow
						:id="strength.id"
						v-model="text"
						:label="'Enter Details'"
						:counter="500"
						:rules="[rules.under500]"
						@input="checkCanSaveToQip"
					/>
					<h3>Exceeding themes addressed in your example</h3>
					<v-row>
						<v-checkbox 
							class="px-4" 
							label="Embed" 
							v-model="embed"
						/>
						<v-checkbox
							class="px-4"
							label="Critically reflect"
							v-model="reflect"
						/>
						<v-checkbox
							class="px-4"
							label="Meaningful engagement"
							v-model="engagement"
						/>
					</v-row>
					<v-row v-for="(piece, i) of evidence" :key="i">
						<v-col cols="12" md="6">
							<AdsFileInput
								label="Attach Evidence"
								hint="Attach evidence to support your example"
								:persistent-hint="true"
								:file.sync="piece.evidence"
								:multiple="false"
								:rules="[rules.singleFile, rules.fileSize]"
								ref="evidence"
								@change="handleEvidenceChange(i, $event)"
							/>
							<a class="px-5" v-if="piece.evidence && piece.evidence.length !== 0" @click="viewEvidence(piece.id)">{{
								piece.evidence[0]?.name
							}}</a>
						</v-col>
						<v-col cols="12" md="6">
							<AdsTextField label="Comments" :value="piece.comments" @input="handleCommentInput(i, $event)"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="subtitle-1 d-flex justify-space-between">
							<div
								class="add-another-btn"
								@click="addMoreEvidence()"
								:disabled="evidence.length >= 2"
							>
								<v-icon class="mr-2" v-show="evidence.length < 2"
									>add_circle_outline</v-icon
								>
								<a :align="'center'" v-if="evidence.length < 2">Add another</a>
								<a :align="'center'" v-if="evidence.length >= 2"
									>Two pieces of evidence may be added per statement</a
								>
							</div>
							<SaveStatus :status="status" />
						</v-col>
						<v-col cols="12" class="d-flex justify-right">
							<div v-if="isDialog">
								<AdsButton
									class="px-3 mx-2"
									tertiary
									button-text="Clear all fields"
									@click="clearFields"
								/>
								<AdsButton
									class="px-3 mx-2"
									secondary
									button-text="Cancel"
									@click="handleBack"
								/>
								<AdsButton
									class="px-3 mx-2"
									button-text="Update"
									:disabled="!saveButtonEnabled"
									@click="sendToQip(strength)"
								/>
							</div>
							<div v-else class="sendtoqip-btn">
								<AdsButton
									class="px-3 mx-2"
									tertiary
									button-text="Clear all fields"
									@click="clearFields()"
								/>
								<AdsButton
									:icon="'send'"
									button-text="Save to QIP"
									secondary
									@click="sendToQip(strength)"
									:disabled="!saveButtonEnabled"
								/>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-expand-transition>
		</v-card-text>
	</v-card>
</template>

<script>
import api from "../api"
import AdsFileInput from "./AdsFileInput.vue"
import { AdsButton, AdsTextField, FeatureIcon } from "@nswdoe/doe-ui-core"
import { mapGetters } from "vuex"
import _ from "lodash"
import SaveStatus from "./SaveStatus.vue"

const RecordStrength = {
	name: "RecordStrength",
	components: { AdsFileInput, AdsButton, AdsTextField, FeatureIcon, SaveStatus },
	props: {
		index: {
			type: Number,
			default: "",
		},
		title: {
			type: String,
			default: "",
		},
		subtitle: {
			type: String,
			default: "",
		},
		strength: {
			type: Object,
			default() {
				return {}
			},
		},
		mode: {
			type: String,
			default() {
				return "add" // (add || dialog)
			},
		},
		loading: {
			type: Boolean,
			default() {
				return false
			},
		},
	},
	data() {
		return {
			rules: {
				required: (value) => !!value || "Please fill out this field.",
				under500: (value) =>
					(typeof value === "string" && value.length <= 500) ||
					"Must be less than 500 characters.",
				singleFile: (value) =>
					!value ||
					value.length <= 1 ||
					"You can only upload one file at a time.",
				fileSize: (value) =>
					!value ||
					value.length == 0 ||
					value[0].size <= this.maxSize ||
					`File must be less than ${Math.round(this.maxSize / 1000000)}MB.`,
			},
			maxSize: 5 * 1024 * 1024,
			status: 0,
			saveButtonEnabled: false,
		}
	},
	computed: {
		...mapGetters(["centreId"]),
		assessmentId() {
			return this.$store.getters.selectedAssessment.assessment_id
		},
		text: {
			get() {
				return this.strength.text
			},
			set(val) {
				const strengthCopy = _.cloneDeep(this.strength)
				strengthCopy.text = val
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
			}
		},
		embed: {
			get() {
				return this.strength.embed
			},
			set(val) {
				const strengthCopy = _.cloneDeep(this.strength)
				strengthCopy.embed = val
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
			}
		},
		reflect: {
			get() {
				return this.strength.reflect
			},
			set(val) {
				const strengthCopy = _.cloneDeep(this.strength)
				strengthCopy.reflect = val
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
			}
		},
		engagement: {
			get() {
				return this.strength.engagement
			},
			set(val) {
				const strengthCopy = _.cloneDeep(this.strength)
				strengthCopy.engagement = val
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
			}
		},
		evidence() {
			if (!this.strength.evidence) return []
			if (this.strength.evidence.length <= 0) return [{}]
			return this.strength.evidence
		},
		isDialog: {
			get() {
				return this.mode === "dialog"
			},
			set(val) {
				this.mode = val ? "dialog" : "add"
			},
		},
		wontRespond: {
			get() {
				return this.strength.wontRespond
			},
			set(val) {
				const strengthCopy = _.cloneDeep(this.strength)
				strengthCopy.wontRespond = !!val
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
			},
		},
	},
	methods: {
		async handleEvidenceChange(index, newValue) {
			this.dirty()
			const strengthCopy = _.cloneDeep(this.strength)
			if (strengthCopy.evidence?.length === 0) {
				strengthCopy.evidence.push({})
			}
			strengthCopy.evidence[index].evidence = newValue
			if (newValue === undefined || (Array.isArray(newValue) && newValue.length === 0)) {
				delete strengthCopy.evidence?.[index]?.evidence
				delete strengthCopy.evidence?.[index]?.old_id
				delete strengthCopy.evidence?.[index]?.id
			}

			let evidence = []
				if (strengthCopy.evidence?.[index]?.evidence && strengthCopy.evidence?.[index]?.evidence?.length === 1) {
					evidence.push({
						old_id: strengthCopy.evidence[index].old_id,
						id: strengthCopy.evidence[index].id,
						file: strengthCopy.evidence[index].evidence[0],
						centre_id: this.centreId,
						comment: strengthCopy.evidence[index].comments,
					})
				}

			for (let i in evidence) {
				let ev = evidence[i]
				if (ev.id && ev.id.length) continue

				// if methods.removeOldEvidence gave this an old_id
				if (ev.old_id && ev.old_id.length) {
					await api.strengths.deleteOldEvidence(ev.old_id)
				}

				try {
					const data = await api.strengths.registerEvidence(ev)
					//if (!data) throw Error("Could not register evidence e270")
					ev.id = data.data.id
					strengthCopy.evidence[index].id = data.data.id
					strengthCopy.evidence[index].old_id = ""
				} catch (reason) {
					strengthCopy.evidence.splice(index, 1)
					console.error(reason)
					this.$store.dispatch("displayApiError", [
						"evidence",
						"upload",
						reason.reason || reason.data?.reason || reason.status || "500",
					])
				}
			}
				const ev = strengthCopy.evidence[index]
				for (let i in ev.evidence) {
					const file = ev.evidence[i]
					strengthCopy.evidence[index].evidence[i] = {
						name: file.name,
						size: file.size,
						type: file.type,
						lastModified: file.lastModified,
					}
				}

			this.commitStrength(strengthCopy)
			this.saveDebounce(strengthCopy)
		},
		handleCommentInput(index, value) {
			const strengthCopy = _.cloneDeep(this.strength)
			if (strengthCopy.evidence.length === 0) {
				strengthCopy.evidence.push({comments: value})
			} else {
				strengthCopy.evidence[index].comments = value
			}
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
		},
		viewEvidence(id) {
			let routeData = this.$router.resolve({
				name: "View Evidence",
				params: { id },
			})
			window.open(routeData.href, "_blank")
		},
		dirty() {
			this.status = 1
			// if (this.clearStatusTimeout != undefined) {
			// 	clearTimeout(this.clearStatusTimeout)
			// }
		},
		handleBack() {
			this.$emit("handleBack")
		},
		clearFields() {
			let tStr = JSON.stringify(this.strength)
			const strengthCopy = _.cloneDeep(this.strength)
			strengthCopy.evidence = [{}]
			strengthCopy.embed = false
			strengthCopy.engagement = false
			strengthCopy.reflect = false
			strengthCopy.evidenceAttached = false
			strengthCopy.exceedingTheme = []
			strengthCopy.summary = ""
			strengthCopy.text = ""
			this.commitStrength(strengthCopy)
			if (tStr !== JSON.stringify(strengthCopy)) {
				this.dirty()
				this.saveDebounce(strengthCopy)
			}
		},
		deleteOldEvidence(i) {
			if (this.evidence[i]) {
				this.evidence[i].old_id = this.evidence[i].id
				this.evidence[i].id = ""
			}
		},
		addMoreEvidence() {
			if (this.evidence.length < 2) {
				const strengthCopy = _.cloneDeep(this.strength)
				if (!strengthCopy.evidence || strengthCopy.evidence.length === 0) {
					strengthCopy.evidence = [{}]
				} else {
					strengthCopy.evidence.push({})
				}
				this.commitStrength(strengthCopy)
				this.dirty()
				this.saveDebounce(strengthCopy)
			}
		},
		async sendToQip(strengthData, shouldCloseAfter = true) {
			this.status = 2
			
			const data = await api.strengths.updateStrength(this.assessmentId, this.strength.id, strengthData);

			if (data) {
				this.$store.commit("setProgress", [
					data.data.IJKL.split(" ").slice(0, 3),
					data.data.strengthProgress,
					data.data.elementProgress,
				])
				// this.$store.commit("updateStrengthData", [
				// 	data.data.IJKL.split(" "),
				// 	data.data.strengthData,
				// ])
				// Sucess status icon
				this.status = 3;
				console.log(`Strength ${data.data.strengthData.id} has been saved`);
				setTimeout(() => {
					// Remove status icon
					this.status = 0;
				}, 1000);
				setTimeout(() => {
					if (shouldCloseAfter) this.handleBack()
				}, 600);
			}
			
			// await api.strengths
			// 	.updateStrength(this.assessmentId, this.strength.id, strengthCopy)
			// 	.then((data) => {
			// 		if (data) {
			// 			console.log("IJKL..." + data.data.IJKL)
			// 			this.$store.commit("setProgress", [
			// 				data.data.IJKL.split(" ").slice(0, 3),
			// 				data.data.strengthProgress,
			// 				data.data.elementProgress,
			// 			])
			// 			this.$store.commit("updateStrengthData", [
			// 				data.data.IJKL.split(" "),
			// 				data.data.strengthData,
			// 			])
			// 			console.log('here status ' + this.status)
			// 			this.status = 3
			// 			this.clearStatusTimeout = setTimeout(() => {
			// 				console.log("status reset to 0")
			// 				this.status = 0
			// 			}, 1000)
			// 			setTimeout(() => {
			// 				console.log("should close after..." + this.shouldCloseAfter)
			// 				console.log('here status ' + this.status)
			// 				if (shouldCloseAfter) this.handleBack()
			// 			}, 600)
			// 		}
			// 	})
			// 	.catch((reason) => {
			// 		console.error(reason)
			// 		this.$store.dispatch("displayApiError", [
			// 			"evidence",
			// 			"upload",
			// 			reason.reason || reason.data?.reason || reason.status,
			// 		])
			// 	})
		},
		checkCanSaveToQip(...e) {
			this.deleteOldEvidence(...e)

			this.saveButtonEnabled =
				this.assessmentId &&
				typeof this.strength.text === "string" &&
				this.strength.text.length <= 500
					? true
					: false

			if (!this.$refs.evidence || !this.$refs.evidence.length > 0) {
				this.saveButtonEnabled = false
			}

			for (let i in this.$refs.evidence) {
				if (
					!this.$refs.evidence[i]?.$refs.input?.validate() ||
					this.$refs.evidence[i]?.$refs.input?.errorBucket?.length > 0
				) {
					this.saveButtonEnabled = false
				}
			}
			return this.saveButtonEnabled
		},
		// trySave() {
		// 	this.checkCanSaveToQip()
		// 	if (this.saveButtonEnabled) this.sendToQip(false)
		// },
		createDebouncedSave() { 
			return _.debounce(async function (strengthData) {
				await this.sendToQip(strengthData);
			}, 2000)
		},
		commitStrength(strengthData) {
			this.$store.commit("updateStrengthData", [
					strengthData.id.split("").map(item => Number(item) - 1),
					strengthData,
				])
		},
	},
	created() {
		this.checkCanSaveToQip()
		// Ensures each instance of this component has its own debounce function, so there's no interference when saving multiple strengths' data within a short timeframe 
		this.saveDebounce = this.createDebouncedSave()
		// this.debouncedSave = debounce(this.trySave, 1500)
	},
}
export default RecordStrength
</script>

<style scoped lang="scss">
@import "../scss/ads.scss";
.justify-right {
	justify-content: right;
}
.spin-me {
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
.vertically-center-me {
	display: flex;
	align-items: center;
}

.save-status-wrapper {
	min-height: 24px;
}
.add-another-btn {
	display: flex;
	align-items: center;
	height: 100%;
	font-weight: 600;
}
.theme--light .add-another-btn a {
	color: #041e42;
}
.theme--light .add-another-btn[disabled],
.theme--light .add-another-btn[disabled] a {
	cursor: unset;
}
.sendtoqip-btn {
	display: flex;
	justify-content: right;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
	color: $ads-dark;
}
:deep(.v-input__control .v-input__slot .v-text-field__slot label.v-label) {
	min-height: 1.1em;
}
:deep(label.v-label.theme--light) {
	color: $ads-dark;
}
h3 {
	color: $ads-navy;
}

:deep(.v-input__control .v-input__slot legend) {
	width: 6.3em !important;
}
/* replicate .v-label--active permanently */
:deep(.v-input__control .v-input__slot .v-text-field__slot label.v-label) {
	@extend .v-label--active;
	transform: translateY(-24px) scale(0.75);
	font-size: 1.25rem !important;
	padding-top: 2px;
}

:deep(.v-text-field__slot .v-label.v-label--active.theme--light) {
	font-size: 20px;
	font-weight: 500;
	color: $ads-navy;
	padding-left: 2px;
	padding-right: 2px;
}
:deep(.v-input--is-dirty),
:deep(.v-input--is-focused) {
	.v-input__control .v-input__slot legend {
		min-width: 96px;
	}
}

.v-application .error--text {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(div.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(label.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(button.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(i.error--text) {
	color: $ads-error-red !important;
	caret-color: $ads-error-red !important;
}

:deep(.v-counter) {
	padding-top: 2px;
}
</style>
