<template>
	<v-row class="flexcenter">
		<v-card width="50%" class="py-6 px-6">
			<div class="flexcenter mb-4">
				<FeatureIcon
					size="64"
					color="navy"
					background-color="light blue"
					icon="warning_amber"
				/>
			</div>
			<h3 width="100%">{{ title }}</h3>
			<div class="subtitle-1" width="100%">{{ subtitle }}</div>
		</v-card>
	</v-row>
</template>

<script>
import { FeatureIcon } from "@nswdoe/doe-ui-core"

const NothingFoundCard = {
	name: "NothingFoundCard",
	props: {
		title: {
			type: String,
			default: "Oops! We couldn't find anything!",
		},
		subtitle: {
			type: String,
			default: "",
		},
	},
	computed: {},
	methods: {},
	watch: {},
	components: { FeatureIcon },
}
export default NothingFoundCard
</script>

<style lang="scss" scoped>
.flexcenter {
	justify-content: center;
	text-align: center;
	display: flex;
	flex-flow: wrap;
}
</style>
