<template>
	<v-card-text class="px-6">
		<template v-if="markdown">
			<vue-simple-markdown :source="markdown" />
		</template>
		<template v-else-if="loading">
			<v-skeleton-loader type="article" />
		</template>
		<template v-else>
			<h4 class="pt-5">
				Unfortunately, we couldn't find any guide for this standard. Please try
				again later.
			</h4>
		</template>
	</v-card-text>
</template>

<script>
const GuideMarkdownRenderer = {
	name: "GuideMarkdownRenderer",
	components: {},
	props: {
		markdown: {
			type: String,
			default: "",
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {},
}
export default GuideMarkdownRenderer
</script>

<style scoped lang="scss">

::v-deep .vue-simple-markdown.markdown-body {
	line-height: 24px;
	color: $ads-grey-01;
	h3 {
		margin-top: 5px;
		margin-bottom: 5px;
		color: $ads-secondary-blue;
	}
	h4 {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	a {
		color: $ads-secondary-blue;
	}
	ul li {
		margin-bottom: 16px;
	}
	hr {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	ul {
		margin-bottom: 5px;
	}
}
</style>
