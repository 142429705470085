/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import axios from "axios";
import Vue from "vue"; // Import Vue
import CONFIG from "../utils/authConfig";

export async function refreshAuthToken() {
  console.debug("refreshing existing token");

  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  const clientId = CONFIG.VUE_APP_OAUTH_CLIENT_ID;
  const tokenEndpoint = CONFIG.VUE_APP_OAUTH_TOKEN_URL;

  try {
    const requestBody = new URLSearchParams();
    requestBody.append("client_id", clientId);
    requestBody.append("grant_type", "refresh_token");
    requestBody.append("refresh_token", refreshToken);

    const response = await axios.post(tokenEndpoint, requestBody.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    console.debug("received a response for a new token");
    const jwtParsed = parseJwt(response.data.id_token);

    const { access_token, refresh_token, token_type, expires_in } =
      response.data;
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("token_type", token_type);
    localStorage.setItem("token_expiry", jwtParsed.exp);
    localStorage.setItem(
      "expires_in",
      Math.floor(Date.now() / 1000) + expires_in
    );

    sessionStorage.setItem("Profile", jwtParsed);

    // Optionally, update the OAuth plugin data if needed
    if (Vue.prototype.$OAuth) {
      console.log("update OAuth plugin data");
      Vue.prototype.$OAuth.data.accessToken = access_token;
      Vue.prototype.$OAuth.data.refreshToken = refresh_token;
      Vue.prototype.$OAuth.data.tokenType = token_type;
      Vue.prototype.$OAuth.data.profile = jwtParsed;
      Vue.prototype.$OAuth.data.tokenExpiry =
        Math.floor(Date.now() / 1000) + expires_in;

      console.log("Calling setToken to update OAuth plugin data");
      Vue.prototype.$OAuth.setToken(
        access_token,
        response.data.id_token,
        token_type,
        refresh_token
      );

      console.debug(
        "successfully updated application state with the new token"
      );
      // Vue.prototype.$OAuth.data.profile.exp = Math.floor(Date.now() / 1000) + expires_in;
    }

    sessionStorage.setItem("oauth-session-initialised", true);

    console.log("Token refreshed successfully");
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Failed to refresh token", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }
    console.error("Failed to refresh token", error);
    throw error;
  }
}

function parseJwt(jwt) {
  if (!jwt) {
    return "";
  }

  var base64Url = jwt.split(".")[1];
  var base64 = decodeURIComponent(
    atob(base64Url.replace(/-/g, "+").replace(/_/g, "/"))
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(base64);
}
