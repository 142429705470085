export default {
	lastUpdated: "Thu Dec 14 2023 01:08:34 GMT+0000 (Coordinated Universal Time)",
	centre: { name: "ATP qa PS Test School", id: "9079", group: false },
	philosophyStatement: "Philosophy Statement",
	assessment_id: "c3cfe76d-8967-40eb-bc79-86f23ac07ec7",
	progress: 0,
	term: 1,
	cid: "9079",
	qualityAreas: [
		{
			checklist: {
				puE4Y2: true,
				puE4Y1: true,
				tHatn9: true,
				"1CkHW2": true,
				"1CkHW1": true,
				kQMT41: true,
				kQMT42: true,
				vLMx1c: true,
			},
			name: "epp",
			number: "1",
			standards: [
				{
					elements: [
						{
							name: "alf",
							number: "1.1.1",
							progress: 2,
							strengths: [
								{
									summary: "Test 123",
									exceedingTheme: ["Embed"],
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1111",
									text: "Test 123",
									embed: true,
									updated:
										"Thu Dec 14 2023 01:08:33 GMT+0000 (Coordinated Universal Time)",
								},
								{
									summary: "Test",
									exceedingTheme: [],
									reflect: false,
									engagement: false,
									evidence: [
										{
											old_id: "07ca15ea-920e-412a-89f1-06bee5b92304",
											comments: " ",
											id: "",
											evidence: [],
										},
										{
											old_id: "4e743a55-a1b8-461e-a7b7-cc4da9daac4e",
											id: "",
											comments: " ",
											evidence: [],
										},
									],
									evidenceAttached: false,
									id: "1112",
									text: "Our curriculum is included for individuals and groups of children and linked to our service philosophy. We achieve this by having individual pre-enrolment meetings where families talk to us about their priorities for their child and use this to guide initial and ongoing programs.  As a result, the program is differentiated and reflects our philosophy focused on child centered curriculum. ",
									embed: false,
									updated:
										"Tue Oct 24 2023 23:03:15 GMT+0000 (Coordinated Universal Time)",
								},
								{
									summary: "•\tAs a team, we critically ref...",
									exceedingTheme: [],
									reflect: false,
									engagement: false,
									evidence: [
										{
											old_id: "",
											comments: "describe evidence",
											id: "10ccb899-b295-4495-81a1-4b6590c69c90",
											evidence: [
												{
													name: "test evidence SAaP.docx",
													lastModified: 1697413233000,
													size: 12875,
													type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
												},
											],
										},
										{
											old_id: "",
											id: "02e50c03-882b-4268-bc00-0bd6db6778a3",
											comments: "test",
											evidence: [
												{
													name: "test evidence SAaP (3).docx",
													lastModified: 1698788323064,
													size: 12875,
													type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
												},
											],
										},
									],
									evidenceAttached: true,
									id: "1113",
									text: "•\tAs a team, we critically reflect how children use play to investigate, imagine and explore their ideas. We support children to develop and explore their identities through role play. Interest areas within the environment like doctor’s surgery, home corner and supermarkets allow children to experience different roles.",
									embed: false,
									updated:
										"Thu Dec 14 2023 01:08:04 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1114",
									text: "Test",
									embed: false,
									updated:
										"Thu Apr 20 2023 02:17:41 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }, {}],
									evidenceAttached: false,
									id: "1115",
									text: "We use the Early Years Learning Framework (EYLF) to implement and guide learning program for children. This is evident within the weekly programming, observations, learning stories, the children’s ‘Celebration of Learning’ portfolios and individual and team critical reflections. Informal meetings take place regularly between educators and families to discuss their child’s interests and progress in preparation for formal schooling.",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:40:44 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: true,
									evidence: [
										{
											evidence: null,
											comments:
												"E3 - promote connections between learning environments",
										},
									],
									evidenceAttached: false,
									id: "1116",
									text: "We encourage our families to engage with us on a range of platforms (verbally, emails, Educa, Dojo) to share their perspective and feedback to inform curriculum decisions. Families contribute interests and home adventures on Dojo allowing us to follow up and explore more at preschool.",
									embed: false,
									updated:
										"Tue Aug 01 2023 06:10:06 GMT+0000 (Coordinated Universal Time)",
								},
							],
						},
						{
							name: "chce",
							number: "1.1.2",
							progress: 2,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1121",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:45:13 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "Annotation" }, {}],
									evidenceAttached: false,
									id: "1122",
									text: "Example",
									embed: true,
									updated:
										"Thu May 04 2023 00:56:28 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1123",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:42:36 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1124",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:42:43 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1125",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:42:46 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1126",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:43:33 GMT+0000 (Coordinated Universal Time)",
								},
							],
						},
						{
							name: "plo",
							number: "1.1.3",
							progress: 2,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1131",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:46:28 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1132",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:46:30 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1133",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:46:37 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1134",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:46:41 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1135",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:46:47 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1136",
									text: " ",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:50:08 GMT+0000 (Coordinated Universal Time)",
								},
							],
						},
					],
					name: "program",
					number: "1.1",
					progress: 2,
				},
				{
					elements: [
						{
							name: "inttea",
							number: "1.2.1",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }, {}],
									evidenceAttached: false,
									id: "1211",
									text: "Key practice statement",
									embed: true,
									updated:
										"Mon Apr 24 2023 05:48:10 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "rts",
							number: "1.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1226",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "cdl",
							number: "1.2.3",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1231",
									text: "Test",
									embed: false,
									updated:
										"Thu May 04 2023 01:07:57 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1232",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1233",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1234",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1235",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1236",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "prac",
					number: "1.2",
					progress: 1,
				},
				{
					elements: [
						{
							name: "apc",
							number: "1.3.1",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1311",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1312",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1313",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1314",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1315",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1316",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "cf",
							number: "1.3.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1321",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1322",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1323",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1324",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1325",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1326",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "iff",
							number: "1.3.3",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "1331",
									text: "Test to see if it remains test",
									embed: false,
									updated:
										"Wed Apr 19 2023 04:50:44 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1332",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1333",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1334",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1335",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "1336",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "aap",
					number: "1.3",
					progress: 1,
				},
			],
		},
		{
			checklist: {
				xNiu01: true,
				NSPCw2: true,
				NSPCw1: true,
				xNiu02: true,
				xNiu03: true,
				jpCmTJ: false,
				"2RTq7k": true,
				BJFive: false,
				DqFYA3: false,
				DqFYA2: true,
				DqFYA1: true,
				I2plKx: true,
				oxiCIG: true,
				BakaH1: true,
				"483iQ6": false,
				BakaH2: true,
				gIIFa_: false,
				KfTmUy: true,
				r4twZW: false,
				XL8WW2: true,
				Tgrvd1: false,
				Tgrvd2: false,
				obXFlq: true,
			},
			name: "chs",
			number: "2",
			standards: [
				{
					elements: [
						{
							name: "wellbeing",
							number: "2.1.1",
							progress: 1,
							strengths: [
								{
									summary: "•\tThe program allows opportuni...",
									exceedingTheme: ["Meaningful engagement"],
									reflect: false,
									engagement: true,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "2111",
									text: "•\tThe program allows opportunities for children to be involved in relaxation and rest experiences such as yoga/stories/soft music. We critically reflect on these opportunities, to ensure that we are sensitive to family’s cultural beliefs. For example, one family was concerned about their child participating in yoga activities due to their belief system around yoga bringing out negative spirits. As a result, we ensured that alternative activities were offered to her. ",
									embed: false,
									updated:
										"Wed Sep 20 2023 23:18:30 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2112",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2113",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2114",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2115",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2116",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "practices",
							number: "2.1.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2121",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2122",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2123",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2124",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2125",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2126",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "lifestyle",
							number: "2.1.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2131",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2132",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2133",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2134",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2135",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2136",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "health",
					number: "2.1",
					progress: 1,
				},
				{
					elements: [
						{
							name: "supervis",
							number: "2.2.1",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "test evidence" }],
									evidenceAttached: false,
									id: "2211",
									text: "test",
									embed: false,
									updated:
										"Tue Oct 31 2023 21:39:05 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "iaem",
							number: "2.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2226",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "childpro",
							number: "2.2.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2231",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2232",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2233",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2234",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2235",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "2236",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "safety",
					number: "2.2",
					progress: 1,
				},
			],
		},
		{
			checklist: {
				qpz9in: false,
				Pa9mL7: false,
				Pa9mL8: false,
				Pa9mL5: false,
				Pa9mL6: false,
				Pa9mL3: false,
				Pa9mL4: false,
				"7nsgvm": false,
				Pa9mL1: false,
				Pa9mL2: false,
			},
			name: "physenv",
			number: "3",
			standards: [
				{
					elements: [
						{
							name: "ffp",
							number: "3.1.1",
							progress: 1,
							strengths: [
								{
									reflect: true,
									engagement: true,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "3111",
									text: "a",
									embed: true,
									updated:
										"Mon May 08 2023 04:18:13 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: true,
									engagement: true,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "3112",
									text: "b",
									embed: true,
									updated:
										"Mon May 08 2023 04:18:17 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: true,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "3113",
									text: "c",
									embed: true,
									updated:
										"Mon May 08 2023 04:18:23 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3114",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3115",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3116",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "upkeep",
							number: "3.1.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3121",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3122",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3123",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3124",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3125",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3126",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "design",
					number: "3.1",
					progress: 1,
				},
				{
					name: "use",
					number: "3.2",
					progress: 0,
					elements: [
						{
							name: "inclenv",
							number: "3.2.1",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3211",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "rspbl",
							number: "3.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3226",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "enviroresp",
							number: "3.2.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3231",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3232",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3233",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3234",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3235",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "3236",
									text: "",
									embed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			checklist: {
				qLl2A5: false,
				"85NYsU": false,
				KzN2w2: false,
				KzN2w3: false,
				NWLRGU: true,
				KzN2w1: true,
				tvU20v: false,
			},
			name: "staffarr",
			number: "4",
			standards: [
				{
					elements: [
						{
							name: "ooe",
							number: "4.1.1",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "4111",
									text: "test",
									embed: false,
									updated:
										"Thu Sep 21 2023 03:36:08 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4112",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4113",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4114",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4115",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4116",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "cos",
							number: "4.1.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4121",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4122",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4123",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4124",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4125",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4126",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "staffarrange",
					number: "4.1",
					progress: 1,
				},
				{
					elements: [
						{
							name: "profcoll",
							number: "4.2.1",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [
										{
											old_id: "",
											comments: "asdf1",
											id: "4bd1bc36-5e5e-469d-818f-c4fbbb90a565",
											evidence: [
												{
													name: "compliance.md",
													lastModified: 1683515618276,
													size: 471,
													type: "text/markdown",
												},
											],
										},
									],
									evidenceAttached: true,
									id: "4211",
									text: "asdf1",
									embed: true,
									updated:
										"Mon May 08 2023 04:02:59 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "profstan",
							number: "4.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "4226",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "profess",
					number: "4.2",
					progress: 1,
				},
			],
		},
		{
			checklist: {
				BGrwxZ: false,
				BGrwx6: false,
				BGrwx3: false,
				BGrwx2: false,
				BGrwx5: false,
				v_BuG7: false,
				BGrwx4: false,
				BGrwx1: false,
			},
			name: "rwc",
			number: "5",
			standards: [
				{
					elements: [
						{
							name: "petci",
							number: "5.1.1",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "5111",
									text: "Key practice statement",
									embed: false,
									updated:
										"Thu May 11 2023 10:01:50 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "5112",
									text: "Test",
									embed: false,
									updated:
										"Thu May 11 2023 10:01:48 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "5113",
									text: "Check for spaces",
									embed: false,
									updated:
										"Thu May 11 2023 10:01:48 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5114",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5115",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5116",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "darotc",
							number: "5.1.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5121",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5122",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5123",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5124",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5125",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5126",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5127",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "rbeac",
					number: "5.1",
					progress: 1,
				},
				{
					name: "rbc",
					number: "5.2",
					progress: 0,
					elements: [
						{
							name: "collablearn",
							number: "5.2.1",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5211",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "selfreg",
							number: "5.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "5226",
									text: "",
									embed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			checklist: { NYfHxi: false },
			name: "cpwfac",
			number: "6",
			standards: [
				{
					elements: [
						{
							name: "ewts",
							number: "6.1.1",
							progress: 2,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6111",
									text: "test ",
									embed: false,
									updated:
										"Tue Sep 19 2023 02:30:46 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6112",
									text: "test",
									embed: false,
									updated:
										"Tue Sep 19 2023 02:32:47 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6113",
									text: "test",
									embed: false,
									updated:
										"Tue Sep 19 2023 02:32:40 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6114",
									text: "test",
									embed: false,
									updated:
										"Tue Sep 19 2023 02:32:37 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6115",
									text: "test",
									embed: false,
									updated:
										"Tue Sep 19 2023 02:33:04 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6116",
									text: "test",
									embed: false,
									updated:
										"Tue Sep 19 2023 02:32:32 GMT+0000 (Coordinated Universal Time)",
								},
							],
						},
						{
							name: "pvar",
							number: "6.1.2",
							progress: 1,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6121",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [{ evidence: null, comments: "" }],
									evidenceAttached: false,
									id: "6122",
									text: "x",
									embed: false,
									updated:
										"Tue Sep 19 2023 03:23:50 GMT+0000 (Coordinated Universal Time)",
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6123",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6124",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6125",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6126",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "fas",
							number: "6.1.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6131",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6132",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6133",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6134",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6135",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6136",
									text: "",
									embed: false,
								},
							],
						},
					],
					name: "srwf",
					number: "6.1",
					progress: 1,
				},
				{
					name: "collabpart",
					number: "6.2",
					progress: 0,
					elements: [
						{
							name: "trans",
							number: "6.2.1",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6211",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "accpart",
							number: "6.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6226",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "comeng",
							number: "6.2.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6231",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6232",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6233",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6234",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6235",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "6236",
									text: "",
									embed: false,
								},
							],
						},
					],
				},
			],
		},
		{
			name: "gal",
			standards: [
				{
					name: "govern",
					number: "7.1",
					progress: 0,
					elements: [
						{
							name: "spap",
							number: "7.1.1",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7111",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7112",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7113",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7114",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7115",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7116",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "managesys",
							number: "7.1.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7121",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7122",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7123",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7124",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7125",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7126",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "rolesandres",
							number: "7.1.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7131",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7132",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7133",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7134",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7135",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7136",
									text: "",
									embed: false,
								},
							],
						},
					],
				},
				{
					name: "leadership",
					number: "7.2",
					progress: 0,
					elements: [
						{
							name: "contimprov",
							number: "7.2.1",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7211",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7212",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7213",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7214",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7215",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7216",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "edulead",
							number: "7.2.2",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7221",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7222",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7223",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7224",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7225",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7226",
									text: "",
									embed: false,
								},
							],
						},
						{
							name: "devofprof",
							number: "7.2.3",
							progress: 0,
							strengths: [
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7231",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7232",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7233",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7234",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7235",
									text: "",
									embed: false,
								},
								{
									reflect: false,
									engagement: false,
									evidence: [],
									evidenceAttached: false,
									id: "7236",
									text: "",
									embed: false,
								},
							],
						},
					],
				},
			],
			number: "7",
			checklist: {
				"0iKEjJ": false,
				EEADYA: false,
				V2vLh1: false,
				xxbZd2: false,
				xxbZd3: false,
				xxbZd1: false,
				VvBLh1: false,
				xw1Wrg: false,
				Inopqp: false,
				y3Habn: false,
				JKhs9z: false,
				InHlTE: false,
				lHVnB_: false,
				QBiksV: false,
				AcOloM: false,
			},
		},
	],
}
