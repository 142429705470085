import api from "../../api"
//import handleStatus from "../../utils/apiError"

const guidesModule = {
	state: {
		guides: {
			standard: {
				1.1: false,
				1.2: false,
				1.3: false,
				2.1: false,
				2.2: false,
				3.1: false,
				3.2: false,
				4.1: false,
				4.2: false,
				5.1: false,
				5.2: false,
				6.1: false,
				6.2: false,
				7.1: false,
				7.2: false,
			},
			qualityArea: {},
			help: {
				index: {},
			},
		},
		selectedGuide: ["", ""],
		loading: {
			selectedGuide: false,
		},
		previouslySeenGuides: {
			standard: {
				1.1: false,
				1.2: false,
				1.3: false,
				2.1: false,
				2.2: false,
				3.1: false,
				3.2: false,
				4.1: false,
				4.2: false,
				5.1: false,
				5.2: false,
				6.1: false,
				6.2: false,
				7.1: false,
				7.2: false,
			},
			qualityArea: {},
			help: {
				index: {},
			},
		},
	},
	getters: {
		selectedGuide: (state) => {
			if (!state.selectedGuide[0] || !state.selectedGuide[1]) return false
			return state.guides[state.selectedGuide[0]][state.selectedGuide[1]]
		},
		hasSelectedGuide: (state) => {
			if (!state.selectedGuide[0] || !state.selectedGuide[1]) return false
			return (
				Object.keys(
					state.guides[state.selectedGuide[0]][state.selectedGuide[1]],
				)?.length > 0
			)
		},
		selectedGuideIsLoading: (state) => state.loading.selectedGuide,
	},
	mutations: {
		setPreviouslySeenGuides(state, guides) {
			state.previouslySeenGuides = guides
		},
		setSelectedGuideSeen(state, seen = true) {
			if (typeof seen != "boolean")
				return console.error("seen must be a boolean value")
			if (!state.selectedGuide[0] || !state.selectedGuide[1]) return false

			state.previouslySeenGuides[state.selectedGuide[0]][
				state.selectedGuide[1]
			] = seen
			localStorage.setItem(
				"seenGuides",
				JSON.stringify(state.previouslySeenGuides),
			)
		},
		selectGuide(state, [guideType, guide]) {
			if (state.guides[guideType] === undefined)
				return console.error("Guide type", guideType, "doesn't exist")
			if (state.guides[guideType][guide] === undefined)
				return console.error("Guide", guideType, ":", guide, "is missing")

			state.selectedGuide = [guideType, guide]
		},
		setGuideData(state, [data, guideType, guide]) {
			if (!data || !guideType || !guide)
				return console.error(
					"must set guide data with data, guidetype, and guide",
				)

			state.guides[guideType][guide] = data
		},
		setIsLoading(state, [field, value = true]) {
			state.loading[field] = value
		},
	},
	actions: {
		loadPreviouslySeenGuides({ commit }) {
			let guides = JSON.parse(localStorage.getItem("seenGuides"))
			if (!guides) return false

			commit("setPreviouslySeenGuides", guides)
		},
		async getSelectedGuide({ dispatch, commit, state }) {
			if (!state.selectedGuide[0] || !state.selectedGuide[1])
				return console.error("Must have selected guide")

			return new Promise((resolve, reject) => {
				commit("setIsLoading", ["selectedGuide", true])
				api.guides
					.fetchGuide(state.selectedGuide[0], state.selectedGuide[1])
					.then((data) => {
						commit("setGuideData", [
							data,
							state.selectedGuide[0],
							state.selectedGuide[1],
						])
						resolve()
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"guidance for this page",
							"fetch",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["selectedGuide", false])
					})
			})
		},
	},
}
export default guidesModule
