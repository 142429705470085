export default {
	name: "Educational program and practice",
	short: "epp",
	number: "1",
	children: {
		standards: [
			{
				name: "1.1 - Program",
				short: "program",
				number: "1.1",
				subtitle:
					"The educational program enhances each child’s learning and development.",
				blurb:
					"#### What does the outcome of this standard look like for children, families and communities?\n        Educators use their in-depth knowledge of each child and the Early Years Learning Framework to guide curriculum decision making that leads to improved outcomes for children. Opportunities for teaching and learning are recognised and capitalised on throughout the day during interactions, experiences, routines and events, with a clear emphasis on learning throughout the program, rather than other priorities. ",
				checklist: [
					{
						source: "(S.168)",
						title:
							"Is the early years learning framework used to guide the development of the program?",
						id: "tHatn9",
					},
					{
						source: "(R.73)",
						title:
							"Have you developed a program that contributes to each child’s learning and development outcomes, as outlined by the learning framework? ",
						id: "vLMx1c",
					},
				],
				children: {
					standards: [
						{
							name: "1.1.1 - Approved learning framework",
							short: "alf",
							number: "1.1.1",
							subtitle:
								"Curriculum decision-making contributes to each child’s learning and development outcomes in relation to their identity, connection with community, wellbeing, confidence as learners and effectiveness as communicators.",
							children: {
								strengths: [
									{
										id: "1111",
										text: "How does your service’s use of the Early Years Learning Framework support all children to progress towards the learning outcomes? How is it informing decisions you make about children’s learning?",
									},
									{
										id: "1112",
										text: "What makes up your curriculum and how do you decide what is included for individuals and groups of children? How does this link to your service philosophy?",
									},
									{
										id: "1113",
										text: "What processes do you have in place to collect evidence of children demonstrating learning across all five learning outcomes, the key components and examples within?  ",
									},
									{
										id: "1114",
										text: "How do you use the teaching strategies that are listed in the EYLF to foster children’s learning and how do you decide if what you are doing contributes to children’s learning outcomes?",
									},
									{
										id: "1115",
										text: "How do educators draw and reflect on their in-depth knowledge and understanding of each child in relation to the outcomes of the EYLF?\n",
									},
									{
										id: "1116",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
						{
							name: "1.1.2 - Child-centred",
							short: "chce",
							number: "1.1.2",
							subtitle:
								"Each child’s current knowledge, strengths, ideas, culture, abilities and interests are the foundation of the program. ",
							children: {
								strengths: [
									{
										id: "1121",
										text: "How do you gather information about each child’s knowledge, strengths, ideas, culture and interest and use that to inform curriculum decisions? ",
									},
									{
										id: "1122",
										text: "How do you plan for children’s learning individually and in groups to ensure curriculum decisions are child centred?",
									},
									{
										id: "1123",
										text: "How does your knowledge of each child contribute to planning for their learning?  ",
									},
									{
										id: "1124",
										text: "How do you ensure all children experience a sense of belonging, being and becoming in the service, considering all aspects of a child’s context including background, culture, attendance patterns, family situation, additional needs? ",
									},
									{
										id: "1125",
										text: "How do your programming decisions support all children to actively participate in experiences and guide their own learning? \n",
									},
									{
										id: "1126",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
						{
							name: "1.1.3 - Program learning opportunities",
							short: "plo",
							number: "1.1.3",
							subtitle:
								"All aspects of the program, including routines, are organised in ways that maximize opportunities for each child’s learning.",
							children: {
								strengths: [
									{
										id: "1131",
										text: "How is the program delivery flexible and adaptable to support individual children’s learning? ",
									},
									{
										id: "1132",
										text: "How is learning at the heart of what you are doing and in every part of your program? ",
									},
									{
										id: "1133",
										text: "How are the learning opportunities within the program both planned for and spontaneous to capture learning throughout each day? ",
									},
									{
										id: "1134",
										text: "How is every part of your program considered as an opportunity for learning including transition and routines? ",
									},
									{
										id: "1135",
										text: "How do you make the most of all learning opportunities throughout the day?\n",
									},
									{
										id: "1136",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
					],
				},
			},
			{
				name: "1.2 - Practice",
				short: "prac",
				number: "1.2",
				subtitle:
					"Educators facilitate and extend each child’s learning and development.",
				blurb:
					"#### What does the outcome of this standard look like for children, families and communities?\n        Educators’ decisions and practices are intentional to support, consolidate and extend children’s learning in both planned and spontaneous ways. Thoughtful, deliberate teaching practices occur with learning outcomes for children in mind. Educators are responsive and respectful and engage in high-quality interactions with children.",
				checklist: [],
				children: {
					standards: [
						{
							name: "1.2.1 - Intentional teaching",
							short: "inttea",
							number: "1.2.1",
							subtitle:
								"Educators are deliberate, purposeful, and thoughtful in their decisions and actions.",
							children: {
								strengths: [
									{
										id: "1211",
										text: "How do you make decisions about what to teach and how to teach it? ",
									},
									{
										id: "1212",
										text: "How do the decisions you make about your teaching practices lead to progress towards children’s learning and development outcomes? ",
									},
									{
										id: "1213",
										text: "How do you know that your teaching practices are working and meaningful for children’s learning and development? ",
									},
									{
										id: "1214",
										text: "What strategies do you use when engaging with children to support and extend  thinking and learning? ",
									},
									{
										id: "1215",
										text: "Do you make conscious decisions on intentional teaching strategies based on your professional knowledge of early childhood best practice to promote learning? \n",
									},
									{
										id: "1216",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
						{
							name: "1.2.2 - Responsive teaching and scaffolding",
							short: "rts",
							number: "1.2.2",
							subtitle:
								"Educators respond to children’s ideas and play and extend children’s learning through open-ended questions, interactions and feedback.",
							children: {
								strengths: [
									{
										id: "1221",
										text: "What strategies do educators use to stay in tune with children both individually and in groups to enhance their learning?",
									},
									{
										id: "1222",
										text: "How do you intentionally plan learning experiences which appropriately challenge children to learn through exploration and experimentation?",
									},
									{
										id: "1223",
										text: "How do you make the most of spontaneous teachable moments and draw on children’s response and feedback to further extend their learning?",
									},
									{
										id: "1224",
										text: "How do you use open-ended questioning and sustained shared conversations to enhance children’s learning and development?",
									},
									{
										id: "1225",
										text: "How do you build on spontaneous experiences to plan for follow up-learning?\n",
									},
									{
										id: "1226",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
						{
							name: "1.2.3 - Child directed learning",
							short: "cdl",
							number: "1.2.3",
							subtitle:
								"Each child's agency is promoted, enabling them to make choices and decisions that influence events and their world.",
							children: {
								strengths: [
									{
										id: "1231",
										text: "What is your view of children both individually and as a group of educators and how does this influence the agency they are given?",
									},
									{
										id: "1232",
										text: "How are children’s voices evident in the learning program and teaching and learning decisions that are made on a daily basis?",
									},
									{
										id: "1233",
										text: "What is your approach to providing children with choice and an ability to influence decisions that affect them both at the service and in the wider world?",
									},
									{
										id: "1234",
										text: "How do children drive their own learning? How do children make decisions about, plan for and input into planning and resources?",
									},
									{
										id: "1235",
										text: "How would you describe your services’ approach to child directed learning and can you provide examples of this in practice and how it leads to more meaningful outcomes for children?\n",
									},
									{
										id: "1236",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
					],
				},
			},
			{
				name: "1.3 - Assessment and planning",
				short: "aap",
				number: "1.3",
				subtitle:
					"Educators and coordinators take a planned and reflective approach to implementing the program for each child. ",
				blurb:
					"#### What does the outcome of this standard look like for children, families and communities?\n        Educators’ practice is based on age-appropriate approaches to assessment and learning. Planning for learning and teaching is an ongoing cyclical process and includes an individual and group focus. Critical reflection on practices and planned experiences informs improved approaches. Families input into what children know and understand and in setting goals for learning is actively sort. The information provided to families is focused on learning, effectiveness of teaching and planned experiences and shows growth over time.",
				checklist: [
					{
						source: "(R.74)",
						title:
							"Do you document an assessment of each child’s development, interests and participation in the program?",
						id: "1CkHW1",
					},
					{
						source: "(R.74)",
						title:
							"Do you document an assessment of each child’s progress towards the program outcomes?",
						id: "1CkHW2",
					},
					{
						source: "(R.75)",
						title:
							"Is the information about the program displayed in a place at the service that is accessible to parents?",
						id: "puE4Y1",
					},
					{
						source: "(R.75)",
						title:
							"Is evidence of the program available at the service for inspection on request?",
						id: "puE4Y2",
					},
					{
						source: "(R.76)",
						title:
							"If requested, do you provide families with Information about the content of the program and service routines and how they operate in relation to their children, including their participation? ",
						id: "kQMT41",
					},
					{
						source: "(R.76)",
						title:
							" If requested, do you provide families with copy of their children’s assessment/evaluation documentation?",
						id: "kQMT42",
					},
				],
				children: {
					standards: [
						{
							name: "1.3.1 - Assessment and planning cycle",
							short: "apc",
							number: "1.3.1",
							subtitle:
								"Each child’s learning and development is assessed or evaluated as part of an ongoing cycle of observation, analysing learning, documentation, planning, implementation and reflection.",
							children: {
								strengths: [
									{
										id: "1311",
										text: "How do you gather and analyse information as evidence of what children know, can do and understand in relation to the learning outcomes?",
									},
									{
										id: "1312",
										text: "How do you use the assessment and planning cycle to inform planning for children’s learning?",
									},
									{
										id: "1313",
										text: "How do you decide how to document and plan for children’s learning as a team of educators?",
									},
									{
										id: "1314",
										text: "How does your approach to documentation make children’s learning visible?",
									},
									{
										id: "1315",
										text: "How do you ensure your planned program reflects the service statement of philosophy and is implemented and reflected upon as a continuing cycle of learning and teaching?\n",
									},
									{
										id: "1316",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
						{
							name: "1.3.2 - Critical reflection",
							short: "cf",
							number: "1.3.2",
							subtitle:
								"Critical reflection on children’s learning and development, both as individuals and in groups, drives program planning and implementation. ",
							children: {
								strengths: [
									{
										id: "1321",
										text: "How do you engage in critical reflection on all aspects of the program, considering your decision making, service philosophy, individual educators’ knowledge and experience and the context of the children and families you are working with?",
									},
									{
										id: "1322",
										text: "How is your approach to critical reflection as educators ongoing and dynamic? How do you ensure different perspectives are included and that the status quo is challenged?",
									},
									{
										id: "1323",
										text: "How do you document critical reflection and when is this revisited, used or drawn on to inform future decision making around all aspects of professional practice, including programming for children’s learning?",
									},
									{
										id: "1324",
										text: "How do your critical reflection practices maintain a focus on outcomes for children and families? Consider how you identify ways to improve opportunities for children’s participation, learning and development.",
									},
									{
										id: "1325",
										text: "What professional standards or guidance do you draw on to inform your reflective practice?\n",
									},
									{
										id: "1326",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
						{
							name: "1.3.3 - Information for families",
							short: "iff",
							number: "1.3.3",
							subtitle:
								"Families are informed about the program and their child's progress.",
							children: {
								strengths: [
									{
										id: "1331",
										text: "What information do you share with families about their child’s learning and development, and progress towards the learning outcomes? How is this achieved?",
									},
									{
										id: "1332",
										text: "How do you seek and act upon information from families about their child’s knowledge, skills and capabilities and what goals they have for their child’s learning?",
									},
									{
										id: "1333",
										text: "How do you demonstrate children’s participation in the program to families? How does this reflect your approach to ensuring equity in planning and learning across the group?",
									},
									{
										id: "1334",
										text: "How do you display the educational program and make learning visible to families?",
									},
									{
										id: "1335",
										text: "How do you make sure that families understand their child’s progress including strengths and focus areas, as well as any concerns? How do you alter this message and approach for different families?\n",
									},
									{
										id: "1336",
										text: "Do you have an additional strength statement for this element?",
									},
								],
							},
						},
					],
				},
			},
		],
	},
}
