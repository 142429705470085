<template>
	<div>
		<AssessmentBanner
			:header="selectedQualityArea.name"
			:subheader="`Quality Area ${
				selectedQualityArea.number ? selectedQualityArea.number : '?'
			}`"
			:loading="loading"
			:progress="progress"
		/>
		<TabNavigation
			v-model="tab"
			:items="tabs"
			:center-active="true"
			class="custom-tab"
		>
			<template #content1>
				<v-container
					:class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'"
				>
					<v-row class="my-6" :no-gutters="true">
						<div style="display: flex; align-items: center" @click="goBack()">
							<v-icon class="mr-2">arrow_back</v-icon>
							<a :align="'center'" style="color: #041e42">Back</a>
						</div>
					</v-row>
					<ChildSelection :quality-area-short="$route.params.qualityArea" />
				</v-container>
			</template>
			<template #content2>
				<IdentifiedIssues @goBack="goBack()" />
			</template>
			<template #content3>
				<ImprovementPlan @goBack="goBack()" />
			</template>
			<template #content4>
				<v-container
					:class="$vuetify.breakpoint.xsOnly ? 'px-0 mobile' : 'px-10'"
				>
					<v-row class="my-6" :no-gutters="true">
						<div style="display: flex; align-items: center" @click="goBack()">
							<v-icon class="mr-2">arrow_back</v-icon>
							<a :align="'center'" style="color: #041e42">Back</a>
						</div>
					</v-row>
					<compliance-checklist />
				</v-container>
			</template>
		</TabNavigation>
	</div>
</template>

<script>
import { AdsButton, AdsDataTable, Alert } from "@nswdoe/doe-ui-core"
import {
	AssessmentBanner,
	ChildSelection,
	ComplianceChecklist,
	ImprovementPlan,
	IdentifiedIssues,
	TabNavigation,
} from "../components"

const qualityArea = {
	title: "Quality Area",
	name: "qualityArea",
	created() {
		if (typeof this.$route.query?.tab != "undefined") {
			this.tab = this.$route.query.tab / 1
		}
	},
	computed: {
		selectedQualityArea() {
			return this.$store.getters.selectedQualityArea
		},
		selectedAssessment() {
			return this.$store.getters.selectedAssessment
		},
		loading() {
			return (
				this.$store.getters.qualityAreasAreLoading ||
				this.$store.getters.assessmentIsLoading
			)
		},
		progress() {
			if (this.loading) return 0

			let els = this.selectedAssessment.qualityAreas.find(
				(v) => v.name == this.selectedQualityArea.short,
			)?.standards
			if (!els) return -1

			let total = 0
			for (let standard of els) {
				if (standard.progress > -1) total += standard.progress
			}
			return (total / (els.length * 2)) * 100
		},
	},
	data() {
		return {
			tabs: [
				{ id: "1", tab: "SELF-ASSESSMENT" },
				{ id: "2", tab: "IDENTIFIED ISSUES" },
				{ id: "3", tab: "IMPROVEMENT PLAN" },
				{ id: "4", tab: "COMPLIANCE" },
			],
			tab: 4,
		}
	},
	methods: {
		goBack() {
			this.$router.push({
				name: "Self-Assessment",
				params: {},
			})
		},
	},
	components: {
		AdsButton,
		AdsDataTable,
		ChildSelection,
		AssessmentBanner,
		TabNavigation,
		Alert,
		ComplianceChecklist,
		ImprovementPlan,
		IdentifiedIssues,
	},
}

export default qualityArea
</script>

<style lang="scss" scoped>
@import "../scss/ads.scss";
@import "../scss/custom-tab.scss";
@import "../scss/invert-banner.scss";

#main-content {
	padding-bottom: 0px !important;
	::v-deep div.pl-0.col {
		padding-top: 0px !important;
	}
}
::v-deep .searchInput {
	display: none;
}
::v-deep .embeddedalert .v-alert.v-sheet {
	background: #e0e9ff;
	box-shadow: none !important;
}
</style>
