<template>
	<ErrorPage
		:title="title"
		:error-code="ERRORCODE"
		:subtitle="subtitle"
		:is404page="false"
		:actions="actions"
	/>
</template>

<script>
import { ErrorPage } from "@nswdoe/doe-ui-core"

const Error401 = {
	title: "401 Error",
	name: "Error401",
	created() {
		this.$store.commit("isLoading", false)
	},
	data() {
		return {}
	},
	computed: {
		ERRORCODE() {
			return this.$route.params.ERRORCODE || "USER-401"
		},
		title() {
			switch (this.ERRORCODE) {
				case "CENTRE-401":
					return "You do not have access to this application."
				case "USER-401":
					return "You do not have access to this application."
				case "CENTRE-404":
					return "You do not have access to this application."
			}
		},
		subtitle() {
			switch (this.ERRORCODE) {
				case "CENTRE-401":
					return "You do not have access to this application. If you work in a school with a DoE preschool and require access, please contact your Principal who can grant access."
				case "USER-401":
					return "You do not have access to this application. If you work in a school with a DoE preschool and require access, please contact your Principal who can grant access."
				case "CENTRE-404":
					return "Your school has not been approved to access this application. If you believe this is in error, and you require access, please contact your Principal to communicate with the SAaP Team."
			}
		},
		actions() {
			return [
				{
					text: "Return to Portal",
					attrs: {
						href: "https://portal.det.nsw.edu.au/",
					},
				},
			]
		},
	},
	components: { ErrorPage },
}

export default Error401
</script>
