export default {
	goals: [
		{
			summary: "Test 2",
			measures: "Test 4",
			due: "2023-04-17T14:00:00.000Z",
			purpose: "Test 1",
			strategies: "Test 3",
			progressNotes: [
				{
					date: "2023-04-17T04:43:19.587Z",
					addedby: "sylvana.cham",
					text: "TEST",
				},
				{
					date: "2023-05-04T00:59:42.075Z",
					addedby: "bridget.bardon",
					text: "Progress",
				},
				{
					date: "2023-12-06T23:20:02.917Z",
					addedby: "colleen.new",
					text: "test test test",
				},
			],
			id: "50fd0819-8c44-4336-a4b0-fd853b514fd2",
			issues: ["58cab365-dd33-48d5-a7be-219663000651"],
			updated: "Wed Dec 06 2023 23:20:23 GMT+0000 (Coordinated Universal Time)",
			who: "Test",
			status: 0,
		},
		{
			summary: "TEST",
			measures: "TEST",
			due: "2023-04-17T14:00:00.000Z",
			purpose: "TEST",
			strategies: "TEST",
			progressNotes: [],
			id: "e6e99ac0-c3b9-46af-95aa-85e6514c6cac",
			issues: ["89c7f5c6-edd1-479e-b5cb-ded4c845fbdd"],
			updated: "Fri Sep 15 2023 04:52:55 GMT+0000 (Coordinated Universal Time)",
			who: "TEST",
			status: 1,
		},
		{
			summary: "Goal - test",
			measures: "Success measure - test ",
			due: "2023-05-21T14:00:00.000Z",
			purpose: "Purpose -test",
			strategies: "Steps, strategies, and resourcing - test",
			progressNotes: [],
			id: "ac5d1d20-8940-4273-8452-a90940a66de3",
			issues: [
				"58cab365-dd33-48d5-a7be-219663000651",
				"89c7f5c6-edd1-479e-b5cb-ded4c845fbdd",
			],
			updated: "Sun May 14 2023 22:34:38 GMT+0000 (Coordinated Universal Time)",
			who: "Who -test",
			status: 0,
		},
		{
			summary: "Goal",
			measures: "Success measures",
			due: "2023-04-25T14:00:00.000Z",
			purpose: "Purpose",
			strategies: "Steps",
			progressNotes: [],
			id: "203dc4ee-0726-429c-bde6-0d981513a157",
			issues: [
				"58cab365-dd33-48d5-a7be-219663000651",
				"89c7f5c6-edd1-479e-b5cb-ded4c845fbdd",
			],
			updated: "Wed Apr 26 2023 00:22:34 GMT+0000 (Coordinated Universal Time)",
			who: "Who",
			status: 0,
		},
		{
			summary: "Test",
			measures: "TEst",
			due: "2023-05-03T14:00:00.000Z",
			purpose: "Test",
			strategies: "TEst",
			progressNotes: [],
			id: "431eb0db-b94a-42a2-aa6e-2f9eae88e1fb",
			issues: [
				"58cab365-dd33-48d5-a7be-219663000651",
				"89c7f5c6-edd1-479e-b5cb-ded4c845fbdd",
			],
			updated: "Thu May 04 2023 00:59:12 GMT+0000 (Coordinated Universal Time)",
			who: "Test",
			status: 0,
		},
		{
			summary: "Test",
			measures: "Test",
			due: "2023-05-11T14:00:00.000Z",
			purpose: "Test",
			strategies: "Test",
			progressNotes: [],
			id: "ac5eccd2-5279-4f0d-a679-2399106e6e94",
			issues: ["89c7f5c6-edd1-479e-b5cb-ded4c845fbdd"],
			updated: "Thu May 11 2023 23:53:49 GMT+0000 (Coordinated Universal Time)",
			who: "Test",
			status: 0,
		},
		{
			summary: "xx",
			measures: "xx",
			due: "2023-06-21T14:00:00.000Z",
			purpose: "xx",
			strategies: "xx",
			progressNotes: [],
			id: "eb7a7aa2-6f17-4985-b806-83eac154cc5e",
			issues: ["58cab365-dd33-48d5-a7be-219663000651"],
			updated: "Thu Jun 22 2023 00:33:58 GMT+0000 (Coordinated Universal Time)",
			who: "Bridget",
			status: 0,
		},
		{
			summary: "kfdjg'ldr",
			measures: "ftd;lhkrtlp;",
			due: "2023-09-17T14:00:00.000Z",
			purpose: "dn/lfdkg",
			strategies: "dfg;lkg",
			progressNotes: [
				{
					date: "2023-09-18T08:19:39.886Z",
					addedby: "nicole.lawson",
					text: "testing what this will look like ",
				},
			],
			id: "801b9692-463c-4ffd-b510-90a91e1992e7",
			issues: ["8d59d7ae-a447-4feb-9b3a-faadde1eecaa"],
			updated: "Mon Sep 18 2023 08:25:06 GMT+0000 (Coordinated Universal Time)",
			who: ".lmdfg",
			status: 0,
		},
		{
			summary: "kfdjg'ldr",
			measures: "ftd;lhkrtlp;",
			due: "2023-09-17T14:00:00.000Z",
			purpose: "dn/lfdkg",
			strategies: "dfg;lkg",
			progressNotes: [
				{
					date: "2023-09-18T08:19:39.886Z",
					addedby: "nicole.lawson",
					text: "testing what this will look like ",
				},
				{
					date: "2023-09-18T08:19:39.886Z",
					addedby: "nicole.lawson",
					text: "testing what this will look like ",
				},
			],
			id: "d357350f-194a-4179-95fc-4edac6639406",
			issues: ["8d59d7ae-a447-4feb-9b3a-faadde1eecaa"],
			updated: "Mon Sep 18 2023 08:25:35 GMT+0000 (Coordinated Universal Time)",
			who: ".lmdfg",
			status: 0,
		},
		{
			summary: "kfdjg'ldr",
			measures: "ftd;lhkrtlp;",
			due: "2023-09-17T14:00:00.000Z",
			purpose: "dn/lfdkg",
			strategies: "dfg;lkg",
			progressNotes: [
				{
					date: "2023-09-18T08:19:39.886Z",
					addedby: "nicole.lawson",
					text: "testing what this will look like ",
				},
				{
					date: "2023-09-18T08:19:39.886Z",
					addedby: "nicole.lawson",
					text: "testing what this will look like ",
				},
				{
					date: "2023-09-18T08:19:39.886Z",
					addedby: "nicole.lawson",
					text: "testing what this will look like ",
				},
			],
			id: "c16df184-5ca8-4ab8-95d9-fe6eaac0eaac",
			issues: ["8d59d7ae-a447-4feb-9b3a-faadde1eecaa"],
			updated: "Mon Sep 18 2023 08:25:57 GMT+0000 (Coordinated Universal Time)",
			who: ".lmdfg",
			status: 0,
		},
		{
			summary: "g",
			measures: "g",
			due: "2023-09-17T14:00:00.000Z",
			purpose: "g",
			strategies: "g",
			progressNotes: [],
			id: "753314c9-acb0-4453-a984-5e4fc91937df",
			issues: ["c74740e7-521b-4c46-952a-79e9b413bde9"],
			updated: "Mon Sep 18 2023 09:31:38 GMT+0000 (Coordinated Universal Time)",
			who: "g",
			status: 0,
		},
	],
}
