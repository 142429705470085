export default function formFieldAccessibility(controlPrefix) {
	return {
		data() {
			return {
				isValid: true,
			}
		},
		computed: {
			messageBoxId() {
				/* eslint no-underscore-dangle: off */
				return `${controlPrefix}Message-${this._uid}`
			},
			inputRef() {
				/* eslint no-underscore-dangle: off */
				return `${controlPrefix}-${this._uid}`
			},
		},
		mounted() {
			// we have to manually add a watch because the "valid" property is not natively observable
			// also the $refs aren't ready until mounted
			this.$watch(
				() => this.$refs[this.inputRef].valid,
				(val) => {
					this.isValid = val
				},
				{ immediate: true },
			)
		},
	}
}
