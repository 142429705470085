import api from "../../api"
import _ from "lodash"
//import handleStatus from "../../utils/apiError"

const assessmentsModule = {
	state: {
		selectedAssessment: {},
		qualityAreas: {},
		selectedQualityAreaStructure: "",
		selectedQualityArea: {},
		selectedStandard: {},
		selectedElement: {},
		isCompliant: true,
		lastCompliant: null,
		loading: {
			assessment: true,
			qualityAreas: true,
			qualityAreaStructure: true,
			issues: true,
			goals: true,
			isCompliant: true,
		},
		issues: {
			qualityArea: "",
			issues: [],
		},
		goals: {
			qualityArea: "",
			goals: [],
		},
	},
	getters: {
		selectedAssessment: (state) => state.selectedAssessment,
		qualityAreas: (state) => state.qualityAreas,
		selectedQualityAreaStructure: (state) => state.selectedQualityAreaStructure,
		selectedQualityArea: (state) => state.selectedQualityArea,
		selectedStandard: (state) => state.selectedStandard,
		hasSelectedStandard: (state) =>
			state.selectedStandard.children != undefined,
		selectedElement: (state) => state.selectedElement,
		hasSelectedQualityArea: (state) =>
			state.selectedQualityAreaStructure.children != undefined,
		issues: (state) => state.issues.issues,
		goals: (state) => state.goals.goals,
		isCompliant: (state) => state.isCompliant,
		philosophyStatement: (state) =>
			state.selectedAssessment?.philosophyStatement,

		goalsAreLoading: (state) => state.loading.goals,
		assessmentIsLoading: (state) => state.loading.assessment,
		qualityAreasAreLoading: (state) => state.loading.qualityAreas,
		qualityAreaStructureIsLoading: (state) =>
			state.loading.qualityAreaStructure,
		issuesAreLoading: (state) => state.loading.issues,
		issuesNeedsRefresh: (state) =>
			state.issues.qualityArea != state.selectedQualityArea.short,
		complianceCheckIsLoading: (state) => state.loading.isCompliant,
	},
	mutations: {
		setCompliant(state, [compliant, lastCompliant]) {
			state.isCompliant = compliant
			state.lastCompliant = lastCompliant
		},
		setIsLoading(state, [field, value = true]) {
			state.loading[field] = value
		},
		setIssues(state, issues) {
			state.issues = issues
		},
		setGoals(state, goals) {
			state.goals = goals
		},
		updateIssueStatus(state, issues) {
			for (let id of issues) {
				if (typeof id !== "string") id = id.id
				let ind = state.issues.issues.findIndex((v) => v.id === id)
				if (ind != -1) state.issues.issues[ind].status = 1
			}
			console.log('mod issue status, ', issues)
		},
		setSelectedAssessment(state, assessment) {
			state.selectedAssessment = assessment
		},
		setQualityAreas(state, qualityAreas) {
			state.qualityAreas = qualityAreas
		},
		setSelectedQualityAreaStructure(state, qualityArea) {
			state.selectedQualityAreaStructure = qualityArea
		},
		selectStandard(state, shortName) {
			state.selectedStandard =
				state.selectedQualityAreaStructure.children.standards.find(
					(standard) => {
						return standard.short == shortName
					},
				)
		},
		hardSelectElement(state, [standardShort, elementShort]) {
			state.selectedElement =
				state.selectedQualityAreaStructure.children.standards
					.find((standard) => {
						return standard.short == standardShort
					})
					.children.standards.find((element) => {
						return element.short == elementShort
					})
		},
		selectElement(state, elementShort) {
			state.selectedElement = state.selectedStandard.children.standards.find(
				(element) => {
					return element.short == elementShort
				},
			)
		},
		selectQualityArea(state, shortName) {
			let currentQA = state.selectedQualityArea.short
			state.selectedQualityArea = state.qualityAreas[shortName]
			if (currentQA != state.selectedQualityArea.short) {
				state.selectedQualityAreaStructure = ""
			}
		},
		setPhilosophyStatement(state, statement) {
			if (!state.selectedAssessment) return false
			state.selectedAssessment.philosophyStatement = statement
		},
		setProgress(state, [[i, j, k], strengthProgress, elementProgress]) {
			// divided by one to soft coerce strings to ints
			state.selectedAssessment.qualityAreas[i / 1].standards[j / 1].progress =
				elementProgress
			state.selectedAssessment.qualityAreas[i / 1].standards[j / 1].elements[
				k / 1
			].progress = strengthProgress
			state.selectedElement.progress = strengthProgress
			state.selectedStandard.progress = elementProgress
		},
		updateStrengthData(state, [[i, j, k, l], strengthData]) {
			const selectedAssessmentCopy = _.cloneDeep(state.selectedAssessment)
			selectedAssessmentCopy.qualityAreas[i].standards[j].elements[
					k
				].strengths[l] = strengthData
			state.selectedAssessment = selectedAssessmentCopy
		},
	},
	actions: {
		saveChecklist({ dispatch, state }) {
			return new Promise((resolve, reject) => {
				api.assessments
					.saveChecklist(
						state.selectedAssessment.assessment_id,
						state.selectedQualityArea.short,
						state.selectedAssessment?.qualityAreas?.find(
							(v) => v.name == state.selectedQualityArea.short,
						).checklist,
					)
					.then((data) => {
						resolve(data)
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"checklist",
							"save",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
			})
		},
		checkCompliance({ commit, dispatch, state }) {
			commit("setIsLoading", ["isCompliant"])
			return new Promise((resolve, reject) => {
				api.assessments
					.checkStillCompliant(state.selectedAssessment.assessment_id)
					.then((data) => {
						commit("setCompliant", [data.compliant, data.lastCompliant])
						resolve(data)
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"compliance",
							"check",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["isCompliant", false])
					})
			})
		},
		async fetchGoals({ commit, dispatch, state }) {
			commit("setIsLoading", ["goals"])
			return new Promise((resolve, reject) => {
				if (
					state.goals &&
					state.goals.qualityArea == state.selectedQualityArea.short
				) {
					resolve("Issues already up to date")
				}
				api.issues
					.fetchGoals(
						state.selectedAssessment.assessment_id,
						state.selectedQualityArea.short,
					)
					.then((data) => {
						commit("setGoals", data)
						resolve(data)
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"goals",
							"fetch",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["goals", false])
					})
			})
		},
		async fetchIssues({ dispatch, commit, state }) {
			commit("setIsLoading", ["issues"])
			return new Promise((resolve, reject) => {
				if (
					!state.selectedAssessment.assessment_id ||
					!state.selectedQualityArea.short
				) {
					console.error(
						"fetchIssues requires selected assessment and quality area",
					)
					commit("setIsLoading", ["issues", false])
					return reject(
						"fetchIssues requires selected assessment and quality area",
					)
				}
				if (
					state.issues &&
					state.issues.qualityArea == state.selectedQualityArea.short
				) {
					resolve("Issues already up to date")
				}
				api.issues
					.fetchIdentifiedIssues(
						state.selectedAssessment.assessment_id,
						state.selectedQualityArea.short,
					)
					.then((data) => {
						commit("setIssues", data)
						resolve(data)
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"issues",
							"fetch",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["issues", false])
					})
			})
		},
		async fetchAssessment({ dispatch, commit, rootState }) {
			commit("setIsLoading", ["assessment"])
			return new Promise((resolve, reject) => {
				api.assessments
					.fetchAssessment(rootState.centreModule.centre.associated_assessment)
					.then((data) => {
						commit("setSelectedAssessment", data)
						resolve(data)
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"assessment",
							"fetch",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["assessment", false])
					})
			})
		},
		async fetchQualityAreaStructure({ dispatch, commit, state }) {
			commit("setIsLoading", ["qualityAreaStructure"])
			return new Promise((resolve, reject) => {
				api.assessments
					.fetchQualityAreaStructure(state.selectedQualityArea.number)
					.then((data) => {
						commit("setSelectedQualityAreaStructure", data)
						resolve()
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"Elements, Standards, etc",
							"fetch",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["qualityAreaStructure", false])
					})
			})
		},
		async fetchQualityAreas({ dispatch, commit }) {
			commit("setIsLoading", ["qualityAreas"])
			return new Promise((resolve, reject) => {
				api.assessments
					.fetchQualityAreas()
					.then((data) => {
						commit("setQualityAreas", data)
						resolve()
					})
					.catch((reason) => {
						dispatch("displayApiError", [
							"the list of Quality Areas",
							"fetch",
							reason.reason || reason.data?.reason || reason.status,
						])
						reject(reason)
					})
					.finally(() => {
						commit("setIsLoading", ["qualityAreas", false])
					})
			})
		},
	},
}
export default assessmentsModule
